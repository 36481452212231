export async function putAlterarSenha(token, user, password) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  let raw = "";

  let requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const apiUrl = `https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/user_api/?empresa=01&filial=01&user=${user}&password=${password}`;

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error troca senha:", error);
  }
}
