import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import ManualDistribuidor from "../components/manual-distribuidor";
import ManualAvaliador from "../components/manual-avaliador";
import ManualPEX from "../components/manual-pex-pdf";
import { useAppContext } from "../context/AppContext";

const ManualCard = ({ title, description, children }) => (
  <Box
    maxW="md"
    borderWidth="1px"
    borderRadius="lg"
    overflow="hidden"
    p={4}
    boxShadow="md"
    minHeight="200px" // Definindo altura mínima para manter os ícones alinhados
    minW="250px"
  >
    <Center height="100%">
      <Flex direction="column" alignItems="center">
        <Heading size="md" mb={2}>
          {title}
        </Heading>
        <Text mb={4}>{description}</Text>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex="1"
        >
          {children}
        </Box>
      </Flex>
    </Center>
  </Box>
);

const PaginaManual = () => {
  const { userProfile } = useAppContext();

  // Recuperar o userProfile do localStorage
  const userProfiles = userProfile;

  // Verificar o userProfile e renderizar os componentes apropriados
  let renderedManuals;
  switch (userProfiles) {
    case "Administrador":
      renderedManuals = (
        <>
          <ManualCard
            title="Manual Distribuidor"
            // description="Este manual foi projetado para fornecer instruções detalhadas sobre como operar o perfil de distribuidor no Programa de Excelência Raízen Lubrificantes. Ele abrange uma variedade de tópicos, desde o registro inicial até as práticas recomendadas para maximizar a eficiência e o desempenho do seu negócio como distribuidor de lubrificantes Shell. Ao seguir as orientações deste manual, você aprenderá a navegar pelo sistema, entender os padrões, processos e rotinas essenciais para o sucesso do seu negócio como distribuidor."
          >
            <ManualDistribuidor />
          </ManualCard>
          <ManualCard
            title="Manual Avaliador"
            // description="Este manual é um guia abrangente para usar o perfil de avaliador no Programa de Excelência Raízen Lubrificantes. Ele fornece instruções passo a passo sobre como realizar avaliações eficazes, oferecer feedback construtivo e tomar decisões informadas para melhorar o desempenho dos distribuidores. Ao seguir as diretrizes deste manual, você se tornará proficientes em avaliar o desempenho dos distribuidores de forma justa e consistente, contribuindo assim para o sucesso contínuo do programa."
          >
            <ManualAvaliador />
          </ManualCard>
          <ManualCard
            title="Manual PEX"
            // description="Este manual é um recurso abrangente que oferece uma visão geral do Programa de Excelência Raízen Lubrificantes (PEX). Ele detalha os objetivos do programa, os critérios de avaliação, os benefícios para os distribuidores participantes e as expectativas de desempenho. Este manual serve como um guia indispensável para entender os princípios fundamentais do PEX e como eles se aplicam à gestão eficaz do seu negócio como distribuidor de lubrificantes Shell. Ao seguir as orientações deste manual, você estará bem equipado para alcançar e manter os padrões de excelência exigidos pelo programa."
          >
            <ManualPEX />
          </ManualCard>
        </>
      );
      break;
    case "Distribuidor":
      renderedManuals = (
        <>
          <ManualCard
            title="Manual Distribuidor"
            // description="Este manual foi projetado para fornecer instruções detalhadas sobre como operar o perfil de distribuidor no Programa de Excelência Raízen Lubrificantes. Ele abrange uma variedade de tópicos, desde o registro inicial até as práticas recomendadas para maximizar a eficiência e o desempenho do seu negócio como distribuidor de lubrificantes Shell. Ao seguir as orientações deste manual, você aprenderá a navegar pelo sistema, entender os padrões, processos e rotinas essenciais para o sucesso do seu negócio como distribuidor."
          >
            <ManualDistribuidor />
          </ManualCard>
          <ManualCard
            title="Manual PEX"
            // description="Este manual é um recurso abrangente que oferece uma visão geral do Programa de Excelência Raízen Lubrificantes (PEX). Ele detalha os objetivos do programa, os critérios de avaliação, os benefícios para os distribuidores participantes e as expectativas de desempenho. Este manual serve como um guia indispensável para entender os princípios fundamentais do PEX e como eles se aplicam à gestão eficaz do seu negócio como distribuidor de lubrificantes Shell. Ao seguir as orientações deste manual, você estará bem equipado para alcançar e manter os padrões de excelência exigidos pelo programa."
          >
            <ManualPEX />
          </ManualCard>
        </>
      );
      break;
    case "Avaliador":
      renderedManuals = (
        <>
          <ManualCard
            title="Manual Avaliador"
            // description="Este manual é um guia abrangente para usar o perfil de avaliador no Programa de Excelência Raízen Lubrificantes. Ele fornece instruções passo a passo sobre como realizar avaliações eficazes, oferecer feedback construtivo e tomar decisões informadas para melhorar o desempenho dos distribuidores. Ao seguir as diretrizes deste manual, você se tornará proficientes em avaliar o desempenho dos distribuidores de forma justa e consistente, contribuindo assim para o sucesso contínuo do programa."
          >
            <ManualAvaliador />
          </ManualCard>
          <ManualCard
            title="Manual PEX"
            // description="Este manual é um recurso abrangente que oferece uma visão geral do Programa de Excelência Raízen Lubrificantes (PEX). Ele detalha os objetivos do programa, os critérios de avaliação, os benefícios para os distribuidores participantes e as expectativas de desempenho. Este manual serve como um guia indispensável para entender os princípios fundamentais do PEX e como eles se aplicam à gestão eficaz do seu negócio como distribuidor de lubrificantes Shell. Ao seguir as orientações deste manual, você estará bem equipado para alcançar e manter os padrões de excelência exigidos pelo programa."
          >
            <ManualPEX />
          </ManualCard>
        </>
      );
      break;
    default:
      renderedManuals = <Text>Perfil de usuário não reconhecido.</Text>;
  }

  return (
    <>
      <Heading mt={4}>Manuais PEX</Heading>
      <Divider borderWidth="2px" marginTop={4} />
      <Center mt={8}>
        <Stack spacing={8} direction={{ base: "column", md: "row" }}>
          {renderedManuals}
        </Stack>
      </Center>
    </>
  );
};

export default PaginaManual;
