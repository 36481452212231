import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Tooltip,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdCreate } from "react-icons/md";
import { IoAlert } from "react-icons/io5";

const ModalObsAval = ({ onSave, itemObs }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [observacao, setObservacao] = useState("");

  const handleSave = () => {
    onSave(observacao);
    onClose();
  };

  return (
    <Box>
      <Tooltip hasArrow label="Este item possui uma observação salva">
        <Button mt="10px" onClick={onOpen}>
          <IoAlert />
        </Button>
      </Tooltip>

      <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent style={{ width: "85%" }}>
          <ModalHeader>Observação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text> {itemObs}</Text>
            {/* // height="350px"
              // onChange={(e) => setObservacao(e.target.value)} */}
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="whatsapp" mr={3} onClick={handleSave}>
              Salvar
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Fechar
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalObsAval;
