// AppContext.js

import React, { createContext, useState, useContext } from "react";

const AppContext = createContext();

export function AppProvider({ children }) {
  const [distribuidor, setDistribuidorGlobal] = useState("");
  const [globalToken, setGlobalToken] = useState("");
  const [user, setUser] = useState("");
  const [userProfile, setUserProfile] = useState("");

  const [getAnexoApiGlobalState, setGetAnexoApiGlobalState] = useState([]);
  const [
    getFileNameTrimestralApiGlobalState,
    setGetFileNameTrimestralApiGlobalState,
  ] = useState([]);
  const [getItemsPexGlobalState, setGetItemsPexGlobalState] = useState([]);
  const [getItemsPontuacaoAnualApi, setGetItemsPontuacaoAnualApi] = useState(
    []
  );
  const [getItemsPontuacaoTrimestralApi, setGetItemsPontuacaoTrimestralApi] =
    useState([]);

  const [getFileNameAnualApiGlobalState, setGetFileNameAnualApiGlobalState] =
    useState([]);

  const [filesGlobal, setFilesGlobal] = useState([]);
  const [emailGlobal, setEmailGlobal] = useState("");
  const [passwordGlobal, setPasswordGlobal] = useState("");
  const [anoGlobal, setAnoGlobal] = useState("");
  const [trimestreGlobal, setTrimestreGlobal] = useState("");
  const [anoGlobalAtivo, setAnoGlobalAtivo] = useState("");
  const [trimestreGlobalAtivo, setTrimestreGlobalAtivo] = useState("");

  const [statusGlobal, setStatusGlobal] = useState(""); // estado para controlar se botoes de anexo e avaliacao devem estar ativos

  const [emailUsuario, setEmailUsuario] = useState("");
  const [acessoUsuario, setAcessoUsuario] = useState("");
  const [nivelAcesso, setNivelAcesso] = useState([]);

  const [empresaGlobal, setEmpresaGlobal] = useState("");

  return (
    <AppContext.Provider
      value={{
        empresaGlobal,
        setEmpresaGlobal,
        statusGlobal,
        setStatusGlobal,
        nivelAcesso,
        setNivelAcesso,
        acessoUsuario,
        setAcessoUsuario,
        emailUsuario,
        setEmailUsuario,
        anoGlobalAtivo,
        setAnoGlobalAtivo,
        trimestreGlobalAtivo,
        setTrimestreGlobalAtivo,
        anoGlobal,
        setAnoGlobal,
        trimestreGlobal,
        setTrimestreGlobal,
        emailGlobal,
        setEmailGlobal,
        passwordGlobal,
        setPasswordGlobal,
        distribuidor,
        setDistribuidorGlobal,
        globalToken,
        setGlobalToken,
        user,
        setUser,
        userProfile,
        setUserProfile,
        getAnexoApiGlobalState,
        setGetAnexoApiGlobalState,
        getFileNameTrimestralApiGlobalState,
        setGetFileNameTrimestralApiGlobalState,
        getItemsPexGlobalState,
        setGetItemsPexGlobalState,
        getItemsPontuacaoAnualApi,
        setGetItemsPontuacaoAnualApi,
        getItemsPontuacaoTrimestralApi,
        setGetItemsPontuacaoTrimestralApi,
        getFileNameAnualApiGlobalState,
        setGetFileNameAnualApiGlobalState,
        filesGlobal,
        setFilesGlobal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext deve ser usado dentro do AppProvider");
  }
  return context;
}
