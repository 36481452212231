import { Box, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";

export default function ButtonsYesNo({
  onSave,
  avaliado,
  pontuacao_real,
  resposta,
  item,
}) {
  const [botaoClicado, setBotaoClicado] = useState(
    avaliado === "S" ? resposta : null
  );
  const { anoGlobal, setAnoGlobal } = useAppContext();
  const { trimestreGlobal, setTrimestreGlobal } = useAppContext();
  const { anoGlobalAtivo, setAnoGlobalAtivo } = useAppContext();
  const { trimestreGlobalAtivo, setTrimestreGlobalAtivo } = useAppContext();
  const { userProfile, setUserProfile } = useAppContext();

  useEffect(() => {
    setBotaoClicado(avaliado === "S" ? resposta : null);
  }, [avaliado, resposta]);

  const handleButtonClick = (resposta) => {
    setBotaoClicado(resposta);
    onSave(resposta, item.fator, item.avaliado, item.pontuacao_real);
  };

  const colorSchemeSim = botaoClicado === "S" ? "green" : "gray";
  const colorSchemeNao = botaoClicado === "N" ? "red" : "gray";
  const colorSchemeParcial = botaoClicado === "P" ? "yellow" : "gray";

  let isDisabled =
    anoGlobal !== anoGlobalAtivo || trimestreGlobal !== trimestreGlobalAtivo;

  if (userProfile === "Administrador") {
    isDisabled = false; // Desativar a desativação se o usuário for um administrador
  }

  const itensParcial = [
    "1.1.3",
    "1.2.2",
    "1.5.2",
    "2.1.1",
    "2.1.2",
    "2.1.3",
    "2.1.4",
    "2.1.5",
    "2.1.6",
    "2.1.7",
    "2.1.8",
    "2.1.9",
    "2.1.10",
    "2.1.11",
    "2.1.12",
    "2.1.13",
    "2.1.14",
    "2.1.15",
    "2.1.16",
    "2.2.1",
    "3.1.1",
    "4.1.2",
    "4.1.3",
    "4.1.4",
    "4.2.1",
    "4.3.1",
    "4.3.2",
    "4.3.3",
    "4.3.4",
    "5.1.1",
    "5.2.1",
    "5.2.3",
    "5.4.3",
    "5.5.1",
    "6.1.1",
    "6.1.2",
    "6.1.3",
    "6.1.4",
    "6.1.5",
    "6.1.6",
    "6.1.7",
    "6.4.1",
    "6.4.2",
    "6.4.3",
    "6.4.4",
    "6.4.5",
    "6.4.6",
    "6.4.7",
    "6.5.1",
    "6.5.2",
    "6.6.2",
    "7.1.2",
    "7.1.3",
    "7.1.4",
    "7.4.2",
    "7.4.3",
  ];

  return (
    <Box>
      <Button
        mt="10px"
        colorScheme={colorSchemeSim}
        onClick={() => handleButtonClick("S")}
        isDisabled={isDisabled}
      >
        Sim
      </Button>
      <Button
        ml="10px"
        mt="10px"
        colorScheme={colorSchemeNao}
        onClick={() => handleButtonClick("N")}
        isDisabled={isDisabled}
      >
        Não
      </Button>
      {itensParcial.includes(item.numero) ? (
        <Button
          ml="10px"
          mt="10px"
          colorScheme={colorSchemeParcial}
          onClick={() => handleButtonClick("P")}
          isDisabled={isDisabled}
        >
          Parcial
        </Button>
      ) : (
        ""
      )}
    </Box>
  );
}
