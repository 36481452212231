import { Button } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";
import { Box, Center, Divider, Text } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import { FaBook } from "react-icons/fa6";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useState } from "react";
import { Document, Page } from "react-pdf";

const ManualPEX = () => {
  const [isOpen, setIsOpen] = useState(false);
  const pdfUrl = "../helpers/mock/files/PEX - Avaliador.pdf";

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <Box mb="15px">
      <Tooltip hasArrow label="Manual PEX">
        <Button onClick={handleOpen}>
          <Icon as={FaBook} boxSize={8} color="black" mx={1} />
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={handleClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manual PEX</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center mt="4">
              <Button
                colorScheme="red"
                mr="2"
                onClick={goToPrevPage}
                disabled={pageNumber <= 1}
              >
                Página Anterior
              </Button>
              <Button
                colorScheme="red"
                onClick={goToNextPage}
                disabled={pageNumber >= numPages}
              >
                Próxima Página
              </Button>
            </Center>
            <Text>
              Página {pageNumber} de {numPages}
            </Text>
            <Divider mb="4" />
            <Center>
              <Document
                file={require("../helpers/mock/files/Manual PEX 24_25 Maio2024.pdf")}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Center>Carregando PDF...</Center>}
                error={<Center>Ocorreu um erro ao carregar o PDF.</Center>}
                style={{ width: "100vh", height: "100vh" }} // Defina o tamanho desejado do PDF aqui
              >
                <Page pageNumber={pageNumber} width={1050} />
              </Document>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ManualPEX;
