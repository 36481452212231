import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppProvider } from "./context/AppContext";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const root = ReactDOM.createRoot(document.getElementById("root"));
const pca = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <AppProvider>
        <App />
      </AppProvider>
    </MsalProvider>
  </React.StrictMode>
);
