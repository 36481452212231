import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { MdDownload } from "react-icons/md";
import DocViewerComponent from "./doc-viewer";
import { FaEye } from "react-icons/fa";
import { useEffect, useState } from "react";
import { getToken } from "../api/get-token-api";
import { getAnexos } from "../api/get-anexo-api";
import { useAppContext } from "../context/AppContext";

const ModalAvaliacao = ({ chave, pagina, tamanhoPagina }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [token, setToken] = useState(null);
  const [apiData, setApiData] = useState([]);
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const tokenData = await getToken();
        // setToken(tokenData.access_token);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [chave]);

  return (
    <Box>
      <Tooltip hasArrow label="Visualizar evidências">
        <Button mt="10px" onClick={onOpen}>
          <FaEye />
        </Button>
      </Tooltip>

      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent style={{ height: "1200px", width: "1450px" }}>
          <ModalHeader>Evidências</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DocViewerComponent
              chave={chave}
              pagina={pagina}
              tamanhoPagina={tamanhoPagina}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalAvaliacao;
