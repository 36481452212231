export const descContinuation = [
  {
    Número: "1.1.1",
    Conceito:
      "O Distribuidor deverá realizar reunião envolvendo a alta liderança e gerências do Distribuidor mensalmente para compartilhar os indicadores de SSMA (acidentes, multas), a situação das ações estabelecidas durante visitas, auditorias e verificações e as iniciativas de SSMA realizadas (palestras, reuniões, treinamentos, reconhecimentos).",
  },
  {
    Número: "1.1.2",
    Conceito:
      "Mensalmente o Distribuidor deverá preencher e enviar até o 5º dia útil para SSMA Comercial da Raízen o Formulário de Indicadores de SSMA Distribuidores Raízen. Os dados deverão corresponder ao somatório de todas as unidades.",
  },
  {
    Número: "1.1.3",
    Conceito:
      "O distribuidor deve incentivar que os funcionários reportem situações de risco, intervenções durante comportamentos inseguros e dicas de segurança. Tal atividade não deve se restringir ao Influenciador de SSMA. As melhores dicas podem ser transmitidas por e-mail, mensagens de texto, ou qualquer outro meio de comunicação, desde que seja possível seu registro, destacando sempre o benefício da prática.\nReconhecimentos das melhores práticas é recomendável.",
  },
  {
    Número: "1.2.1",
    Conceito:
      "O Distribuidor deverá realizar a Caminhada de Segurança nas áreas operacionais e administrativas de todas as unidades (matriz e filiais) que realizam atividades envolvendo os produtos Shell (Alvorada e franquia). A Caminhada deverá acontecer mensalmente, com pelo menos mais um participante de qualquer área do Distribuidor, e utilizar um checklist cobrindo os itens principais descritos no Guia de SSMA para Operações de Distribuidores. \nDeverá ser construído um Plano de Ação constando a não conformidade identificada, responsável e prazo para resolução do problema levando em consideração a criticidade da situação identificada.",
  },
  {
    Número: "1.2.2",
    Conceito:
      "O distribuidor deverá possuir todos os documentos e licenças relacionados à SSMA dentro da validade: Licença de Operação - órgão Ambiental, Alvará de Operações - Funcionamento, Auto de Vistoria do Corpo de Bombeiros (AVCB), Projeto Corpo de Bombeiros do Armazém, PCMSO e PGR, CADRI - destino de resíduos, Registro / Gerador resíduos e TCFA.",
  },
  {
    Número: "1.3.1",
    Conceito:
      "Todos os veículos (leves ou pesados) utilizados para a realização da operação do Distribuidor (vendas, logísticas ou administrativas), sendo próprias ou terceirizadas, devem possuir os itens básicos de segurança previstos pela resolução CONTRAN nº 912, de 28 de março de 2022 e resolução CONTRAN nº 380 de 28/04/2011. \nO Distribuidor deverá realizar obrigatoriamente inspeção dos veículos utilizados nas operações, inclusive de agregados conforme a periodicidade abaixo determinada. Os registros deverão ser mantidos para verificação em auditoria. \n•\nChecklist Bimestral (frota leve): veículos utilizados pelos vendedores, veículos leves de distribuição.\n•\nChecklist Mensal (frota pesada): Veículos pesados utilizados em transferência e em distribuição. Recomenda-se realizar a verificação de veículos agregados/terceirizados a cada viagem e manter os registros.\n•\nChecklist Anual (empresas de transporte terceirizadas): Este checklist deverá ser preenchido pelo transportador terceirizado, validado pelo Distribuidor que em conjunto com o transportador construirá um Plano de Ação para as não conformidades identificadas.",
  },
  {
    Número: "1.4.1",
    Conceito:
      "O Distribuidor deverá ter um programa de treinamentos, assegurando que cada funcionário receba a capacitação devida de acordo com a função e operação exercida. Neste programa deverá ser informada a periodicidade, data de realização do treinamento e registro de participação. O detalhamento dos treinamentos mandatórios, principais tópicos e a aplicação consta no Guia de SSMA para Operações de Distribuidores. ",
  },
  {
    Número: "1.5.1",
    Conceito:
      "O Distribuidor deverá possuir um Plano Atendimento à Emergências e Contingências englobando, obrigatoriamente os seguintes cenários: acidente com veículo leve e pesado, acidente com pessoas, incêndio, derrame de produtos. Além disso, o Plano de Emergência deverá conter as informações solicitadas no Guia de SSMA para Operações de Distribuidores.",
  },
  {
    Número: "1.5.2",
    Conceito:
      "Distribuidor deverá realizar o registro, investigação e acompanhamento de acidentes. Os resultados deverão ser compartilhados com a alta liderança para que sejam providenciadas medidas preventivas em busca do zero acidente bem como o gerenciamento de consequências para cada caso",
  },
  {
    Número: "2.1.1",
    Conceito:
      "É o líder de toda operação comercial, guardião da estratégia, deve dominar as plataformas motivacionais da Raízen, os procedimentos recomendados para uma boa execução de seus liderados, saber explicar os 7Vs e seus benefícios, também conhecer os conceitos da visão do consumidor, negócios de revenda, varejo, B2B e Alvorada. Entender sobre oferta integrada, trocador (Influenciador), galonagem do posto, ratio ( Lubrificantes Vs Combustíveis), compra zero, despejo e calculadora de valor.\nO distribuidor deve possuir um gerente de Vendas por Estado de atuação para suportar um time de 3 a 6 supervisores, caso o distribuidor tenha mais de 6 supervisores na estrutura, uma nova gerencia deverá ser contratada\n",
  },
  {
    Número: "2.1.2",
    Conceito:
      "O Supervisor do canal B2C é responsável por:\n•\nRevenda: são os clientes de Oficinas Mecânicas, Trocas de Óleo, Super Trocas de Óleo, Centros Automotivos, postos de bandeira branca etc.; \n•\nVarejo:  são os postos de bandeira Shell;\nEle precisa ter em mente o conceito do negócio, entender que cada caixa e cada cliente são importantíssimos, a distribuição numérica é a chave para expansão. Ter domínio de todos os KPI’s por consultor. Deve conhecer a região geograficamente e economicamente, saber quem são os principais clientes e onde estão as principais oportunidades, bem como, a linha de lubrificante e sua aplicação e as plataformas motivacionais e de treinamento da Raízen.\nDeve estar presente diariamente no campo, garantindo a execução integral do plano de ação construído junto ao gerente. Acompanhar os consultores de vendas apoiando através do coaching. \nVisando o desenvolvimento efetivo da equipe o Supervisor de vendas B2C não deverá exceder o número máximo de 8 consultores de venda sob sua gestão. ",
  },
  {
    Número: "2.1.3",
    Conceito:
      "É o líder focado na gestão da equipe que atende clientes de consumo maiores, indústrias, frotas entre outros. A abordagem e metodologia é completamente diferente do B2C,  com uma introdução lenta, mas com possibilidades de alto volume, fidelidade e retorno financeiro.\nO Supervisor de vendas deve conhecer o perfil da sua equipe de B2B, consultores de vendas e engenheiro responsável pelo suporte técnico ao distribuidor. Características dos cargos, e desenvolver os membros de sua equipe por meio de feedbacks de resultados das experiências em rota com o consultor de vendas. Isso deve ser feito por meio de formulário de coach padrão.\nVisando o desenvolvimento efetivo da equipe o Supervisor de vendas B2B não deverá exceder o número máximo de 8 consultores de venda sob sua gestão. ",
  },
  {
    Número: "2.1.4",
    Conceito:
      "Tem o papel fundamental na orientação de sua equipe interna, acompanhando os trabalhos de seu time a fim de desenvolver esses recursos e garantir que os processos e rotinas pertinentes a tais estruturas sejam cumpridos",
  },
  {
    Número: "2.1.5",
    Conceito:
      "Para que o Distribuidor possa ter melhor desempenho e cobrir seu mercado de B2C de forma mais efetiva, com foco nas ações de vendas, garantindo uma maior pulverização para que o mercado seja atendido adequadamente com a oferta de produtos, é fundamental que cada consultor de vendas externas atenda a uma área com potencial de 150 m³/mês, além disso o universo de clientes em seu território precisa suportar uma carteira de 400 clientes em média para serem roteirizados. Zonas de vendas que possuem potencial maior e/ou já possuem uma carteira de clientes de 400, indicam oportunidade de novas Zonas de Vendas. Essas áreas devem ser definidas pelos gerentes e supervisores, sendo responsabilidade do supervisor garantir que essa cobertura equilibrada seja mantida. Esse potencial e quantidade de clientes no Universo é definida de acordo com as informações disponibilizadas pelo time de inteligência da Raizen.",
  },
  {
    Número: "2.1.6",
    Conceito:
      "O Consultor de Vendas do varejo atende os postos de combustíveis Shell , deverá atentar a recomendação direcionada ao canal para garantir a venda do maior mix e volume. Ele deve residir, preferencialmente, dentro ou próximo de sua zona de atuação. Para Zonas de vendas exclusivas para o canal Postos Shell observar uma quantidade aproximada de 60 Postos na carteira, possibilitando visitas quinzenais.  ",
  },
  {
    Número: "2.1.7",
    Conceito:
      "É o vendedor focado em atender empresas que consomem nossos produtos (Ex. Indústrias, transportes, construção, etc.), seu escopo de trabalho é bem distinto do B2C. Utiliza outra metodologia de trabalho e execução, o processo de introdução é lento, porém pode produzir excelentes negócios. Ele deve ter uma habilidade adicional as mencionadas acima (item 2.1.6), comportamento técnica com relação ao Plano de Visitas: capacidade de gerir a agenda através do pipeline (SPANCOP), conhecer, cumprir e sanear a base junto aos clientes em prospecção. Captar novos clientes sempre que possível alinhando as oportunidades com o B2B direto da Raizen, considerando a gestão do pipeline (SPANCOP) garantindo a máxima execução em sua zona de vendas e preparar-se de forma adequada através de um POPSA robusto e fechar a reunião com um Call Reports claro. ",
  },
  {
    Número: "2.1.8",
    Conceito:
      "Profissional de vendas interna que fará o atendimento espelhado de consultores de vendas es externos, realizando ligações ativas e receptivas. Fica definido que cada atendente interno poderá realizar o espelho de até 4 consultores de vendas externos. As ligações receptivas visam apoiar o time de vendas externo em relação a solicitações que seus clientes possam fazer referentes a status de pedidos, colocação de pedidos, informações sobre produtos, enfim suportando e complementando o atendimento do time de vendas externo. As ligações ativas devem seguir o roteiro das ligações e tem como propósito dar sequência ao atendimento presencial 15 dias após a visita do consultor externo. As atividades aqui incluem ligações ativas de vendas, Pesquisa de Satisfação de Clientes, Ações em carteira específica de clientes sem compra a mais de 60 dias (clientes churn), por exemplo. O consultor interno espelho poderá, na ausência do Consultor Volante, cobrir a carteira de vendas do consultor externo em razão de suas férias ou qualquer tipo de ausência temporária",
  },
  {
    Número: "2.1.9",
    Conceito:
      "É um engenheiro que suportará todo o time comercial B2B em relação à parte técnica e aplicação. É uma função muito importante, exclusiva para o fim, e deve ser utilizada como alavanca de negócios para a equipe do distribuidor, pois é por meio dele que se pode mostrar o custo-benefício dos produtos Shell em relação à concorrência, por casos de sucesso para aumentar os benefícios de se comprar Shell, e com isso vender valor para os nossos clientes",
  },
  {
    Número: "2.1.10",
    Conceito:
      "Profissional sênior com experiencia em trade marketing e campo, deve gerir no distribuidor todos os processos referentes a Trade Marketing, além de garantir a implementação de todas as iniciativas Raízen.\nA sinergia com o time de consultores o time Raízen, supervisores e consultores do distribuidor é vital para o sucesso desta posição. Com ele, ficará mais fácil entender em que e quanto deve investir em seu mercado, além de acompanhar de forma muito mais ágil os resultados das iniciativas locais. ",
  },
  {
    Número: "2.1.11",
    Conceito:
      "É a posição responsável por acompanhar o Power BI da Raizen, gerar informações/relatórios, para o time de vendas e liderança com objetivo de acompanhar o plano de ação traçado e identificar possíveis oportunidades de mercado. Seu papel é fundamental para garantir o desenho correto das zonas de vendas, adequação da roteirização e alavancar a performance do time de vendas.",
  },
  {
    Número: "2.1.12",
    Conceito:
      "É o profissional que irá garantir que o distribuidor possua e utilize todos os sistemas e recursos de TI recomendados pela Raizen, que estes estejam instalados e atualizados e que possua pessoal treinado para operar de forma correta. Também verificar se o distribuidor cumpre a rotina de envio de informações solicitadas pela Raízen. Os Analistas de TI devem participar das teleconferências e treinamentos presenciais sempre que estes forem agendados, sua participação é de extrema importância. Nesses encontros, são expostos os pontos importantes para a melhoria constante no processo de gerenciamento da informação, manutenção e ajustes nos processos atuais",
  },
  {
    Número: "2.1.13",
    Conceito:
      "O Distribuidor deve definir um Gestor Geral do Programa de Excelência Raízen Lubrificantes e Apoiadores para cada Título a fim de envolver toda a estrutura na busca pela Excelência. Estes serão responsáveis por multiplicar os conceitos do Programa no Distribuidor. Para tanto, deverão conhecer os Títulos, Quesitos e Conceitos do PEX para que sua credibilidade entre a equipe não seja prejudicada. O Gestor e Apoiadores serão responsáveis pela execução das reuniões de PEX, a elaboração, acompanhamento e exposição do Plano de Ação do PEX, bem como das pré-avaliações periódicas do Programa.",
  },
  {
    Número: "2.1.14",
    Conceito:
      "O Distribuidor deverá designar um responsável por SSMA. As principais atribuições são: Conhecer e auxiliar no cumprimento dos itens de SSMA; gerenciar as atividades de SSMA; consolidar as informações e controles solicitados no PEX; participar das reuniões e iniciativas de SSMA da Raízen.",
  },
  {
    Número: "2.1.15",
    Conceito:
      "É o ponto focal do troca certa, a pessoa responsável por orientar e apoiar os distribuidores na gestão de processos de vendas em relação a oferta de produtos em granel.  Garantindo os processos descritos neste programa e no GQPD (Manual de Qualidade). Este recurso não precisa ser exclusivo para esta função, mas é vital que garanta a execução de todos os processos",
  },
  {
    Número: "2.1.16",
    Conceito:
      "O líder de finanças é responsável por informações da empresa como: Fluxo de Caixa, Contas a pagar, DRE e etc. Deverá enviar o DRE mensal até o dia 25 do mês subsequente a competência, ao time do Programa de Distribuidores, no padrão previamente acordado.",
  },
  {
    Número: "2.2.1",
    Conceito:
      "A equipe de vendas deve ser direcionada para o atingimento dos resultados por meio de uma política de remuneração clara, simples e alinhada com a estratégia do Raízen e, consequentemente, do Distribuidor. O acompanhamento diário, pela equipe, dos resultados parciais da sua remuneração promove um maior engajamento ao atingimento dos alvos e maior transparência das regras e resultados obtidos em cada KPI. As informações disponíveis no ROTA (Aplicativo de Força de Vendas) também auxiliam nesse entendimento e engajamento da equipe",
  },
  {
    Número: "3.1.1",
    Conceito:
      "Os Analistas de TI devem participar das teleconferências e treinamentos presenciais sempre que estes forem agendados. É de extrema importância que os AIs participem das teleconferências e dos treinamentos/ workshops organizados pela área de IT da Raízen. Nesses encontros, são expostos os pontos importantes para a melhoria constante no processo de gerenciamento da informação, manutenção e ajustes nos processos atuais",
  },
  {
    Número: "3.1.2",
    Conceito:
      "O Distribuidor deve, sempre que acabar de fazer o fechamento do mês (considerando-se sempre o último dia útil), realizar o procedimento de fechamento de estoque no Sistema de Gestão Integrada. Esse procedimento deve ocorrer mensalmente e o fechamento do estoque deve ser comunicado ao time de TI da Raízen",
  },
  {
    Número: "3.1.3",
    Conceito:
      "Os vendedores que estão ativos devem estar corretamente segmentados no cadastro, com informação de supervisor, email e CPF informados",
  },
  {
    Número: "3.1.4",
    Conceito:
      "Validar os dados de SKU do produto, Descrição, Volume e Familia conforme planilha de portofolio",
  },
  {
    Número: "3.1.5",
    Conceito:
      "O Distribuidor deve garantir que os dados de segmento dos clientes estejam de acordo com a lista-padrão passada pela Raízen, para que as análises possam ser feitas com informações corretas, gerando maior veracidade a tais informações",
  },
  {
    Número: "3.1.6",
    Conceito:
      "Os vendedores ativos devem possuir metas de pontos e KPIs Táticos cadastrados antes do inicio do mês, garantindo assim que ao iniciar o mês os vendedores já conhecem suas metas",
  },
  {
    Número: "4.1.1",
    Conceito:
      "Mostrar o reporte da última avaliação do GQPD, com o resultado percentual atingido pelo distribuidor.",
  },
  {
    Número: "4.1.2",
    Conceito:
      "Apresentar no campo, in loco, as ações concluídas, fruto de ações corretivas da última inspeção do GQPD. Plano de ação concluído no prazo indicado no Relatório de Inspeção.",
  },
  {
    Número: "4.1.3",
    Conceito:
      "Cascatear resultado da avaliação do GQPD para todo o time envolvido na operação Troca Certa por meio de reunião com ata de presença.",
  },
  {
    Número: "4.1.4",
    Conceito:
      "GQPD – Garantia de Qualidade de Produtos do Distribuidor. A Raízen Lubrificantes acredita que para uma empresa ser bem-sucedida no setor de lubrificantes, é necessário que seus produtos, serviços e processos operacionais sejam orientados para a qualidade. Os Distribuidores desempenham um papel fundamental nesse processo, devendo estar dispostos a compartilhar os valores da Raízen e sustentar suas políticas, valores e objetivos de qualidade. O programa GQPD estabelece normas para inspeções a fim de ajudar a supervisionar a qualidade dos Distribuidores que estão autorizados a manusear lubrificantes a granel. O coordenador de granel é o principal responsável pela execução das normas e por garantir sua constante atualização, bem como cumprir com todas as diretrizes e regulamentações federais, estaduais e municipais. O coordenador do granel é o principal responsável por manter sempre atualizado o manual de GQPD, que é composto de vários arquivos, cada um dedicado a um aspecto específico das normas e procedimentos de manuseio e/ou reembalagem de produtos a granel. Há também vários anexos no manual contendo materiais de exemplo, como formulários, procedimentos, tabelas e especificações de contêineres.",
  },
  {
    Número: "4.2.1",
    Conceito:
      "O coordenador do Troca Certa deve ter um material-padrão de treinamento para os consultores de vendas. Esse material deve conter as apresentações de GQPD, SSMA, Marketing (quais são os materiais e como utilizá-los), Pricing, tópicos básicos de venda a granel e, principalmente, o link com as plataformas motivacionais",
  },
  {
    Número: "4.3.1",
    Conceito:
      "Diferente do coordenador do troca certa que olha para questões operacionais, o ponto focal (Supervisor ou Gerente) líder do Troca Certa deve atuar na gestão comercial com foco no avanço e ativação de tanques do Troca Certa sobre as principais plataformas motivacionais e oportunidades de crescimento. Deve estar pronto para discussões profundas sobre a categoria granel com o dono do distribuidor e a Raízen",
  },
  {
    Número: "4.3.2",
    Conceito:
      "O distribuidor deve ter claros os critérios mínimos de crédito, volume, raio de atendimento logístico e segmento foco de crescimento (Plataformas Motivacionais, 5 maiores oportunidades e SPANCOP B2B) para instalação de novos tanques estacionários de Granel. O focal de Troca Certa, supervisor ou gerente, deve tomar decisões de instalação de novos tanques com base nesses critérios",
  },
  {
    Número: "4.3.3",
    Conceito:
      "Coordenador de Troca Certa deve possuir uma Planilha de Controle com os seguintes dados:\n \n• Tanques ativos e inativos para todos os clientes de B2C (Revenda e Varejo)\n• Tanques ativos e inativos no D-SPANCOP do canal B2B\nA planilha deve dar clara visibilidade à ativação e destacar clientes com mais de 4 meses sem recompra",
  },
  {
    Número: "4.3.4",
    Conceito:
      "O ponto focal de Troca Certa, supervisor ou gerente, deve utilizar os controles gerados pelo Coordenador de Granel para garantir que os temas Troca Certa no B2C e nas oportunidades de Troca Certa no SPANCOP do canal B2B estejam presentes em todas as reuniões matinais e mensais com revisão de performance do time de vendas",
  },
  {
    Número: "5.1.1",
    Conceito:
      "Verificar se foi definido calendário anual de reuniões do Programa de Excelência Raízen Lubrificantes, se está exposto em quadro mural, se todos estão cientes, se as reuniões estão sendo realizadas e se seu propósito de monitorar as ações do PEX está sendo atingido. Frequencia mínima de reuniões trimestral.",
  },
  {
    Número: "5.2.1",
    Conceito:
      "Verificar se o Distribuidor, com os Consultores Raizen, realizou reunião para definição do Plano de Negócio (Business Plan - BP), definindo os alvos, as ações e consolidando-os na planilha de acompanhamento. Verificar também se o plano foi cascateado para toda a equipe de vendas até a primeira quinzena do mês de Abril",
  },
  {
    Número: "5.2.2",
    Conceito:
      "Para a entrega do Plano de Negócios e atingimento de alvos, o distribuidor, junto com seu consultor, deverá definir uma série de ações que deverão suportar esse crescimento.\nDe acordo com as informações do Plano de Negócios, essas ações serão:\na) Relacionadas à solução de determinadas falhas de competências identificadas no Plano de Negócios dos distribuidores que possam impactar negativamente no cumprimento do plano e que precisam ser urgentemente sanadas\nb) Diretamente ligadas a resultados de vendas, como campanhas, investimentos em clientes, investimentos em pessoas, incentivo ao time de vendas. Para subsidiar essas ações, cada distribuidor deve se utilizar dos valores definidos no Fundo Local. Todas as ações devem ter prazo e responsável para que possam ser levadas até o final. Entende-se também que, se forem identificadas falhas relacionadas a um segmento específico (B2C ou B2B) e não houver ações definidas para a resolução de problemas ou para suportar o crescimento e \natingimento do plano, esse ponto não será atingido\nO Distribuidor deve fazer uma reunião mensal com o consultor da Raízen para apresentar as evoluções dos resultados em relação ao Plano de Negócios, bem como apresentar ações já concluídas, seus resultados e ações que ainda deverão ser trabalhadas",
  },
  {
    Número: "5.2.3",
    Conceito:
      "O Distribuidor deve construir e acompanhar sua projeção financeira com base no Relatório Demonstrativo de Resultado (DRE) para entender a necessidade real de investimento em caixa e limite de crédito, além de poder trabalhar para reduzir custos e aumentar competitividade",
  },
  {
    Número: "5.3.1",
    Conceito:
      "A venda de produtos agregados é importante, pois ajuda a diluir os custos do distribuidor, além de contribuir para uma oferta integrada de produtos para o cliente. Porém, itens de grande complexidade comercial ou logística devem ser evitados, garantindo assim uma operação ágil e eficiente.  A participação destes dentro da equipe de lubrificantes, não deve ultrapassar 30% da receita bruta anual do universo de franquia para garantir o foco da equipe de vendas e da operação como um todo. Excedendo a marca de 30% o distribuidor deverá optar entre a contratação de equipe exclusiva ou redução do portfólio de agregados",
  },
  {
    Número: "5.4.1",
    Conceito:
      "Não comparecimento do Distribuidor ou transportadora contratada pelo distribuidor para carregamento de pedido agendado na Planta da Raízen Lubrificantes ou em qualquer base logística operada pela Raízen Lubrificantes",
  },
  {
    Número: "5.4.2",
    Conceito:
      "Distribuidor deve linearizar a retirada dos pedidos ao longo do mês evitando acumulo de agendamento em um mesmo período",
  },
  {
    Número: "5.4.3",
    Conceito:
      "Um dos pontos críticos da operação de lubrificantes é a segurança de todos e do meio ambiente. Para isso é necessário que os caminhões utilizados pelos distribuidores para a retirada dos produtos na fábrica e/ ou nos armazéns da Raízen estejam sempre em dia com todos os itens de segurança e que todas as rotinas e processos de segurança sejam cumpridos, incluindo-se aí a verificação da documentação dos veículos e realização do teste de bafômetro pelos condutores.\nO processo de realização do checklist antes do envio do caminhão para a retirada dos lubrificantes é fundamental para garantir que o transporte dos produtos seja feito de forma segura. Esse checklist deve ser feito pelo distribuidor e/ou empresa contratada para a retirada do produto.\nSerá feito também o checklist assim que o caminhão chegar na planta e/ou armazém além da verificação dos documentos e teste de bafômetro. Nesse quesito, importante também que os agendamentos sejam respeitados, evitando que caminhões e/ou carretas cheguem com muita antecedência no local de retirada da carga, evitando que estes fiquem parados aguardando carregamento, comprometendo a segurança do veículo, do motorista e implicando em impacto nas redondezas pela estadia de tais veículos nas vias públicas.\nO atraso na chegada dos veículos também gera impacto no cronograma de carregamentos e deve ser evitado",
  },
  {
    Número: "5.5.1",
    Conceito:
      "Medir a satisfação dos clientes é um elemento crítico para entender como o distribuidor é visto no mercado. Essa prática nos proporciona uma leitura por amostragem dos principais pontos que precisa ser melhorado na distribuição, para uma melhor retenção de clientes nos mercados de B2C e B2B",
  },
  {
    Número: "5.5.2",
    Conceito:
      "O Distribuidor deve atingir um nível de serviço para atendimento aos clientes Raízen no nível que temos acordado em offerbook. Ou seja, 93% das ordens devem ser atendidas dentro do prazo acordado e quantidade solicitada e a medição é feita por grupo de clientes.\nOu seja, todo cliente deve ter OTIF de 93% ou acima. A medição do OTIF é feita \nautomaticamente pelo sistema, a partir da confirmação dos canhotos de entrega. \nPor isso, é muito importante que os canhotos sejam confirmados em sistema com \nregularidade",
  },
  {
    Número: "6.1.1",
    Conceito:
      "Realizar semanalmente e de forma estruturada reunião com os Supervisores, discutindo iniciativas e ações dos respectivos canais de cada Supervisor",
  },
  {
    Número: "6.1.2",
    Conceito:
      "Para que tenhamos uma boa execução de mercado e uma percepção de prestação de serviço por parte do cliente, o consultor de vendas deve executar os procedimentos recomendados conforme o Drill. O consultor de vendas só realizará os procedimentos se tiver consciência de seus benefícios e as razões pelas quais deve adotar cada um dos passos. O Gerente deve saber explicar aos Supervisores cada um dos passos e as razões pelas quais cada um deles deve ser adotado para que, então, os supervisores tenham efetivamente um papel ativo nessa metodologia junto aos consultores de vendas. Além de saber explicar os 7Vs e seus benefícios, o gerente deve também conhecer e explicar os conceitos da Visão do Consumidor. O Gerente de vendas também deve conhecer todos os conceitos do canal varejo, tais como oferta integrada, trocador, galonagem do posto, ratio, compra zero, despejo e calculadora de valor",
  },
  {
    Número: "6.1.3",
    Conceito:
      "Em linha com os Pilares estratégicos do negócio, o Gerente deve conhecer profundamente as plataformas motivacionais da Raízen Lubrificantes, sabendo explicar os benefícios de cada um dos seus pilares, com o intuito de poder treinar e reciclar o conhecimento do time de vendas. Além disso, ele deve conhecer as metas dessas plataformas do seu time e os resultados atingidos em relação ao número de clientes engajados, clientes ativos e volume de produtos Premium\nPara a parte do conhecimento dos alvos e resultados das \nplataformas, será solicitado ao gerente que apresente as planilhas de acompanhamentos mensais e e-mails/atas de reuniões com os supervisores, abordando os alvos e resultados evidenciando como o gerente direciona o tema \njunto aos supervisores",
  },
  {
    Número: "6.1.4",
    Conceito:
      "Conhecer, aplicar e gerenciar os indicadores do D-SPANCOP (Força do Tubo, Alvo incremental, Tempo de Ciclo, Taxa de Sucesso). Atentar-se aos BIG 10 e TOP 10. Orientar a equipe nas grandes negociações, participando quando necessário e dando apoio ao desenvolvimento da equipe",
  },
  {
    Número: "6.1.5",
    Conceito:
      "O Gerente deve monitorar e discutir com os Supervisores semanalmente a quantidade de clientes em CHURN do B2C e garantir estratégias para que essa base seja revertida, deverá apresentar ao Consultor Raizen nas agendas de revisão de negócio de cada mês, um ou mais planos de ação para reversão do volume em CHURN",
  },
  {
    Número: "6.1.6",
    Conceito:
      "O Gerente de Vendas realiza processo de coaching com os supervisores relativo à parte qualitativa do trabalho desses com seus consultores de vendas. Assim como o supervisor deve desenvolver as habilidades de seus consultores de vendas, o gerente deve também dar coaching aos seus supervisores (coaching do coaching)",
  },
  {
    Número: "6.1.7",
    Conceito:
      "O Distribuidor deve se reunir com o Consultor Raizen para validar os alvos do time de vendas para o mês seguinte. Deverão ser cadastrados em sistema, até o último dia de cada mês os alvos de Pontos, Volume, Efetivos e KPIs Táticos para o mês seguinte, estes deverão ser iguais ou maiores que o acordado em BP com a Raizen. Todas as ZV's ativas deverão ter alvo de clientes Novos cadastrados mensalmente\nDentre todos os KPI's táticos, o Distribuidor deverá cadastrar obrigatoriamente efetivos como o principal, o KPI de Efetivos deverá ter a maior representação % na parcela da remuneração variável do time de vendas, não superando o peso % do volume",
  },
  {
    Número: "6.2.1",
    Conceito:
      "O Supervisor do Canal B2C deve gerir mensalmente os alvos de pontos, clientes efetivos, volume total, volume por segmento e volume premium. Garantir a recorrência de compras dos clientes da sua base e direcionar sua equipe de vendas para execução dos planos de ação do Distribuidor na reversão da base de clientes CHURN, INATIVOS e SEM COMPRA. Monitorar as coletas de preços da sua supervisão, garantindo o número mínimo ideal por vendedor. É importante que o Supervisor de Vendas B2C domine as principais plataformas de relacionamento motivacionais da Raizen para se diferenciar da concorrência",
  },
  {
    Número: "6.2.2",
    Conceito:
      "O Supervisor deve conhecer os índices de Cobertura, Efetividade, Penetração e Cumprimento do Plano de Visitas de sua equipe e apoiar na construção do plano de visitas garantindo inteligência e dinamismo ao deslocamento dos vendedores",
  },
  {
    Número: "6.2.3",
    Conceito:
      "O Supervisor deve possuir dedicação ao aumento de Efetivos da sua supervisão garantindo efetivação recorrente dos clientes da sua base, conquistando novos clientes mensalmente garantindo maior participação da Raizen Lubrificantes em sua área de atuação, para a entrega desse importante KPI, o Supervisor B2C precisa ter planos de ação claros e objetivos para manutenção da base ATIVA e reversão dos clientes em CHURN, INATIVOS e SEM COMPRA da sua supervisão",
  },
  {
    Número: "6.3.1",
    Conceito:
      "O Consultor de Vendas do Canal B2C deve gerir diariamente seus alvos de pontos, clientes efetivos, volume total, volume por segmento e volume premium. Garantir a recorrência de compras dos clientes da sua base e executar os planos de ação para reversão da base de clientes CHURN, INATIVOS e SEM COMPRA, realizar no mínimo 3 (três) coletas de preços IN e OUT por dia. O Consultor de Vendas deve demostrar que possui controle absoluto sobre essas informações",
  },
  {
    Número: "6.3.2",
    Conceito:
      "O consumidor final é a razão principal de todo nosso trabalho. Para tanto, devemos realizar uma execução nos Pontos de Vendas a fim de impulsionar o consumo dos nossos produtos. O consultor de vendas deve entender e saber aplicar os conceitos de execução em Merchandising, Exposição e Precificação, demonstrando domínio sobre a importância de cada aspecto da execução para nosso desempenho no mercado. O Consultor de Vendas B2C precisa conhecer as principais plataformas de relacionamento desenvolvidas pela Raízen e utilizá-las para se diferenciar da concorrência, deve entender todos os pilares dessas plataformas, acompanhando a performance de cada um dos seus clientes",
  },
  {
    Número: "6.3.3",
    Conceito:
      "O Consultor deve conhecer os índices de Cobertura, Efetividade, Penetração e Cumprimento do Plano de Visitas de sua região e apoiar seu supervisor no melhor desenho do roteiro",
  },
  {
    Número: "6.4.1",
    Conceito:
      "Ter profundo conhecimento sobre as oportunidades de negócio B2B em sua área de atuação, por segmento, por zona de venda, conhecer oportunidade de negócio B2B por Zona de Venda garantindo o aumento de participação de mercado. Realizar semanalmente reunião com o consultor de vendas para garantir a cobertura correta de seus clientes por meio de um planejamento de visitas (CALL PLAN) eficiente, procurar direcionar o consultor de vendas para atingir seus alvos, focando nos pontos prioritários de desenvolvimento, orientar o consultor de vendas quanto à preparação de suas visitas (POPSA) e incentivar a prospecção discutindo o SPANCOP, analisando os indicadores e, por meio disso, fazendo a programação para a próxima semana. O coaching realizado pelo supervisor deverá englobar todas essas atividades",
  },
  {
    Número: "6.4.2",
    Conceito:
      "O Supervisor não deve ser solicitado a desenvolver atividades que não estejam diretamente ligadas aos seus consultores de vendas B2B",
  },
  {
    Número: "6.4.3",
    Conceito:
      "O Supervisor, a fim de planejar, acompanhar, e dar ritmo ao seu trabalho, deve saber quais são os KPIS relacionados ao negócio B2B da sua área e qual foi o resultado obtido em vendas até a data. Os dados devem ser expressos pelo Supervisor em números de visitas Plano vs Realizado, Efetivos Plano vs Realizado, litros (volume) Plano vs Realizado, Efetivos e Volume Premium Plano vs Realizado. O Supervisor deve ter sob controle o SPANCOP, Call Plan e POPSA de toda sua equipe, avaliando os índices do SPANCOP e verificando se o resultado até a data está compatível com a meta estabelecida",
  },
  {
    Número: "6.4.4",
    Conceito:
      "Para obter resultados sólidos no SPANCOP, o supervisor deve conhecer a metodologia, fases e significados de cada etapa de vendas e seus marcos formais, bem como entender o preenchimento da ferramenta online D-SPANCOP.",
  },
  {
    Número: "6.4.5",
    Conceito:
      "Entende-se por cliente em fase de abandono, ou Churn no canal B2B, aqueles clientes que estão sem comprar há 180 dias ou mais. Esse indicador é muito importante para gerenciarmos a retenção de clientes e aumentarmos a distribuição dos nossos produtos no mercado, garantindo a manutenção dos esforços de prospecção feitos anteriormente. Para que tenhamos os melhores resultados na retenção desses clientes, é fundamental que o Supervisor conheça os dez maiores clientes Churn da sua área de atuação. O assistente de vendas B2B deve realizar a pesquisa nos clientes Churn conforme sugestão do sistema, enviar o relatório diariamente com as causas de não compra para o supervisor analisar e elaborar seu plano de ação para reverter esses clientes",
  },
  {
    Número: "6.4.6",
    Conceito:
      "O Supervisor de vendas deve conhecer o perfil da sua equipe de B2B (consultores de vendas e STD), características do cargo, e desenvolver os membros de sua equipe por meio de feedbacks de resultados das experiências em rota com o consultor de vendas. Isso será feito por meio de formulário de coach padrão",
  },
  {
    Número: "6.4.7",
    Conceito:
      "O Supervisor B2B é o responsável por dimensionar o potencial do mercado B2B de sua área de atuação, assim como ter conhecimento por ZVs de oportunidades em volume, segmentos, produtos, essa informação será necessária para ajustes em relação a estratégia de atuação dentro do distribuidor",
  },
  {
    Número: "6.5.1",
    Conceito:
      "O Consultor de Vendas B2B, a fim de planejar, acompanhar, e dar ritmo ao seu trabalho, deve saber qual é o volume potencial de sua Zona de Vendas de atuação. Conhecer seus KPIS para entrega de resultado como: Visitação, Efetivos, Alvo Total de Volume, Alvo de Produtos Premium, além de saber o comparativo com os resultados acumulados no período entre Plano e Realizado. Os dados devem ser expressos pelo Consultor de Vendas B2B em litros e percentual em relação ao alvo. O Consultor de Vendas B2B deve ter atualizado o SPANCOP, além da utilização do Call Plan e POPSA de toda sua área, avaliando os índices do SPANCOP e verificando se o resultado até a data está compatível com a meta estabelecida",
  },
  {
    Número: "6.5.2",
    Conceito:
      "Para execução correta do SPANCOP, o consultor de vendas B2B deve conhecer o significado de cada fase da metodologia e seus marcos formais, bem como entender corretamente o uso da ferramenta online. O consultor de vendas B2B deve atualizar sua ferramenta D-SPANCOP, no mínimo, semanalmente",
  },
  {
    Número: "6.6.1",
    Conceito:
      "O objetivo do Calendário Anual de Treinamento é capacitar todas as equipes de vendas de B2B para superar as argumentações de vendas dos competidores. As equipes de vendas (consultor de vendas B2B, Supervisores e STD) serão treinadas sobre o novo formato de conteúdo, metodologia e execução das estratégias e argumentações de vendas Raízen Lubrificantes, direcionando a equipe a uma comunicação homogênea ao mercado. O distribuidor deverá realizar todos os treinamentos referentes às trilhas de capacitação que forem solicitados pela Raízen",
  },
  {
    Número: "6.6.2",
    Conceito:
      "A Avaliação de Nivelamento tem como objetivo mensurar o nível de conhecimento das equipes de vendas B2B e identificar os principais gaps. E, com os resultados das avaliações, diagnosticar os maiores gaps de conhecimento das equipes e elaborar um plano de ação para construir treinamentos em um calendário estruturado de capacitação.",
  },
  {
    Número: "7.1.1",
    Conceito:
      "Coordenador de marketing deverá obrigatoriamente participar das teleconferências definidas pelo time da Raízen, das Webcasts dos Supervisores de Vendas, das sessões presenciais e Workshops  e Lives de acordo com a periodicidade solicitada pelo time Raízen. Qualquer impossibilidade do Coordenador de Marketing de participar de qualquer uma das atividades agendadas pelo marketing deverá ser previamente comunicada ao time Raízen e ao Consultor Comercial Raízen e um substituto deverá participar das reuniões e lives.",
  },
  {
    Número: "7.1.2",
    Conceito:
      "O Fundo Local é um fundo de investimento com recursos do próprio distribuidor gerido pelo coordenador para ações locais em seus clientes e mercado de atuação. Espera-se que o distribuidor tenha um perfil empreendedor que entenda as necessidades do mercado relacionadas a desenvolvimento e aumento da participação de mercado. Para isso, é importante o reinvestimento de pelo menos 1% de todo o faturamento IN no mercado de atuação do distribuidor no Fundo Local. O distribuidor precisa ter um controle de todos os investimentos e compartilhar as informações com o consultor comercial ou time de marketing da Raízen nas reuniões de alinhamento do Plano de Negócios.",
  },
  {
    Número: "7.1.3",
    Conceito:
      "O Coordenador de Marketing comunica e cascateia para a equipe de vendas as comunicações, promoções, campanhas de incentivo e MPDVs que são apresentadas nas reuniões da Raizen Lubes.\nCoordenadores Trade Regional, realiza reuniões com o time de vendas e participa 1x por mês das reuniões matinais, garantindo que a equipe de vendas possua material explicativo das ações na pasta do vendedor.",
  },
  {
    Número: "7.1.4",
    Conceito:
      "Participação em rota no mínimo 2x ao mês sendo negócios (B2B ou B2C), canais e segmentos distintos.",
  },
  {
    Número: "7.2.1",
    Conceito:
      "O time do distribuidor deve realizar todos os treinamentos obrigatórios indicados pela Raízen.",
  },
  {
    Número: "7.2.2",
    Conceito:
      "Os revendedores que compram lubrificantes Shell devem estar cadastrados na plataforma Conexão Shell Lubes para fazer parte do programa motivacional e ganhar benefícios pelas compras. Visto isso, nesse item é avaliado o percentual dos efetivos realizados pelo distribuidor que está cadastrado no portal, ajudando a garantir uma boa execução de trade marketing",
  },
  {
    Número: "7.2.3",
    Conceito:
      "Os revendedores e trocadores que estão cadastrados no Conexão Shell Lubes devem estar ativos e atentos na plataforma, para acompanhar seus alvos, extrato e aproveitar os benefícios oferecidos pelo programa. Visto isso, nesse item é avaliado o percentual de trocadores e revendedores cadastrados no Conexão Shell Lubes que acessaram a plataforma ao menos uma vez durante o período avaliado, ajudando a garantir uma boa execução de trade marketing e a participação efetiva dos revendedores e trocadores dentro do programa.",
  },
  {
    Número: "7.2.4",
    Conceito:
      "Os trocadores dos pontos de venda que compram lubrificantes Shell devem estar cadastrados na plataforma Conexão Shell Lubes ou no portal Incríveis para fazer parte do programa Craque da Troca e ganhar benefícios pelas trocas de lubrificante. Visto isso, nesse item é avaliado o percentual de trocadores que cadastraram algum QR Code de tampinha durante o período avaliado, ajudando a garantir uma boa execução de trade marketing e a participação efetiva dos trocadores dentro dos programas. Os trocadores / balconistas de autopeças não são considerados nesse item, uma vez que não possuem cadastro de tampinha habilitado no portal.",
  },
  {
    Número: "7.2.5",
    Conceito:
      "Os revendedores e trocadores que estão cadastrados no Conexão Shell Lubes devem estar ativos e atentos na plataforma, para resgatar os pontos creditados em razão de suas compras ou trocas de lubrificante. Visto isso, nesse item é avaliado o percentual de pontos resgatados por esses usuários em comparação à quantidade de pontos creditados, ajudando a garantir uma boa execução de trade marketing e a participação efetiva dos revendedores e trocadores dentro do programa.",
  },
  {
    Número: "7.3.1",
    Conceito:
      "Implementação e execução dos MPDVs enviados para o CD do distribuidor",
  },
  {
    Número: "7.4.1",
    Conceito:
      "O linkedin é o canal de maior importância para a captação de clientes B2B e awareness, por isso, a importância de estarmos presentes, mensalmente, com o conteúdo personalizado para os nossos públicos e setores",
  },
  {
    Número: "7.4.2",
    Conceito:
      "Precisamos acompanhar todas as etapas do funil de vendas, para sabermos onde conseguimos apoiar o time de vendas a conquistas novos clientes e evitar o churn. Esse trabalho é importante para entender os motivos pelos quais perdemos vendas e como podemos atuar",
  },
  {
    Número: "7.4.3",
    Conceito:
      "Existem materiais de apoio às vendas (brochuras, emails, whatsapp, guide execução, ppt institucional e etc), que precisam ser usados com a qualidade que deveriam pelo vendedor. Este material é importante não só para a apresentação dos nossos produtos para o cliente, mas também para a capacitação do time de vendas. Por isso, a obrigatoriedade de treinamentos online ou presenciais para todo o time de vendas, pelo trade regional é importante",
  },
  {
    Número: "8.1.1",
    Conceito:
      "Garantir a execução da Rotina Básica nos PDV’s B2C (Revendas e Postos Shell) através dos Consultores é a principal função do Supervisor de Vendas. Para isso, ele deve utilizar a Ferramenta do Coaching como ferramenta de desenvolvimento do seu time",
  },
  {
    Número: "8.1.2",
    Conceito:
      "O Supervisor deve reunir-se com seus consultores de vendas (Matinal) antes da saída para o mercado, obedecendo execução de acordo com o previsto para a área de negócios B2C. A reunião matinal é o momento em que o Supervisor transmite as instruções, sana as dúvidas e alinha estratégias e compromissos com a equipe de vendas. A reunião deve respeitar a execução prevista na Rotina Drill Varejo e ser realizada diariamente de forma remota ou presencial",
  },
  {
    Número: "8.1.3",
    Conceito:
      "O Supervisor deverá reunir-se com seus consultores (Vespertina) após o retorno da saída para o mercado, obedecendo execução de acordo com o previsto no Drill B2C ",
  },
  {
    Número: "8.2.1",
    Conceito:
      "O Consultor de vendas deve conhecer os impactos positivos e oportunidades que a execução perfeita dos 7V’s pode oferecer, seguir com critério cada uma dessas etapas é importante para que o cliente perceba valor, diferenciação e qualidade na execução, com isso é possível se destacar da concorrência, e criar relacionamento sólido com o PDV.",
  },
  {
    Número: "8.3.1",
    Conceito:
      "COBERTURA: É igual ao total de clientes roteirizados sobre o universo de clientes de mercado reconhecido pela Raizen. Meta acima de 80%",
  },
  {
    Número: "8.3.2",
    Conceito:
      "EFETIVIDADE DA VISITA: Determina o número de clientes efetivos (com pedidos) versus o número de visitas realizadas do consultor a clientes em determinado roteiro. Meta acima de 30%",
  },
  {
    Número: "8.3.3",
    Conceito:
      "PENETRAÇÃO: Representa a porcentagem de clientes efetivos (com vendas) dividida pelo total de clientes roteirizados da base. Meta acima de 30%",
  },
  {
    Número: "8.3.4",
    Conceito:
      "CUMPRIMENTO DO PLANO DE VISITAS: É a relação entre o número de clientes visitados versus o número de clientes planejados no roteiro de visitas. Meta acima de 95%",
  },
  {
    Número: "9.1.1",
    Conceito:
      "Força do Tubo: Distribuidor vai pontuar de acordo com a Força do Tubo do SPANCOP no momento da avaliação",
  },
  {
    Número: "9.1.2",
    Conceito:
      "Alvo Incremental: Distribuidor vai pontuar neste item de acordo com o Alvo incremental entregue no momento da avaliação",
  },
  {
    Número: "9.1.3",
    Conceito:
      "Sua Taxa de Sucesso: Distribuidor vai pontuar de acordo com a Taxa de Sucesso do SPANCOP",
  },
  {
    Número: "9.1.4",
    Conceito:
      "Tempo de ciclo: Distribuidor vai pontuar de acordo com o tempo de ciclo do SPANCOP.",
  },
  {
    Número: "9.2.1",
    Conceito:
      "O Supervisor é responsável por fazer a gestão e orientar sua equipe na utilização das ferramentas de Sales 1st. O Call Plan é uma agenda que detalha o cronograma de visitas e os objetivos de cada contato com o cliente. Dessa forma, é imprescindível que o Supervisor estimule o uso na rotina do consultor para se beneficiar dessa organização e controle, cobrando disciplina e periodicidade no report",
  },
  {
    Número: "9.2.2",
    Conceito:
      "O Supervisor de vendas B2B deve conhecer o perfil da sua equipe de B2B (Consultores de Vendas B2B e STD), características do cargo, e desenvolver os membros de sua equipe por meio de feedbacks de resultados das experiências em rota com o consultor B2B. Isso será feito por meio de formulário de coach padrão",
  },
  {
    Número: "9.3.1",
    Conceito:
      "O POPSA é o método para preparação de visitas utilizado em vendas avançadas cuja finalidade é, por meio de propósitos e objetivos de visita claramente definidos, levar o consultor de vendas B2B a traçar sua estratégia e antecipar possíveis objeções no momento de contato com o cliente.",
  },
  {
    Número: "9.3.2",
    Conceito:
      "O consultor de vendas B2B maximizará o sucesso de suas negociações se conseguir agregar maior valor aos seus clientes por meio do correto atendimento de suas necessidades. Para isso, ele deverá possuir um planejamento que envolva no momento adequado as visitas aos seus clientes com propósitos claros de desenvolvimento",
  },
  {
    Número: "9.3.3",
    Conceito:
      "O SPANCOP dos consultores de vendas B2B devem ser atualizados semanalmente",
  },
  {
    Número: "10.1.1",
    Conceito:
      "Vendas de lubrificantes para CARROS: Para o cálculo da pontuação do item, devemos verificar o realizado de volume total no período relativo à avaliação versus o alvo previsto no Business Plan (Plano de Negócios) no mesmo período. O limite máximo de atingimento é de 120% e o mínimo é de 90%. Caso o distribuidor fique abaixo de 90%, a pontuação será zerada nesse item",
  },
  {
    Número: "10.1.2",
    Conceito:
      "Vendas de lubrificantes para MOTOS: Para o cálculo da pontuação do item, devemos verificar o realizado de volume total no período relativo à avaliação versus o alvo previsto no Business Plan (Plano de Negócios) no mesmo período. O limite máximo de atingimento é de 120% e o mínimo é de 90%. Caso o distribuidor fique abaixo de 90%, a pontuação será zerada nesse item",
  },
  {
    Número: "10.1.3",
    Conceito:
      "Vendas de lubrificantes para CAMINHÕES: Para o cálculo da pontuação do item, devemos verificar o realizado de volume total no período relativo à avaliação versus o alvo previsto no Business Plan (Plano de Negócios) no mesmo período. O limite máximo de atingimento é de 120% e o mínimo é de 90%. Caso o distribuidor fique abaixo de 90%, a pontuação será zerada nesse item.",
  },
  {
    Número: "10.1.4",
    Conceito:
      "Vendas de lubrificantes para INDÚSTRIA: Para o cálculo da pontuação do item, devemos verificar o realizado de volume total no período relativo à avaliação versus o alvo previsto no Business Plan (Plano de Negócios) no mesmo período. O limite máximo de atingimento é de 120% e o mínimo é de 90%. Caso o distribuidor fique abaixo de 90%, a pontuação será zerada nesse item",
  },
  {
    Número: "10.1.5",
    Conceito:
      "Melhoria de Produtos (Mix Premium): Usando o argumento de entregar valor extra, é possível vender um produto de melhor tecnologia e com melhor desempenho em uma ampla variedade de aplicações, aumentando assim o seu volume de negócios e entrega de valor real para o cliente. Para o cálculo da pontuação do item, devemos verificar o realizado de volume premium no período relativo à avaliação versus o alvo previsto no Business Plan (Plano de Negócios) no mesmo período. O limite máximo de atingimento é de 120% e o mínimo é de 90%. Caso o distribuidor fique abaixo de 90%, a pontuação será zerada nesse item.",
  },
  {
    Número: "10.1.6",
    Conceito:
      "O total de clientes efetivos realizada no período da avaliação deve atingir ou superar a meta do Plano de Negócios no mesmo período, para garantirmos a continuidade e o aumento da distribuição dos nossos produtos no mercado. O limite máximo de atingimento é de 120% e o mínimo é de 90%. Caso o distribuidor fique abaixo de 90%, a pontuação será zerada nesse item",
  },
];
