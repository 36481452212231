import { Button, Center, Spinner, Text, VStack } from "@chakra-ui/react";
import { useAppContext } from "../context/AppContext";
import { getAllFileNames } from "./get-files-name-jc-api";
import { getAllItemsPex } from "./get-items-pex-api";
import { getAllItemsPexWithPoints } from "./get-items-pontuacao-api";
import { getToken } from "./get-token-api";
import { useState } from "react";

const RefetchAllApis = ({ buttonText }) => {
  const { globalToken, setGlobalToken } = useAppContext();
  const { setGetFileNameAnualApiGlobalState } = useAppContext();
  const { setGetFileNameTrimestralApiGlobalState } = useAppContext();
  const { setGetItemsPexGlobalState } = useAppContext();
  const { setGetItemsPontuacaoAnualApi } = useAppContext();
  const { setGetItemsPontuacaoTrimestralApi } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { distribuidor } = useAppContext();
  const getAllPexApis = async () => {
    try {
      setLoading(true);

      const getTokenApi = await getToken();
      setGlobalToken(getTokenApi.access_token);
      localStorage.setItem("globalToken", getTokenApi.access_token);

      const getFileNamesAnual = await getAllFileNames("A");
      const getFileNamesTrimestral = await getAllFileNames("T");

      const getAllItems = await getAllItemsPex(globalToken, 1);

      const getPexPontuacaoAnual = await getAllItemsPexWithPoints(
        globalToken,
        "Anual",
        distribuidor
      );
      const getPexPontuacaoTrimestral = await getAllItemsPexWithPoints(
        globalToken,
        "Trimestral",
        distribuidor
      );

      localStorage.setItem(
        "getFileNamesAnual",
        JSON.stringify(getFileNamesAnual)
      );
      localStorage.setItem(
        "getFileNamesTrimestral",
        JSON.stringify(getFileNamesTrimestral)
      );
      localStorage.setItem("getAllItems", JSON.stringify(getAllItems));
      localStorage.setItem(
        "getPexPontuacaoAnual",
        JSON.stringify(getPexPontuacaoAnual)
      );
      localStorage.setItem(
        "getPexPontuacaoTrimestral",
        JSON.stringify(getPexPontuacaoTrimestral)
      );

      setGetFileNameAnualApiGlobalState(getFileNamesAnual);
      setGetFileNameTrimestralApiGlobalState(getFileNamesTrimestral);
      setGetItemsPexGlobalState(getAllItems);
      setGetItemsPontuacaoAnualApi(getPexPontuacaoAnual);
      setGetItemsPontuacaoTrimestralApi(getPexPontuacaoTrimestral);

      setLoading(false);
    } catch (error) {
      console.error("Erro em getAllPexApis:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center>
      {loading ? (
        <>
          <Center h="100vh">
            <VStack>
              <Spinner size="xl" color="black" thickness="4px" />
              <Text mt="4">Aguarde... </Text>
            </VStack>
          </Center>
        </>
      ) : (
        <Button colorScheme="yellow" onClick={getAllPexApis}>
          {" "}
          {buttonText}
        </Button>
      )}
    </Center>
  );
};

export default RefetchAllApis;
