export async function getAllAnexosAllDist(ano, referencia, revisao, token) {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const apiUrl = `https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/avaliacoes_pex/distribuidores/anexos?ano=${ano}&referencia=${referencia}&revisao=${revisao}`;

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error get anexo:", error);
  }
}
