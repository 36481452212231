import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box textAlign="center" mt="10">
      <Heading fontSize="2xl">404 - Página não encontrada</Heading>
      <Text mt="4">
        A página que você está procurando pode ter sido removida ou não existe.
      </Text>
      <Button mt="4" colorScheme="teal" onClick={() => navigate("/")}>
        Ir para a página de login
      </Button>
    </Box>
  );
};

export default NotFound;
