import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Tooltip,
  Textarea,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdCreate } from "react-icons/md";
import { IoAlert } from "react-icons/io5";
import { MdOutlineQuestionMark } from "react-icons/md";
import { descContinuation } from "../helpers/mock/desc-mock";
import { LuFileEdit } from "react-icons/lu";

const ModalDescAval = ({ onSave, itemDesc, itemNumero }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [observacao, setObservacao] = useState("");

  const handleSave = () => {
    onSave(observacao);
    onClose();
  };

  const findConcept = (numero) => {
    const item = descContinuation.find((desc) => desc.Número === numero);
    return item ? item.Conceito : null;
  };

  const conceito = findConcept(itemNumero);
  const displayDesc = conceito || itemDesc; // Use conceito if found, otherwise use itemDesc

  return (
    <Box>
      <Tooltip hasArrow label="Descrição do Item">
        <Button mt="10px" onClick={onOpen}>
          <MdOutlineQuestionMark />
        </Button>
      </Tooltip>

      <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent style={{ width: "25%" }}>
          <ModalHeader bg="gray.200">
            <Flex align="center">
              <LuFileEdit />
              <Text ml={2}>Descrição do item {itemNumero}</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{displayDesc}</Text>
            {/* // height="350px"
                // onChange={(e) => setObservacao(e.target.value)} */}
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="whatsapp" mr={3} onClick={handleSave}>
                Salvar
              </Button>
              <Button colorScheme="red" onClick={onClose}>
                Fechar
              </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalDescAval;
