export async function getAllFileNames(
  periodo,
  distribuidor,
  ano,
  trimestre,
  revisao
) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Basic YWRtaW46ZGVjQG5leHVz");

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const apiUrl = `https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/avaliacoes_pex?distribuidor=${distribuidor}&periodo=${periodo}&ano=${ano}&referencia=${trimestre}&revisao=${revisao}`;

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error get pex filenames:", error);
  }
}
