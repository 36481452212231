const mock_capitulos = {
  items_capitulos: [
    { capitulo: 1, AreaFoco: "SSMA" },
    { capitulo: 2, AreaFoco: "Tecnologia da Informação" },
    { capitulo: 3, AreaFoco: "Recursos Humanos" },
    { capitulo: 4, AreaFoco: "Troca Certa" },
    { capitulo: 5, AreaFoco: "Estrutura de Vendas" },
    { capitulo: 6, AreaFoco: "Sistema de Gestão" },
    { capitulo: 7, AreaFoco: "Metodologia de Vendas" },
    { capitulo: 8, AreaFoco: "Marketing" },
    { capitulo: 9, AreaFoco: "Execução B2C" },
    { capitulo: 10, AreaFoco: "Execução B2B" },
    { capitulo: 11, AreaFoco: "Vendas" },
  ],
};

export default mock_capitulos;
