import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  useToast,
  CloseButton,
  Stack,
} from "@chakra-ui/react";
import { MdSend, MdCheck } from "react-icons/md";
import {
  BsFiletypePng,
  BsFiletypeXlsx,
  BsFiletypePdf,
  BsFiletypeDocx,
} from "react-icons/bs";
import { postAnexo } from "../api/post-anexo-api";
import { getToken } from "../api/get-token-api";
import { useAppContext } from "../context/AppContext";
import { BsFiletypeJpg } from "react-icons/bs";

export default function DropzoneBasic({ chave }) {
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false); // Novo estado
  const [files, setFiles] = useState([]);
  const { globalToken, setGlobalToken } = useAppContext();
  const toast = useToast();
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();
  const [tokenState, setStakeToken] = useState("");

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 5 * 1024 * 1024, // 5MB em bytes
    onDrop: (acceptedFiles, rejectedFiles) => {
      const acceptedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];
      const validFiles = acceptedFiles.filter((file) =>
        acceptedFileTypes.includes(file.type)
      );
      const invalidFiles = acceptedFiles.filter(
        (file) => !acceptedFileTypes.includes(file.type)
      );

      if (invalidFiles.length > 0) {
        const errorMessage = `O(s) seguinte(s) arquivo(s) não é(são) suportado(s): ${invalidFiles
          .map((file) => file.name)
          .join(", ")}`;
        toast({
          title: "Erro ao anexar arquivos",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    },
    onDropRejected: (rejectedFiles) => {
      const errorMessage = `O(s) seguinte(s) arquivo(s) excede(m) o tamanho máximo permitido de 5MB ou não é de um tipo suportado: ${rejectedFiles
        .map((file) => file.name)
        .join(", ")}`;
      toast({
        title: "Erro ao anexar arquivos",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleSend = async () => {
    if (isSending) {
      // Evitar múltiplos envios enquanto a chamada da API está em andamento
      return;
    }

    const tokenData = await getToken(emailGlobal, passwordGlobal);
    const salvaToken = tokenData.access_token;

    setIsSending(true);

    if (files.length === 0) {
      toast({
        title: "Erro ao enviar",
        description:
          "Nenhum arquivo anexado. Por favor, anexe pelo menos um arquivo antes de enviar.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsSending(true);

      if (files.length === 0) {
        toast({
          title: "Erro ao enviar",
          description:
            "Nenhum arquivo anexado. Por favor, anexe pelo menos um arquivo antes de enviar.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      await Promise.all(
        files.map(async (file, i) => {
          const base64 = await readFileAsBase64(file);
          const fileName = file && file.name;
          const trimmedFileName =
            fileName.length > 50 ? `${fileName.slice(-50)}` : fileName;

          await postAnexo(salvaToken, "ZC4", i, chave, base64, trimmedFileName);
        })
      );

      setIsSent(true); // Definir como enviado somente após o loop de envio bem-sucedido
      toast({
        title: "Arquivos enviados com sucesso",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erro ao enviar arquivos:", error);
      toast({
        title: "Erro ao enviar",
        description:
          "Ocorreu um erro ao enviar os arquivos. Por favor, tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      setIsSent(false); // Definir como não enviado em caso de erro
    } finally {
      setIsSending(false);
    }
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "application/pdf":
        return BsFiletypePdf;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return BsFiletypeXlsx;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return BsFiletypeDocx;
      case "image/png":
      case "image/jpeg":
        return BsFiletypePng;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Flex
        {...getRootProps({
          className: "dropzone",
          p: 4,
          borderWidth: 2,
          borderRadius: "md",
          borderColor: "gray.300",
          borderStyle: "line",
          cursor: "pointer",
          alignItems: "center",
          flexDirection: "column",
          width: "300px",
          marginTop: "15px",
        })}
      >
        <input {...getInputProps()} />
        <Text mb={2} color="gray.500">
          Clique aqui para anexar evidências.
        </Text>
        <Flex>
          <Icon as={BsFiletypePdf} boxSize={8} color="gray.500" mx={1} />
          {/* <Icon as={BsFiletypeXlsx} boxSize={8} color="gray.500" mx={1} />
          <Icon as={BsFiletypeDocx} boxSize={8} color="gray.500" mx={1} /> */}
          <Icon as={BsFiletypePng} boxSize={8} color="gray.500" mx={1} />
        </Flex>
      </Flex>
      {files.length > 0 && (
        <Box mt={4}>
          <Text fontSize="lg" fontWeight="bold">
            Arquivos ({files.length})
          </Text>
          {files.map((file, index) => (
            <Stack key={index} direction="row" align="center" mt={2}>
              <Icon as={getFileIcon(file.type)} boxSize={5} mr={2} />
              <Text maxWidth="50ch" overflow="hidden" textOverflow="ellipsis">
                {file.path.length > 50
                  ? `...${file.path.slice(-50)}`
                  : file.path}
              </Text>
              <CloseButton
                size="sm"
                color="red"
                onClick={() => handleRemoveFile(index)}
              />
            </Stack>
          ))}
        </Box>
      )}

      <Flex mt={4}>
        <Button
          leftIcon={isSent ? <MdCheck /> : <MdSend />}
          onClick={handleSend}
          disabled={isSent}
          colorScheme={isSent ? "green" : "blue"}
        >
          {isSending ? "Enviando..." : isSent ? "Enviado" : "Enviar"}
        </Button>
        {isSent && <Icon as={MdCheck} boxSize={6} color="green.500" ml={4} />}
        {isSent && (
          <Text ml={2} color="green.500">
            Enviado com sucesso!
          </Text>
        )}
      </Flex>
    </Box>
  );
}
