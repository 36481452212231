import { v4 as uuidv4 } from "uuid";

const STATUS_ON_DECK = {
  id: uuidv4(),
  name: "On Deck",
  color: "blue.300",
};
const STATUS_IN_PROGRESS = {
  id: uuidv4(),
  name: "In Progress",
  color: "yellow.400",
};
const STATUS_TESTING = {
  id: uuidv4(),
  name: "Testing",
  color: "pink.300",
};
const STATUS_DEPLOYED = {
  id: uuidv4(),
  name: "Deployed",
  color: "green.300",
};
export const STATUSES = [
  STATUS_ON_DECK,
  STATUS_IN_PROGRESS,
  STATUS_TESTING,
  STATUS_DEPLOYED,
];

export const DATA = [
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.1",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Realização Mensal de Safety Walk.",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento":
      "Variável (90 pontos por Safety Walk limitado a um por mês)",
    "Pontuação Máxima": "270",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.2",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Check list de frota é realizado trimestralmente.",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.3",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Exposição das Regras e Princípios de SSMA no escritório e na pasta dos vendedores.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.4",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Equipamentos de segurança instalados e validados.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.5",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Sinalização do prédio (saídas de emergência e utilização do corrimão).",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.6",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Dicas e Reportes de SSMA.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "150",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.7",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Histórico de Multas da Equipe.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.8",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Controlar índices de acidentes.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "300",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.9",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: " São feitos treinamentos proativos sobre SSMA, envolvendo temas como reportes, multas, acidentes, práticas inseguras, direção defensiva.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.10",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Possuir pessoas treinadas e designadas a integrar a brigada de incêndio",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.11",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Todos os funcionários com CNH fizeram curso de direção defensiva.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.12",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Todos os funcionários do distribuidor utilizam Equipamentos de Proteção Individual (EPI) para realização de suas tarefas: Vendedores B2B em visitas às Fábricas de clientes e funcionários dos armazéns nos trabalhos diários.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.13",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Possuir ponto focal de SSMA.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "SSMA",
    Número: "1.1.14",
    Bucket: "SSMA",
    Grupo: "Gestão de SSMA",
    Item: "Todos os veículos frota devem possuir os ítens de segurança previstos por lei: Air Bag, ABS e Barras de Proteção Lateral.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "TI",
    Número: "2.1.1",
    Bucket: "Tecnologia da Informação",
    Grupo: "Gestão da Informação",
    Item: "Os Analistas de Informação devem participar das teleconferências e treinamentos presenciais sempre que estes forem agendados pela área de TI da Raízen.",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "150",
  },
  {
    id: uuidv4(),
    Avaliador: "TI",
    Número: "2.1.2",
    Bucket: "Tecnologia da Informação",
    Grupo: "Gestão da Informação",
    Item: "Realizar fechamento de estoque mensalmente no sistema",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "150",
  },
  {
    id: uuidv4(),
    Avaliador: "TI",
    Número: "2.1.3",
    Bucket: "Tecnologia da Informação",
    Grupo: "Gestão da Informação",
    Item: "Ter as linhas do DRE padrão, Módulo financeiro da TOTVS funcionando e enviar para o consultor comercial Raízen.",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "300",
  },
  {
    id: uuidv4(),
    Avaliador: "TI",
    Número: "2.1.4",
    Bucket: "Tecnologia da Informação",
    Grupo: "Gestão da Informação",
    Item: "Dados de cadastro dos produtos Shell estão de acordo com o padrão como volume, família, descrição, entre outros.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "TI",
    Número: "2.1.5",
    Bucket: "Tecnologia da Informação",
    Grupo: "Gestão da Informação",
    Item: "Dados de segmento dos clientes devem estar de acordo com a lista padrão passada pela Raízen.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "TI",
    Número: "2.1.6",
    Bucket: "Tecnologia da Informação",
    Grupo: "Gestão da Informação",
    Item: "Todos os clientes da Revenda possuem o potencial de volume registrado no sistema.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "TI",
    Número: "2.1.7",
    Bucket: "Tecnologia da Informação",
    Grupo: "Gestão da Informação",
    Item: '"Realizar anualmente o ""Renova Cadastro"""',
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "3.1.2",
    Bucket: "Recursos Humanos",
    Grupo: "Gestão de RH",
    Item: "O Distribuidor comunica a sua equipe sobre a Campanha de Incentivo em reuniões e matinais; As conquistas da Campanha de Incentivo são celebradas com a equipe e o momento é registrado com entrega de Troféu/Medalhas e existe planilha especifica para acompanhamento diário dos resultados da Campanha de Incentivo",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "3.1.1",
    Bucket: "Recursos Humanos",
    Grupo: "Gestão de RH",
    Item: "Adotar o sistema de remuneração variável por pontos (ferramenta ROTA) ou o sistema de remuneração sugerido pela Raízen caso o ROTA ainda não tenha sido implementado.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "3.1.3",
    Bucket: "Recursos Humanos",
    Grupo: "Gestão de RH",
    Item: "Distribuidor deve realizar o onboarding para novos colaboradores e para funcionários que mudaram de função antes de iniciarem suas atividades visando ter um time capacitado e com clareza do negócio.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.1.1",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Qualidade do Troca Certa",
    Item: "Mostrar o reporte da última avaliação do GQPD, com o resultado percentual atingido pelo distribuidor. A pontuação do ítem será de acordo com o atingimento percentual do distribuidor conforme abaixo:",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "50",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.1.2",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Qualidade do Troca Certa",
    Item: "Apresentar no campo - in loco - as ações concluidas, fruto de ações corretivas da última inspeção do GQPD.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "50",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.1.3",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Qualidade do Troca Certa",
    Item: "Cascatear resultado da avaliação do GQPD para todo o time envolvido na operação Troca Certa",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "50",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.2.1",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Pessoas do Troca Certa",
    Item: "Coordenador de Troca Certa garante treinamento dos vendedores abordando os temas: Vendas, Marketing, DPQA e HSSE ",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "50",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.3.1",
    Bucket: "Troca Certa",
    Grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    Item: "Distribuidor deve possuir o Plano de Atendimento a Emergência atualizado",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.3.2",
    Bucket: "Troca Certa",
    Grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    Item: "Distribuidor deve possuir KITs de emergência nas áreas dos tanques",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.3.3",
    Bucket: "Troca Certa",
    Grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    Item: "Distribuidor deve possuir KITs de emergência nos caminhões",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.3.4",
    Bucket: "Troca Certa",
    Grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    Item: "Distribuidor deve possuir contrato com empresa especializada em Emergência Ambiental",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.3.5",
    Bucket: "Troca Certa",
    Grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    Item: "Distribuidor deve possuir Seguro Ambiental",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.4.1",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Vendas Troca Certa",
    Item: "Possuir um Coordenador dedicado ao gerenciamento de todo o processo do Troca Certa",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.4.2",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Vendas Troca Certa",
    Item: "Possuir um ponto focal, Supervisor ou Gerente, atuando na liderança comercial do Troca Certa.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.4.3",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Vendas Troca Certa",
    Item: "Distribuidor ter critérios claros como pré requisitos mínimos para instalação de tanques em clientes. ",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.4.4",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Vendas Troca Certa",
    Item: "Coordenador Troca Certa deve possuir uma Planilha de Controle de ativos ",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.4.5",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Vendas Troca Certa",
    Item: "O Supervisor, ou Gerente, ponto focal deve utilizar-se dos controles gerados pelo Coordenador de Granel para garantir que o tema Troca Certa esteja presentes nas reuniões matinais e mensais.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Qualidade",
    Número: "4.4.6",
    Bucket: "Troca Certa",
    Grupo: "Gestão de Vendas Troca Certa",
    Item: "O Coordenador de Troca Certa deve garantir o atendimento em sua totalidade dos ítens de qualidade do manual do GQPD pertinentes a armazenagem de lubrificantes a granel.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "5.1.1",
    Bucket: "Estrutura de Vendas",
    Grupo: "Estrutura de Suporte à Vendas",
    Item: "Possuir Coordenador de marketing dedicado para implementar as promoções e campanhas, garantir alinhamento na aplicação de merchandising, monitoramento de KPI's e reportes de informações e garantir desenvolvimento do CVP do distribuidor.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "5.1.2",
    Bucket: "Estrutura de Vendas",
    Grupo: "Estrutura de Suporte à Vendas",
    Item: "Possuir supervisor de vendedores internos.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "5.1.3",
    Bucket: "Estrutura de Vendas",
    Grupo: "Estrutura de Suporte à Vendas",
    Item: "Possuir estrutura de atendimento interno com recursos para suporte ao time de vendas externo.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "5.1.4",
    Bucket: "Estrutura de Vendas",
    Grupo: "Estrutura de Suporte à Vendas",
    Item: "Possuir um vendedor a cada 150.000 l de potencial de mercado ",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "500",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "5.1.5",
    Bucket: "Estrutura de Vendas",
    Grupo: "Estrutura de Suporte à Vendas",
    Item: "Possuir um supervisor de vendas para, no máximo, cada 8 vendedores",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "5.1.6",
    Bucket: "Estrutura de Vendas",
    Grupo: "Estrutura de Suporte à Vendas",
    Item: "Possuir STD (Suporte Técnico do Distribuidor)",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "5.1.7",
    Bucket: "Estrutura de Vendas",
    Grupo: "Estrutura de Suporte à Vendas",
    Item: "Possuir gerente de vendas ou recurso dedicado para direcionar o time de supervisores de forma estruturada caso não possua gerente de vendas. (situação deverá ser prevista em Business Plan)",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "6.1.1",
    Bucket: "Sistema de Gestão",
    Grupo: "Projeto Monitor",
    Item: "Foram definidos monitor e apoiadores do PEX no Distribuidor",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "6.1.2",
    Bucket: "Sistema de Gestão",
    Grupo: "Projeto Monitor",
    Item: "Existe um calendário de reuniões e todos participantes estão cientes",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "6.2.1",
    Bucket: "Sistema de Gestão",
    Grupo: "Plano de Negócios",
    Item: "Os objetivos para o ano foram definidos e documentados dentro do prazo estabelecido",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "6.2.2",
    Bucket: "Sistema de Gestão",
    Grupo: "Plano de Negócios",
    Item: "As ações de suporte para entrega do plano foram definidas conforme seus segmentos",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "6.2.3",
    Bucket: "Sistema de Gestão",
    Grupo: "Plano de Negócios",
    Item: "O distribuidor está cumprindo com as ações acordadas nos prazos acordados",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "6.2.4",
    Bucket: "Sistema de Gestão",
    Grupo: "Plano de Negócios",
    Item: "Foi construída Projeção Financeira com base no DRE",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "6.2.5",
    Bucket: "Sistema de Gestão",
    Grupo: "Plano de Negócios",
    Item: "O Business Plan do distribuidor foi aprovado pelo gerente comercial de vendas da Raízen.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "6.2.6",
    Bucket: "Sistema de Gestão",
    Grupo: "Plano de Negócios",
    Item: "Foi realizada Revisão Mensal do BP",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento":
      "Variável (90 pontos por revisão limitado a uma por mês)",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "6.2.7",
    Bucket: "Sistema de Gestão",
    Grupo: "Plano de Negócios",
    Item: "Todos os documentos e contratos/aditamentos entre a Raízen e o Distribuidor foram assinados",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "6.3.1",
    Bucket: "Sistema de Gestão",
    Grupo: "Gestão de Agregados",
    Item: "A participação nas vendas totais de produtos agregados pela equipe de vendedores de lubrificantes é:",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": '"Variável ',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "6.3.2",
    Bucket: "Sistema de Gestão",
    Grupo: "Gestão de Agregados",
    Item: "Caso o faturamento ultrapasse a participação máxima de 30%, o distribuidor deverá possuir equipe dedicada para trabalhar com produtos agregados. Se o percentual for menor que 30% o  o ponto não será avaliado (N/A).",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "150",
  },
  {
    id: uuidv4(),
    Avaliador: "Logistica",
    Número: "6.4.1",
    Bucket: "Sistema de Gestão",
    Grupo: "Gestão de Armazéns e Logística",
    Item: "Comparecimento de todos os caminhões para carregamento de pedido agendado",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Logistica",
    Número: "6.4.2",
    Bucket: "Sistema de Gestão",
    Grupo: "Gestão de Armazéns e Logística",
    Item: "Linearidade de Agendamentos/Carregamentos - O distribuidor atingiu a linearidade de Agendamentos e Carregamentos conforme acordado com a Shell conforme abaixo:",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável ',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Logistica",
    Número: "6.4.3",
    Bucket: "Sistema de Gestão",
    Grupo: "Gestão de Armazéns e Logística",
    Item: "O distribuidor deve possuir todos as autorizações e documentos legais necessários para a operação nos Armazéns",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "500",
  },
  {
    id: uuidv4(),
    Avaliador: "Logistica",
    Número: "6.4.4",
    Bucket: "Sistema de Gestão",
    Grupo: "Gestão de Armazéns e Logística",
    Item: "Distribuidor deve realizar checklist na transportadora",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "500",
  },
  {
    id: uuidv4(),
    Avaliador: "Logistica",
    Número: "6.4.5",
    Bucket: "Sistema de Gestão",
    Grupo: "Gestão de Armazéns e Logística",
    Item: "Em relação a quantidade de caminhões agendados para a retirada de produtos nas plantas e armazéns da Shell o distribuidor teve:",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Logistica",
    Número: "6.4.6",
    Bucket: "Sistema de Gestão",
    Grupo: "Gestão de Armazéns e Logística",
    Item: "Comunicar todo e qualquer acidente/indicente para CAE ",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Logistica",
    Número: "6.4.7",
    Bucket: "Sistema de Gestão",
    Grupo: "Gestão de Armazéns e Logística",
    Item: "Linearidade de Pedidos - O distribuidor atingiu a linearidade de pedidos conforme acordado com a Raízen conforme abaixo:",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "6.5.1",
    Bucket: "Sistema de Gestão",
    Grupo:
      "Gestão de Satisfação de Clientes (Customer Satisfaction Index) e OTIF (On Time In Full)",
    Item: "Existe procedimento rotineiro para pesquisar a satisfação dos clientes (ativo). Para os distribuidores com acesso ao Portal Integrado de Vendas considerar a pesquisa disponível nessa ferramenta",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor de PD",
    Número: "6.5.2",
    Bucket: "Sistema de Gestão",
    Grupo:
      "Gestão de Satisfação de Clientes (Customer Satisfaction Index) e OTIF (On Time In Full)",
    Item: "Atingir nível dfe OTIF de 93% das ordens dos clientes Raízen",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.1.1",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gerente de Vendas",
    Item: "Realizar reunião com os supervisores semanalmente e de forma estruturada discutindo iniciativas e ações dos respectivos canais de cada supervisor",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.1.2",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gerente de Vendas",
    Item: '"Saber explicar aos subordinados os 7V\'s e a razão de cada um dos passos e Conhecer e transmitir os conceitos de Execução do PDV na ""Visão do Consumidor"" "',
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.1.3",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gerente de Vendas",
    Item: "Gerente deve saber explicar de forma precisa e segura todas as plataformas e ações do Varejo.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.1.4",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gerente de Vendas",
    Item: "Conhecer, aplicar e gerenciar os indicadores do SPANCOP (Força do Tubo, Alvo incremental, Tempo de Ciclo, Taxa de Sucesso). Atentar-se aos BIG 5 e TOP 5. Orientar a equipe nas grandes negociações, participando quando necessário e dando apoio ao desenvolvimento da equipe.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.1.5",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gerente de Vendas",
    Item: "Ter um plano de ação definido para recuperação dos 10 maiores clientes CHURN da gerência do B2C",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.1.6",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gerente de Vendas",
    Item: " Gerente de vendas realiza processo de coaching junto aos supervisores",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.1",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Conhecer seus alvos de clientes efetivos e o resultado obtido.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.2",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Conhecer seus alvos de volume total e resultado obtido.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.3",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Conhecer seus alvos de volume Premium e resultado obtido.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.4",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: " Conhece as plataformas e ações motivacionais e explicar seus pilares e benefícios de cada um deles.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.5",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Conhecer os 10 maiores clientes de Volume Premium por canal (Revenda e Varejo).",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.6",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Saber explicar aos subordinados os 7V's e a razão de cada um dos passos.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.7",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: '"Conhecer e transmitir os conceitos de Execução do PDV na ""Visão do Consumidor""."',
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.8",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Treinar a equipe conforme Matriz de Desempenho em Rota.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.9",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Conhecer os índices de Efetividade, Penetração e Cumprimento do Plano de Visitas e Efetividade de sua equipe.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.10",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Ter um plano de ação definido para recuperação dos 10 maiores clientes CHURN da sua supervisão.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.11",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Conhecer os 10 maiores clientes de despejo (no segmento Varejo) e ter plano de ação definido para reversão e contenção desse despejo",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.12",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Supervisor envia mensalmente reporte e relatório  (overview) com os resultados e principais ações realizadas no mês e ações planejadas para os próximos meses para time Raízen.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.13",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Conhecer e explicar os conceitos da calculadora de valor e saber utilizar. ",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.2.14",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    Item: "Supervisor deve possuir total controle sobre a informação de Postos com compra Zero e ações necessárias para reverter estas situações.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.1",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    Item: "Conhecer seus alvos de clientes efetivos e o resultado obtido.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.2",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    Item: "Conhecer seus alvos de volume total e resultado obtido.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.3",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    Item: "Conhecer seus alvos de volume Premium e resultado obtido.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.4",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    Item: "Saber quais são os 7V's e por quais razões deve-se adotar cada um dos passos.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.5",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    Item: '"Saber os conceitos de Execução do PDV na ""Visão do Consumidor""."',
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.6",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    Item: " Conhece as plataformas e ações motivacionais e sabe explicar os pilares e benefícios de cada uma delas.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.7",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    Item: "Vendedor efetua coletas de preço conforme rotina definida.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.8",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill Varejo",
    Item: "Consultor de Vendas Varejo implementa os treinamentos aos funcionários dos postos (frentistas, trocadores e gerentes). ",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.9",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill Varejo",
    Item: "Consultor de Vendas Varejo deve possuir total controle sobre a informação de Postos com compra Zero e ações necessárias para reverter estas situações.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.3.10",
    Bucket: "Metodologia de Vendas",
    Grupo: "Consultor de Vendas Drill Varejo",
    Item: "Conhecer e explicar os conceitos da calculadora de valor e saber utilizar. ",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "7.4.1",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gestão De Recursos Humanos B2C (Revenda+Varejo)",
    Item: "A participação da equipe do distribuidor em todos os módulos de treinamentos obrigatórios:",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "7.4.2",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gestão De Recursos Humanos B2C (Revenda+Varejo)",
    Item: " A nota média da equipe do distribuidor em relação as provas e/ou Quizz foi de:",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.5.1",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2B",
    Item: "Realizar semanalmente reunião com Vendendor para programação semanal",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.5.2",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2B",
    Item: "Dedicar-se exclusivamente a cuidar do seu mercado e chefia de sua equipe B2B",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.5.3",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2B",
    Item: "Conhecer seus alvos mensais de volume e o resultado obtido B2B",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.5.4",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2B",
    Item: "Conhecer seus alvos de volume total de produtos premium e o resultado obtido B2B (MIX PREMIUM)",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.5.5",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2B",
    Item: "Conhecer, aplicar e gerenciar os indicadores do SPANCOP (Força do Tubo, Alvo incremental, Tempo de Ciclo, Taxa de Sucesso). Atentar-se aos BIG 5 e TOP 5. Orientar a equipe nas grandes negociações, participando quando necessário e dando apoio ao desenvolvimento da equipe.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.5.6",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2B",
    Item: "Gerenciamento da retenção dos clientes (Churn) com plano de blindagem para os grandes clientes.",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.5.7",
    Bucket: "Metodologia de Vendas",
    Grupo: "Supervisor de Vendas B2B",
    Item: "Realiza Coaching com seus vendedores dentro do padrão Raízen B2B",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.6.1",
    Bucket: "Metodologia de Vendas",
    Grupo: "Vendedor Spancop B2B",
    Item: "Conhecer seus alvos mensais de volume e o resultado obtido B2B",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.6.2",
    Bucket: "Metodologia de Vendas",
    Grupo: "Vendedor Spancop B2B",
    Item: "Conhecer seus alvos devolume total de produtos premium e o resultado obtido B2B (MIX PREMIUM)",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "7.6.3",
    Bucket: "Metodologia de Vendas",
    Grupo: "Vendedor Spancop B2B",
    Item: "Conhece e preenche rotineiramente o SPANCOP e entende os principais indicadores (Força do Tubo, Alvo incremental, Tempo de Ciclo, Taxa de Sucesso), indicando também os BIG 5 e TOP 5. ",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "7.7.1",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gestão de Recursos Humanos B2B",
    Item: "O Distribuidor aplicou as Avaliações de Nivelamento B2B e todos os vendedores participaram e responderam a todos os questionamentos e o resultado foi:",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "7.7.2",
    Bucket: "Metodologia de Vendas",
    Grupo: "Gestão de Recursos Humanos B2B",
    Item: "Plano elaborado pelo supervisor para melhoria da equipe com base nos resultados da avaliação e coaching",
    "Período de Avaliação": "Anual",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.1.1",
    Bucket: "Marketing",
    Grupo: "Coordenador de Marketing",
    Item: "Participação dos Coordenadores de Marketing e Supervisores de Vendas nas reuniões mensais de acordo com a periodicidade solicitada pelo time Raízen.",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.1.2",
    Bucket: "Marketing",
    Grupo: "Coordenador de Marketing",
    Item: "O Distribuidor possui Fundo Local para investimento em clientes e controla os gastos dessas ações",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "150",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.1.3",
    Bucket: "Marketing",
    Grupo: "Coordenador de Marketing",
    Item: "O Coordenador de Marketing comunica e cascateia para a equipe de vendas as promoções e campanhas de incentivo vigentes em reuniões e matinais e garante que a equipe de vendas possua material explicativo das ações na pasta do vendedor.",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "150",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.1.4",
    Bucket: "Marketing",
    Grupo: "Coordenador de Marketing",
    Item: "Participação em rota no mínimo a cada 15 dias, utilizando o checklist disponibilizado por Marketing Raízen para capturar evidências",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.1.5",
    Bucket: "Marketing",
    Grupo: "Coordenador de Marketing",
    Item: "Coordenador de Marketing deve gerenciar as implementações de LubsDay do seu distribuidor",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "250",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.1.6",
    Bucket: "Marketing",
    Grupo: "Gestão de Marketing",
    Item: "Realização dos treinamentos obrigatórios.",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.1.7",
    Bucket: "Marketing",
    Grupo: "Coordenador de Marketing",
    Item: "Realiza acompanhamento e reporta os resultados de todas as atividades de marketing do seu distribuidor – envio de relatórios atualizados para Marketing Raízen, conforme periodicidade solicitada",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "150",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.1.8",
    Bucket: "Marketing",
    Grupo: "Gestão de Marketing",
    Item: "O coordenador de marketing avalia a performance de execução dos pontos de venda dos canais prioritários",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.2.1",
    Bucket: "Marketing",
    Grupo: "Gestão de Marketing",
    Item: "Percentual de efetivos cadastrados nas plataformas motivacionais. ",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.2.2",
    Bucket: "Marketing",
    Grupo: "Gestão de Marketing",
    Item: "O percentual de pontos de venda com trocador cadastrado nas plataformas motivacionais é de:",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.2.3",
    Bucket: "Marketing",
    Grupo: "Gestão de Marketing",
    Item: "O percentual de trocadores com tampinha cadastrada:",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.2.4",
    Bucket: "Marketing",
    Grupo: "Gestão de Marketing",
    Item: "Percentual de pontos resgatados versus pontos Creditados",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Trade Mkt",
    Número: "8.2.5",
    Bucket: "Marketing",
    Grupo: "Coordenador de Marketing",
    Item: "Percentual de cobertura da execução das campanhas promoções e pontos de venda. ",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "9.1.1",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica Supervisor Drill B2C",
    Item: "Reunir-se com seus vendedores (Matinal) antes da saída para o mercado obedecendo execução de acordo com o previsto no Drill B2C",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável ',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "9.1.5",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica Supervisor Drill B2C",
    Item: "Realizar reunião matinal com time de vendas",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável ',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "9.1.2",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica Supervisor Drill B2C",
    Item: "Realizar reunião vespertina com time de vendas",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável ',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.2.1",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    Item: "Verificar Planejamento do PDV",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.2.2",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    Item: "Verificar Merchandising no PDV",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.2.3",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    Item: "Verificar Exposição de Produtos",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.2.4",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    Item: "Verificar Checklist dos clientes B2C",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.2.5",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    Item: "Verificar Estoque do PDV",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.2.6",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    Item: "Verificar Preços",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.2.7",
    Bucket: "Execução B2C",
    Grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    Item: "Vender produtos históricos e indicados para o PDV em quantidades compatíveis discutindo apenas ao final com o cliente",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.3.1",
    Bucket: "Execução B2C",
    Grupo: "Execução de Mercado B2C",
    Item: "Execução da Visão do consumidor no PDV: Merchandising",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.3.2",
    Bucket: "Execução B2C",
    Grupo: "Execução de Mercado B2C",
    Item: "Execução da Visão do consumidor no PDV: Exposição",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "9.3.3",
    Bucket: "Execução B2C",
    Grupo: "Execução de Mercado B2C",
    Item: "Execução da Visão do consumidor no PDV: Precificação",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "9.4.1",
    Bucket: "Execução B2C",
    Grupo: "KPI's de Visitação B2C",
    Item: "KPI's de Visitação: Cobertura",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "9.4.2",
    Bucket: "Execução B2C",
    Grupo: "KPI's de Visitação B2C",
    Item: "KPI's de Visitação: Efetividade",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "9.4.3",
    Bucket: "Execução B2C",
    Grupo: "KPI's de Visitação B2C",
    Item: "KPI's de Visitação: Penetração",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "9.4.4",
    Bucket: "Execução B2C",
    Grupo: "KPI's de Visitação B2C",
    Item: "Cumprimento plano de visitas 90% ou acima",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "10.1.1",
    Bucket: "Execução B2B",
    Grupo: "Execução de mercado e pipeline de oportunidades B2B",
    Item: "Quanto a prospecção de novos clientes a Força do Tubo foi: (Igual ou Superior a 5)",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "10.1.2",
    Bucket: "Execução B2B",
    Grupo: "Execução de mercado e pipeline de oportunidades B2B",
    Item: "Quanto ao plano de negocios o Alvo Incremental Entregue foi: (Igual ou superior a 100%)",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "10.1.3",
    Bucket: "Execução B2B",
    Grupo: "Execução de mercado e pipeline de oportunidades B2B",
    Item: "Quanto ao resultado, a Taxa de Sucesso foi: (Igual ou superior a 25%)",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Consultor Franquia",
    Número: "10.1.4",
    Bucket: "Execução B2B",
    Grupo: "Execução de mercado e pipeline de oportunidades B2B",
    Item: "Quanto a efetividade das prospecções o Tempo de Ciclo foi: (Inferior a 100 dias)",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": '"Variável',
    "Pontuação Máxima": null,
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "10.2.1",
    Bucket: "Execução B2B",
    Grupo: "Rotina Básica Supervisor SPANCOP",
    Item: "Faz gestão e orienta sua equipe na utilização das ferramentas de SaleS 1st (Call Plan, POPSA, SPANCOP, Call Report, Plano de Contas) - Atualização SPANCOP",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "10.2.2",
    Bucket: "Execução B2B",
    Grupo: "Rotina Básica Supervisor SPANCOP",
    Item: "Supervisor realiza coaching ",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "100",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "10.3.1",
    Bucket: "Execução B2B",
    Grupo: "Rotina Básica Vendedor SPANCOP",
    Item: "Realiza o P.O.P.S.A antes de entrar no cliente preparando suas visitas B2B",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "10.3.2",
    Bucket: "Execução B2B",
    Grupo: "Rotina Básica Vendedor SPANCOP",
    Item: "Utiliza adequadamente o Call Plan no planejamento e prepara os reportes das visitas (Call Report) no B2B",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "10.3.3",
    Bucket: "Execução B2B",
    Grupo: "Rotina Básica Vendedor SPANCOP",
    Item: "Atualização SPANCOP",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento": "Sim ( ) Não ( )",
    "Pontuação Máxima": "200",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "11.1.1",
    Bucket: "Vendas",
    Grupo: "Resultados de Vendas",
    Item: "Venda de Lubrificantes Carros: Em relação ao alvo de volume previsto no Plano de Negócios, o resultado atingido do Distribuidor foi:",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento":
      "Variável e proporcional ao atingimento entre o intervalo de 90% e 120%",
    "Pontuação Máxima": "1000",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "11.1.2",
    Bucket: "Vendas",
    Grupo: "Resultados de Vendas",
    Item: "Venda de Lubrificantes Motos: Em relação ao alvo de volume previsto no Plano de Negócios, o resultado atingido do Distribuidor foi:",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento":
      "Variável e proporcional ao atingimento entre o intervalo de 90% e 120%",
    "Pontuação Máxima": "1000",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "11.1.3",
    Bucket: "Vendas",
    Grupo: "Resultados de Vendas",
    Item: "Venda de Lubrificantes Caminhões: Em relação ao alvo de volume previsto no Plano de Negócios, o resultado atingido do Distribuidor foi:",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento":
      "Variável e proporcional ao atingimento entre o intervalo de 90% e 120%",
    "Pontuação Máxima": "1000",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "11.1.4",
    Bucket: "Vendas",
    Grupo: "Resultados de Vendas",
    Item: "Venda de Lubrificantes Indústria: Em relação ao alvo de volume previsto no Plano de Negócios, o resultado atingido do Distribuidor foi:",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento":
      "Variável e proporcional ao atingimento entre o intervalo de 80% e 120%",
    "Pontuação Máxima": "1000",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "11.1.5",
    Bucket: "Vendas",
    Grupo: "Resultados de Vendas",
    Item: "Venda de Lubrificantes Premium : Em relação a Venda de Produtos Premium o resultado atingido em relação ao alvo previsto no Plano de Negócios foi (máximo 120%):",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento":
      "Variável e proporcional ao atingimento entre o intervalo de 90% e 120%",
    "Pontuação Máxima": "1000",
  },
  {
    id: uuidv4(),
    Avaliador: "Time de BI",
    Número: "11.1.6",
    Bucket: "Vendas",
    Grupo: "Resultados de Vendas",
    Item: "Efetivos B2C: Em relação à venda de produtos para os clientes, a fim de assegurar a disponibilidade dos produtos Shell para o consumidor, a média de clientes efetivos do ano contra o Business Plan foi (máximo 120%):",
    "Período de Avaliação": "Trimestral",
    "Critério de Atingimento":
      "Variável e proporcional ao atingimento entre o intervalo de 80% e 120%",
    "Pontuação Máxima": "1000",
  },
];

export const criterios = ["Sim ou Não", "Variável"];
export const periodos = ["Anual", "Trimestral"];
