import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Input,
  Flex,
  Button,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  Card,
  InputGroup,
  InputLeftAddon,
  Heading,
  Divider,
  Select, // Importando o componente de card do Chakra UI
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

const SelecionarPeriodoAnexo = () => {
  // Estados para armazenar os valores dos inputs

  const [erroAno, setErroAno] = useState("");
  const [erroTrimestre, setErroTrimestre] = useState("");
  const navigate = useNavigate();
  const { anoGlobal, setAnoGlobal, trimestreGlobal, setTrimestreGlobal } =
    useAppContext();
  // Função para lidar com a submissão do formulário
  const handleSubmit = (event) => {
    event.preventDefault();

    const route = `/evidencias/${encodeURIComponent(
      anoGlobal
    )}/${encodeURIComponent(trimestreGlobal)}`;
    navigate(route);
  };

  return (
    <Box p={4}>
      <>
        <Heading>Selecionar Período - Anexar Evidências</Heading>
        <Divider borderWidth="2px" marginTop={"10px"} />
      </>

      <form onSubmit={handleSubmit}>
        <Flex direction="column">
          <InputGroup marginTop={"20px"}>
            <InputLeftAddon children="Ano Safra" width="175px" />
            <Select
              type="text"
              value={anoGlobal}
              onChange={(e) => {
                setAnoGlobal(e.target.value);
              }}
              mb={4}
              maxLength={4}
              isInvalid={erroAno} // Aplica estilo de erro se houver um erro
            >
              <option value="2023">2023/2024</option>
              <option value="2024">2024/2025</option>
              <option value="2025">2025/2026</option>
              <option value="2026">2026/2027</option>
              <option value="2027">2027/2028</option>
              <option value="2028">2028/2029</option>
              <option value="2029">2029/2030</option>
            </Select>
          </InputGroup>

          {erroAno && (
            <Alert status="error" mb={4} textAlign="center">
              <AlertIcon />
              <AlertTitle>{erroAno}</AlertTitle>
            </Alert>
          )}
          <InputGroup marginTop={"20px"}>
            <InputLeftAddon children="Trimestre" width="175px" />

            <Select
              type="number"
              value={trimestreGlobal}
              onChange={(e) => {
                setTrimestreGlobal(e.target.value);
              }}
              mb={4}
              min="1"
              max="4"
              isInvalid={erroTrimestre} // Aplica estilo de erro se houver um erro
            >
              <option value="1">1 (abr - mai - jun)</option>
              <option value="2">2 (jul - ago - set)</option>
              <option value="3">3 (out - nov - dez)</option>
              <option value="4">4 (jan - fev - mar)</option>
            </Select>
          </InputGroup>
          {erroTrimestre && (
            <Alert status="error" mb={4} textAlign="center">
              <AlertIcon />
              <AlertTitle>{erroTrimestre}</AlertTitle>
            </Alert>
          )}
          <Button type="submit" mt="12px" colorScheme="whatsapp" w="150px">
            Selecionar
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default SelecionarPeriodoAnexo;
