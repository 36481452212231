export async function recuperarSenhaPost(email) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Basic YWRtaW46ZGVjQG5leHVz");

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  const apiUrl = `https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/user_api/?empresa=01&filial=01&email=${email}`;

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error recuperar senha:", error);
  }
}
