import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Divider,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";

import { putAlterarSenha } from "../api/alterar-senha-api";
import { useAppContext } from "../context/AppContext";

const ForgotPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { globalToken, setPasswordGlobal } = useAppContext();
  const { emailUsuario, setEmailUsuario } = useAppContext();

  const decoded = jwtDecode(globalToken);
  const userDecoded = decoded.sub;
  const userEmail = emailUsuario;
  const handleChange = () => {
    setErrorMessage("");
  };

  const handleChangePassword = async () => {
    setLoading(true);
    while (true) {
      if (newPassword !== confirmNewPassword) {
        setErrorMessage("As senhas não coincidem. Por favor, tente novamente.");
        setLoading(false);
        return;
      }

      try {
        const response = await putAlterarSenha(
          globalToken,
          userDecoded,
          newPassword
        );

        if (response.status) {
          setNewPassword("");
          setConfirmNewPassword("");
          setErrorMessage(""); // Limpa qualquer mensagem de erro anterior
          setPasswordGlobal(newPassword);
          setSuccessMessage("Senha alterada com sucesso!");
          setLoading(false);
          break; // Sai do loop se a alteração de senha for bem-sucedida
        } else {
          setErrorMessage(response.message);
          setLoading(false);
          break;
        }
      } catch (error) {
        console.error("Erro ao alterar senha:", error);
        setErrorMessage("Erro ao alterar senha. Tentando novamente...");
        setLoading(false);
        break;
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Heading as="h2" size="lg" marginBottom="4">
        Alterar Senha
      </Heading>
      <Divider />
      <InputGroup marginTop="6">
        <InputLeftAddon children="Email do Usuário" width="200px" />
        <Input type="text" value={userEmail} isDisabled />
      </InputGroup>
      <InputGroup marginTop="6">
        <InputLeftAddon children="Nova Senha" width="200px" />
        <Input
          type={showPassword ? "text" : "password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <InputRightElement>
          <Button
            variant="ghost"
            onClick={handleTogglePasswordVisibility}
            aria-label={showPassword ? "Ocultar senha" : "Mostrar senha"}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </Button>
        </InputRightElement>
      </InputGroup>
      <InputGroup marginTop="6">
        <InputLeftAddon children="Confirme a Nova Senha" width="200px" />
        <Input
          type={showPassword ? "text" : "password"}
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        <InputRightElement>
          <Button
            variant="ghost"
            onClick={handleTogglePasswordVisibility}
            aria-label={showPassword ? "Ocultar senha" : "Mostrar senha"}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </Button>
        </InputRightElement>
      </InputGroup>
      {errorMessage && (
        <Text color="red.500" marginTop="4">
          {errorMessage}
        </Text>
      )}
      {successMessage && (
        <Text color="green.500" marginTop="4">
          {successMessage}
        </Text>
      )}
      {newPassword === confirmNewPassword && newPassword && (
        <Text color="green.500" marginTop="4">
          Senhas coincidem.
        </Text>
      )}
      <Button
        colorScheme="green"
        marginTop="6"
        onClick={handleChangePassword}
        isLoading={loading}
        loadingText="Alterando..."
      >
        Alterar Senha
      </Button>
    </>
  );
};

export default ForgotPassword;
