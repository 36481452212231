export async function postActiveYear(token, ano, referencia, status) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = "";

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const apiUrl = `https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/avaliacoes_pex/periodo?ano=${ano}&referencia=${referencia}&status=${status}`;

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error post anexo:", error);
  }
}
