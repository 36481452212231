export const distribuidores = [
  {
    _id: "63daaa1fc89356022a883b0f",
    distribuidor: "DECLUB",
    envelope_fee: "DECLUB",
    internal_id: 14,
    url: "https://decminas-prd-protheus.totvscloud.com.br:16804/rest/",
    url_teste:
      "https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/",
    __v: 0,
  },
  {
    _id: "629a434425d638350448e8f5",
    distribuidor: "BRAZMAX",
    envelope_fee: "BRAZMAX",
    internal_id: 61,
    url: "https://rq6hue-prd-protheus.totvscloud.com.br:18828/rest_prd/",
    url_teste: "http://187.94.63.58:45927/rest/",
    __v: 0,
  },
  {
    _id: "639a89bf09369bb67710cdc7",
    distribuidor: "NACIONAL",
    envelope_fee: "NACIONAL",
    internal_id: 102,
    url: "https://nacional-prd-protheus.totvscloud.com.br:12529/rest/",
    url_teste: "http://nacional-tst-protheus.totvscloud.com.br:45803/rest /",
  },
  {
    _id: "629a432625d638350448e8f4",
    distribuidor: "BOA NOVA",
    envelope_fee: "BOA NOVA",
    internal_id: 76,
    url: "https://boanova-prd-protheus.totvscloud.com.br:13529/rest/",
    url_teste: "",
    __v: 0,
  },
  {
    _id: "62997f54af7b7f1fb915bceb",
    distribuidor: "AGRICOPEL",
    envelope_fee: "AGRICOPEL",
    internal_id: 68,
    url: "https://protheus.agricopel.com.br:1778/rest_prd/",
    url_teste: "",
    __v: 0,
  },
  {
    _id: "629a51f425d638350448e90c",
    distribuidor: "PORTOLUB",
    envelope_fee: "PORTOLUB",
    internal_id: 26,
    url: "https://portolub-prd-protheus.totvscloud.com.br:17824/rest/",
    url_teste: "https://187.94.63.58:44823/rest/",
    __v: 0,
  },

  {
    _id: "64074f5fa98f970229c0bb91",
    distribuidor: "PNBLUB",
    envelope_fee: "PNBLUB",
    internal_id: 56,
    url: "https://pnblub-prd-protheus.totvscloud.com.br:11585/rest/",
    url_teste: "https://pnblub-tst-protheus.totvscloud.com.br:41803/rest_tst/",
    __v: 0,
  },
  {
    _id: "629a51c725d638350448e90a",
    distribuidor: "LUBTROL",
    envelope_fee: "LUBTROL",
    internal_id: 22,
    url: "https://lubtrol-prd-protheus.totvscloud.com.br:12829/rest/",
    url_teste: "http://187.94.63.58:39802/rest/",
    __v: 0,
  },

  {
    _id: "629a518125d638350448e907",
    distribuidor: "LUBPAR",
    envelope_fee: "LUBPAR",
    internal_id: 45,
    url: "https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/",
    url_teste: "https://187.94.63.58:37826/rest/",
    __v: 0,
  },
  {
    _id: "639a88f809369bb67710abb6",
    distribuidor: "FORMULA",
    envelope_fee: "FORMULA",
    internal_id: 101,
    url: "https://goiaslub-prd-protheus.totvscloud.com.br:15809/rest/",
    url_teste: "http://187.94.63.58:42927/rest/",
    __v: 0,
  },
  {
    _id: "629a521325d638350448e90d",
    distribuidor: "QUITE",
    envelope_fee: "QUITE",
    internal_id: 16,
    url: "https://quite-prd-protheus.totvscloud.com.br:14808/rest/",
    url_teste: "https://187.94.63.58:41128/rest/",
    __v: 0,
  },
  {
    _id: "629a529125d638350448e911",
    distribuidor: "SPM",
    envelope_fee: "SPM",
    internal_id: 0,
    url: "https://pmz-prd-protheus.totvscloud.com.br:14529/rest/",
    url_teste: "",
    __v: 0,
  },
];

export const distribuidores_uf = [
  { UF: "AL", DIST: "PNBLUB" },
  { UF: "AM", DIST: "SPM" },
  { UF: "BA", DIST: "BRAZMAX" },
  { UF: "CE", DIST: "LUBTROL" },
  { UF: "DF", DIST: "FORMULA" },
  { UF: "ES", DIST: "NACIONAL" },
  { UF: "GO", DIST: "FORMULA" },
  { UF: "MA", DIST: "SPM" },
  { UF: "MA", DIST: "DECLUB" },
  { UF: "MS", DIST: "FORMULA" },
  { UF: "MT", DIST: "FORMULA" },
  { UF: "PA", DIST: "SPM" },
  { UF: "PB", DIST: "PNBLUB" },
  { UF: "PE", DIST: "PNBLUB" },
  { UF: "PI", DIST: "LUBTROL" },
  { UF: "PR", DIST: "AGRICOPEL" },
  { UF: "RJ", DIST: "LUBPAR" },
  { UF: "RN", DIST: "PNBLUB" },
  { UF: "RS", DIST: "PORTOLUB" },
  { UF: "SC", DIST: "AGRICOPEL" },
  { UF: "SE", DIST: "BRAZMAX" },
  { UF: "SP", DIST: "BOA NOVA" },
  { UF: "SP", DIST: "LUBPAR" },
  { UF: "SP", DIST: "QUITE" },
  { UF: "TO", DIST: "FORMULA" },
];
