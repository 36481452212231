import mock_capitulos from "./capitulos-mock";

const dataMock = [
  {
    evidencia: "S",
    descricao: `O ponto focal de SSMA deve realizar Safety Walk (Caminhada de
      Segurança) mensalmente, cobrindo todos os pontos desse título de SSMA no
      distribuidor, elaborando um plano de ação para cobrir eventuais lacunas que
      sejam identificadas. A Safety Walk deverá ter a participação de outros membros
      do distribuidor (podendo ser membros de equipes de vendas, administrativos ou
      de marketing, por exemplo). Deverá ser registrada com fotos e uma comunicação
      (via e-mail) para os demais colaboradores.`,
    avaliador: "SSMA",
    numero: "1.1.1",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo: "Realização Mensal de Safety Walk.",
    periodo: "Trimestral",
    criterio: "V (90 pontos por Safety Walk limitado a um por mês)",
    pontuacao_maxima: 270,
  },
  {
    evidencia: "S",
    descricao: `O Checklist dos itens críticos do veículo deverá ser realizado de forma
    bimestral em todos os veículos da empresa. Essa vistoria deverá ser realizada
    pelo supervisor e/ou gerente de vendas e assinada pelo condutor no momento
    da vistoria. Se houver manutenção a ser realizada, deverá ser feita
    imediatamente.`,
    avaliador: "SSMA",
    numero: "1.1.2",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo: "Check list de frota é realizado trimestralmente.",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Todos os funcionários do distribuidor devem conhecer os 5 hábitos de
    Direção Segura (5HDS), os quais devem estar expostos no mural do distribuidor
    e nas pastas dos consultores de vendas. `,
    avaliador: "SSMA",
    numero: "1.1.3",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo:
      "Exposição das Regras e Princípios de SSMA no escritório e na pasta dos vendedores.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Todo Distribuidor deve manter os dispositivos de segurança contra
    incêndio (extintores, detectores de fumaça, sprinklers, mangueiras, hidrantes...)
    de acordo com as regras estabelecidas pelo corpo de bombeiros em perfeito
    estado de funcionamento. 
    `,
    avaliador: "SSMA",
    numero: "1.1.4",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo: "Equipamentos de segurança instalados e validados.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `O prédio do distribuidor deve ter sinalização de saída de emergência, rota
    de fuga, utilização do corrimão e EPI’s mandatórios. Para distribuidores que
    tenham cargas suspensas, o local também deverá ser sinalizado. No caso de
    outras sinalizações que se fizerem necessárias, basta seguir um padrão de placas
    determinado pela Engenharia de Segurança do Trabalho. `,
    avaliador: "SSMA",
    numero: "1.1.5",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo:
      "Sinalização do prédio (saídas de emergência e utilização do corrimão).",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Dicas e reportes de SSMA. O distribuidor pode incentivar a participação
    dos funcionários reconhecendo essas melhores dicas em reuniões e, se possível,
    com gratificações. As dicas podem ser transmitidas pela equipe por e-mail,
    mensagens de texto, ou qualquer outro meio de comunicação, desde que seja
    possível seu registro, destacando sempre o benefício da prática.
    Reconhecimentos das melhores práticas é recomendável. 
    `,
    avaliador: "SSMA",
    numero: "1.1.6",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo: "Dicas e Reportes de SSMA.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `O distribuidor deve realizar a gestão de multas e violações (histórico,
      Gerenciamento de consequências para redução de multas, abordagem em
      reuniões gerenciais, plano de ação com metas de redução) da equipe de vendas.
      Esse acompanhamento deve ser realizado mensalmente. No Plano de Ação,
      devem constar ações claras do que está sendo feito para diminuir o número de
      multas e violações, bem como um plano de “Gerenciamento de Consequências”,
      envolvendo advertências verbais e por escrito, podendo chegar à suspensão do
      colaborador em casos de reincidência em infrações graves. É necessário
      evidenciar o envolvimento da diretoria do distribuidor na gestão de “multas e
      violações” por meio do direcionamento, condução e acompanhamento do Plano
      de Ação, bem como no “Gerenciamento das Consequências”`,
    avaliador: "SSMA",
    numero: "1.1.7",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo: "Histórico de Multas da Equipe.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deverá ter cultura de acompanhamento de acidentes. Esse
    controle será realizado por meio de planilha mensal e exposto para todos os
    funcionários como medida preventiva em busca do zero acidente. Sugerimos que
    os incidentes sejam controlados, analisados e investigados de forma criteriosa.`,
    avaliador: "SSMA",
    numero: "1.1.8",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo: "Controlar índices de acidentes.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 300,
  },
  {
    evidencia: "S",
    descricao: `Para demonstrar gestão Proativa de SSMA, tendo acompanhado e
    controlado os acidentes e incidentes e o histórico de multas da equipe, o
    Distribuidor deve realizar treinamentos com a equipe de vendas sobre os
    temas relacionados às causas de acidentes, multas e incidentes mais frequentes,
    como medida preventiva em busca do zero acidente. Os treinamentos devem ser
    registrados em atas com as assinaturas dos participantes.`,
    avaliador: "SSMA",
    numero: "1.1.9",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo:
      " São feitos treinamentos proativos sobre SSMA, envolvendo temas como reportes, multas, acidentes, práticas inseguras, direção defensiva.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deverá ter equipe treinada para combate a incêndio e
    primeiros socorros, e esse treinamento deverá ser realizado anualmente. Todo
    Distribuidor deverá verificar a existência de regulamentos municipais/estaduais
    tais como “Decreto Estadual do Corpo de Bombeiros” de acordo com a sua região.
    Estas leis/normas visam estabelecer as diretrizes para a correta formação de
    Brigada de Emergência. `,
    avaliador: "SSMA",
    numero: "1.1.10",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo:
      "Possuir pessoas treinadas e designadas a integrar a brigada de incêndio",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deverá realizar Curso de Direção Defensiva com todos os
    funcionários que possuem CNH. Esse curso deve ser pré-requisito no momento
    da contratação do novo funcionário e deverá ser realizado a cada 2 anos.
    O Distribuidor deverá realizar o treinamento de Direção Defensiva, que poderá
    ser online, para todos os seus recursos que trabalharão com frota, sejam eles em
    vendas ou logística. Raízen 
    `,
    avaliador: "SSMA",
    numero: "1.1.11",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo: "Todos os funcionários com CNH fizeram curso de direção defensiva.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `Todos os consultores de vendas do canal de B2B deverão utilizar
    Equipamentos de Proteção Individual (EPI) quando forem visitar áreas
    operacionais, além disso, devem mostrar aos clientes a importância da utilização
    de tais equipamentos. Também são de uso obrigatório Equipamentos de Proteção
    Individual por todos os funcionários dos armazéns dos distribuidores. Os
    equipamentos de segurança são:
    - Óculos de Proteção
    - Protetor Auricular
    - Capacete
    - Bota de segurança ou calçado com adequado para visitas em
    ambientes que apresentem riscos. 
    `,
    avaliador: "SSMA",
    numero: "1.1.12",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo:
      "Todos os funcionários do distribuidor utilizam Equipamentos de Proteção Individual (EPI) para realização de suas tarefas: Vendedores B2B em visitas às Fábricas de clientes e funcionários dos armazéns nos trabalhos diários.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `Possuir ponto focal de SSMA responsável por disseminar a cultura de
    SSMA na empresa, sendo responsável por consolidar todas as informações e
    controles contidos no Programa de Excelência. Esse ponto focal deve cascatear
    as ações de SSMA (Safety Walk, Dicas e Reportes, e demais ações de SSMA) para
    todo o time dos distribuidores. O Ponto Focal de SSMA precisa ser treinado pelo
    distribuidor no início das operações e quando este for alterado, garantindo a
    continuidade das operações sem comprometimento da segurança. `,
    avaliador: "SSMA",
    numero: "1.1.13",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo: "Possuir ponto focal de SSMA.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Todas as frotas (veículos leves ou pesados) que a equipe do distribuidor
    utilizar para a realização de tarefas da operação do distribuidor (vendas, logísticas
    ou administrativas), sendo próprias ou terceirizadas, devem possuir os itens
    básicos de segurança previstos por lei, incluindo air bag, freios ABS e barras de
    proteção lateral.`,
    avaliador: "SSMA",
    numero: "1.1.14",
    bucket: "SSMA",
    grupo: "Gestão de SSMA",
    titulo:
      "Todos os veículos frota devem possuir os ítens de segurança previstos por lei: Air Bag, ABS e Barras de Proteção Lateral.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `Os Analistas de Informação devem participar das teleconferências e
    treinamentos presenciais sempre que estes forem agendados. É de extrema
    importância que os AIs participem das teleconferências e dos treinamentos/
    workshops organizados pela área de IT da Raízen. Nesses encontros, são
    expostos os pontos importantes para a melhoria constante no processo de
    gerenciamento da informação, manutenção e ajustes nos processos atuais. `,
    avaliador: "TI",
    numero: "2.1.1",
    bucket: "Tecnologia da Informação",
    grupo: "Gestão da Informação",
    titulo:
      "Os Analistas de Informação devem participar das teleconferências e treinamentos presenciais sempre que estes forem agendados pela área de TI da Raízen.",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deve, sempre que acabar de fazer o fechamento do mês
    (considerando-se sempre o último dia útil), realizar o procedimento de
    fechamento de estoque no Sistema de Gestão Integrada. Esse procedimento
    deve ocorrer mensalmente e o fechamento do estoque deve ser comunicado ao
    time de TI da Raízen. 
    `,
    avaliador: "TI",
    numero: "2.1.2",
    bucket: "Tecnologia da Informação",
    grupo: "Gestão da Informação",
    titulo: "Realizar fechamento de estoque mensalmente no sistema",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `Ter as linhas do DRE padrão, módulo financeiro da TOTVS funcionando
    e enviar o DRE para o Consultor de Programa dos Distribuidores e Gerente
    Comercial da Raízen mensalmente ou sempre que solicitado. `,
    avaliador: "TI",
    numero: "2.1.3",
    bucket: "Tecnologia da Informação",
    grupo: "Gestão da Informação",
    titulo:
      "Ter as linhas do DRE padrão, Módulo financeiro da TOTVS funcionando e enviar para o consultor comercial Raízen.",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 300,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deve garantir que os dados de cadastro dos produtos Shell
    estejam de acordo com o padrão para que seja possível gerar informações
    confiáveis pelo BackOffice, tais como volume, família, descrição de produtos, etc.`,
    avaliador: "TI",
    numero: "2.1.4",
    bucket: "Tecnologia da Informação",
    grupo: "Gestão da Informação",
    titulo:
      "Dados de cadastro dos produtos Shell estão de acordo com o padrão como volume, família, descrição, entre outros.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deve garantir que os dados de segmento dos clientes
    estejam de acordo com a lista-padrão passada pela Raízen, para que as análises
    possam ser feitas com informações corretas, gerando maior veracidade a tais
    informações. 
    `,
    avaliador: "TI",
    numero: "2.1.5",
    bucket: "Tecnologia da Informação",
    grupo: "Gestão da Informação",
    titulo:
      "Dados de segmento dos clientes devem estar de acordo com a lista padrão passada pela Raízen.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Todos os clientes do canal B2C (Revenda e Varejo) possuem o potencial
    de volume registrado no sistema. A informação sobre o potencial de volume dos
    clientes da revenda é de vital importância para ações estratégicas, tais como a
    definição do modelo de atendimento dos clientes, frequência de visitas, clientes
    foco para ações de marketing e etc. Por esse motivo, os clientes B2C (ativos e
    inativos) devem possuir a informação do potencial de volume na sua base de
    dados. O potencial de volume é definido pelo total de lubrificantes de todas as
    marcas e especificações vendido por um ponto de venda em um período de 1
    (um) mês. Para a obtenção dessa informação, é recomendado ao consultor de
    vendas que faça uma análise do estoque, além de perguntar ao dono/gerente do
    PDV. O potencial também pode ser obtido perguntando-se ao responsável pelo
    PDV, quantas trocas de óleo são feitas por dia/semana/mês (no caso de Oficinas
    Mecânicas, Trocas de Óleo, Super Trocas de Óleo, Centros Automotivos). 
    `,
    avaliador: "TI",
    numero: "2.1.6",
    bucket: "Tecnologia da Informação",
    grupo: "Gestão da Informação",
    titulo:
      "Todos os clientes da Revenda possuem o potencial de volume registrado no sistema.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O saneamento periódico do cadastro de clientes, por meio do Renova
    Cadastro, possibilita a boa manutenção da base de clientes, ajustando seus dados
    e retirando eventuais “impurezas” que prejudiquem sua utilização e credibilidade.
    Deve ser feito pelo menos uma vez ao ano, entregue no prazo e atualizado no
    sistema, conforme padrão disponibilizado pela Raízen. Orienta-se que o
    distribuidor realize upload periódico das informações de nomenclatura e família
    de produtos, bem como de segmentos de atuação padrão Raízen. `,
    avaliador: "TI",
    numero: "2.1.7",
    bucket: "Tecnologia da Informação",
    grupo: "Gestão da Informação",
    titulo: 'Realizar anualmente o "Renova Cadastro"',
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `A equipe de vendas possui várias atribuições. Dessa forma, faz-se
    necessário lembrá-los sempre das ferramentas que possuem para alavancar as
    vendas e margens do Distribuidor, além de motivar sua equipe. A Campanha de
    Incentivo de Vendas é planejada para motivar as equipes de vendas dos
    Distribuidores. O prêmio é parte fundamental desta motivação, além de dar
    credibilidade à campanha. A premiação e o destaque das melhores performances
    são essenciais para mostrar às equipes que ganhar é possível, além de servir de
    exemplo a ser seguido. Para um acompanhamento efetivo de qualquer
    campanha, seguir os resultados é essencial para que uma estratégia adequada
    de atingimento dos alvos seja feita. A planilha gerencial diária é a ferramenta
    existente para tal feito. 
    `,
    avaliador: "Consultor Franquia",
    numero: "3.1.2",
    bucket: "Recursos Humanos",
    grupo: "Gestão de RH",
    titulo:
      "O Distribuidor comunica a sua equipe sobre a Campanha de Incentivo em reuniões e matinais; As conquistas da Campanha de Incentivo são celebradas com a equipe e o momento é registrado com entrega de Troféu/Medalhas e existe planilha especifica para acompanhamento diário dos resultados da Campanha de Incentivo",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `A equipe de vendas deve ser direcionada para o atingimento dos
    resultados por meio de uma política de remuneração clara e simples. O
    acompanhamento diário, pela equipe, dos resultados parciais da sua
    remuneração promove um maior engajamento ao atingimento das metas e maior
    transparência das regras e resultados obtidos, gerando maior motivação para
    conquistar todos os KPIs. 
    `,
    avaliador: "Consultor de PD",
    numero: "3.1.1",
    bucket: "Recursos Humanos",
    grupo: "Gestão de RH",
    titulo:
      "Adotar o sistema de remuneração variável por pontos (ferramenta ROTA) ou o sistema de remuneração sugerido pela Raízen caso o ROTA ainda não tenha sido implementado.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: ` A fim de integrar novos colaboradores às políticas, às práticas, ao
    mercado e ao negócio da empresa, é de fundamental importância a realização
    do treinamento de integração, que chamamos de onboarding. Para funcionários
    que mudaram de função também é fundamental o treinamento da nova função
    e a apresentação das novas rotinas e ferramentas. O objetivo é proporcionar ao
    novo colaborador um período de integração à empresa, facilitando a adaptação
    aos processos, aos princípios e valores, cultura, além de fornecer informações
    necessárias ao bom desempenho da nova função. Nos casos de mudança de
    função (reestruturações, promoções) o objetivo é fornecer as informações
    necessárias para iniciar as atividades no novo cargo. Com um programa de
    onboarding bem construído, o tempo de adaptação ao cargo e à rotina de
    trabalho diminui e isto impactará em uma resposta mais rápida da performance,
    pois este novo colaborador inicia a função já conhecendo as metodologias e
    rotinas exigidas. `,
    avaliador: "Trade Mkt",
    numero: "3.1.3",
    bucket: "Recursos Humanos",
    grupo: "Gestão de RH",
    titulo:
      "Distribuidor deve realizar o onboarding para novos colaboradores e para funcionários que mudaram de função antes de iniciarem suas atividades visando ter um time capacitado e com clareza do negócio.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `Mostrar o reporte da última avaliação do GQPD, com o resultado
    percentual atingido pelo distribuidor. `,
    avaliador: "Qualidade",
    numero: "4.1.1",
    bucket: "Troca Certa",
    grupo: "Gestão de Qualidade do Troca Certa",
    titulo:
      "Mostrar o reporte da última avaliação do GQPD, com o resultado percentual atingido pelo distribuidor. A pontuação do ítem será de acordo com o atingimento percentual do distribuidor conforme abaixo:",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 50,
  },
  {
    evidencia: "S",
    descricao: `Apresentar no campo, in loco, as ações concluídas, fruto de ações
    corretivas da última inspeção do GQPD. `,
    avaliador: "Qualidade",
    numero: "4.1.2",
    bucket: "Troca Certa",
    grupo: "Gestão de Qualidade do Troca Certa",
    titulo:
      "Apresentar no campo - in loco - as ações concluidas, fruto de ações corretivas da última inspeção do GQPD.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 50,
  },
  {
    evidencia: "S",
    descricao: `Cascatear resultado da avaliação do GQPD para todo o time envolvido na
    operação Troca Certa por meio de reunião com ata de presença. `,
    avaliador: "Qualidade",
    numero: "4.1.3",
    bucket: "Troca Certa",
    grupo: "Gestão de Qualidade do Troca Certa",
    titulo:
      "Cascatear resultado da avaliação do GQPD para todo o time envolvido na operação Troca Certa",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 50,
  },
  {
    evidencia: "S",
    descricao: `O coordenador do Troca Certa deve ter um material-padrão de
    treinamento para os consultores de vendas. Esse material deve conter as
    apresentações de DPQA, SSMA, Marketing (quais são os materiais e como utilizálos), Pricing, tópicos básicos de venda a granel e, principalmente, o link com as
    plataformas motivacionais.`,
    avaliador: "Qualidade",
    numero: "4.2.1",
    bucket: "Troca Certa",
    grupo: "Gestão de Pessoas do Troca Certa",
    titulo:
      "Coordenador de Troca Certa garante treinamento dos vendedores abordando os temas: Vendas, Marketing, DPQA e HSSE ",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 50,
  },
  {
    evidencia: "S",
    descricao: `Plano de Atendimento a Emergência (PAE). O Distribuidor deve ter
    o PAE atualizado. Esse documento deve seguir a Norma NBR 14064
    (atendimento a emergência no transporte terrestre de produtos perigosos).
    Nesse documento, além de todas as diretrizes de emergência, deve também
    constar que todo tipo de incidente deve ser reportado à Raízen dos principais
    pontos focais como Consultor Comercial, Gerente Comercial e Consultor de SSMA. 
    `,
    avaliador: "SSMA",
    numero: "4.3.1",
    bucket: "Troca Certa",
    grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    titulo:
      "Distribuidor deve possuir o Plano de Atendimento a Emergência atualizado",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deve manter kits de emergência na área de
    tanques. Pequenos vazamentos de produto podem ocorrer na área de tanques,
    bombas e mangueiras. Um kit de emergência deverá estar disponível na área de
    trabalho a fim de garantir uma rápida limpeza. Os EPIs básicos sempre devem
    ser usados, como sapatos, óculos de proteção e luvas. 
     `,
    avaliador: "SSMA",
    numero: "4.3.2",
    bucket: "Troca Certa",
    grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    titulo:
      "Distribuidor deve possuir KITs de emergência nas áreas dos tanques",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deve manter kits de emergência nos caminhões.
    Vazamentos de produto podem ocorrer durante o transporte - e nas operações
    de descarregamento nos clientes. Um kit de emergência deverá estar disponível
    no caminhão. A área contaminada deverá ser isolada por fita e cones até que
    seja liberada por uma empresa especializada ou por autoridades competentes. `,
    avaliador: "SSMA",
    numero: "4.3.3",
    bucket: "Troca Certa",
    grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    titulo: "Distribuidor deve possuir KITs de emergência nos caminhões",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Contrato com empresa especializada em emergência ambiental O
    Distribuidor é responsável por contratar uma empresa especializada em
    emergências para as atividades de distribuição de lubrificantes. Essa empresa
    deve atender prontamente à solicitação de atendimento à emergência,
    especialmente em caso de derrame de produtos na área de tanques do
    Distribuidor, em vias públicas e nos pontos de vendas. A coordenação da
    emergência e todo o contato com as autoridades locais e órgãos ambientais ficam
    sob a responsabilidade da empresa contratada. 
    `,
    avaliador: "SSMA",
    numero: "4.3.4",
    bucket: "Troca Certa",
    grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    titulo:
      "Distribuidor deve possuir contrato com empresa especializada em Emergência Ambiental",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Seguro Ambiental Deverá ser contratado um Seguro Ambiental que cubra
    as atividades de distribuição de lubrificantes desde a área operacional do
    Distribuidor, todo o transporte, até o ponto de vendas. Por recomendação das
    empresas especializadas em transporte em emergências, o valor mínimo de
    cobertura deverá ser de R$ 500.000,00. Esse valor deve ser negociado com as
    seguradoras especializadas nesse tipo de produto. 
    `,
    avaliador: "SSMA",
    numero: "4.3.5",
    bucket: "Troca Certa",
    grupo: "Gestão de SSMA Troca Certa (Tanques Estacionários e IBCs)",
    titulo: "Distribuidor deve possuir Seguro Ambiental",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Qualidade",
    numero: "4.4.1",
    bucket: "Troca Certa",
    grupo: "Gestão de Vendas Troca Certa",
    titulo:
      "Possuir um Coordenador dedicado ao gerenciamento de todo o processo do Troca Certa",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Qualidade",
    numero: "4.4.2",
    bucket: "Troca Certa",
    grupo: "Gestão de Vendas Troca Certa",
    titulo:
      "Possuir um ponto focal, Supervisor ou Gerente, atuando na liderança comercial do Troca Certa.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Qualidade",
    numero: "4.4.3",
    bucket: "Troca Certa",
    grupo: "Gestão de Vendas Troca Certa",
    titulo:
      "Distribuidor ter critérios claros como pré requisitos mínimos para instalação de tanques em clientes. ",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Qualidade",
    numero: "4.4.4",
    bucket: "Troca Certa",
    grupo: "Gestão de Vendas Troca Certa",
    titulo:
      "Coordenador Troca Certa deve possuir uma Planilha de Controle de ativos ",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Qualidade",
    numero: "4.4.5",
    bucket: "Troca Certa",
    grupo: "Gestão de Vendas Troca Certa",
    titulo:
      "O Supervisor, ou Gerente, ponto focal deve utilizar-se dos controles gerados pelo Coordenador de Granel para garantir que o tema Troca Certa esteja presentes nas reuniões matinais e mensais.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Qualidade",
    numero: "4.4.6",
    bucket: "Troca Certa",
    grupo: "Gestão de Vendas Troca Certa",
    titulo:
      "O Coordenador de Troca Certa deve garantir o atendimento em sua totalidade dos ítens de qualidade do manual do GQPD pertinentes a armazenagem de lubrificantes a granel.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `O Coordenador de Marketing tem grande responsabilidade dentro do
    distribuidor, auxiliando em todos os processos referentes a Marketing, além de
    garantir a implementação de todas as iniciativas e diminuir o espaço entre o time
    Raízen e o distribuidor. Com ele, ficará mais fácil para o distribuidor entender em
    que e quanto deve investir em seu mercado, além de acompanhar de forma muito
    mais ágil os resultados dessas iniciativas locais.`,
    avaliador: "Consultor de PD",
    numero: "5.1.1",
    bucket: "Estrutura de Vendas",
    grupo: "Estrutura de Suporte à Vendas",
    titulo:
      "Possuir Coordenador de marketing dedicado para implementar as promoções e campanhas, garantir alinhamento na aplicação de merchandising, monitoramento de KPI's e reportes de informações e garantir desenvolvimento do CVP do distribuidor.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Possuir Supervisor de consultores de vendas Internos e/ou Supervisor de
    Assistentes de Vendas. Em ambos os casos, o Supervisor tem papel fundamental
    na orientação de sua equipe, acompanhando os trabalhos de seu time a fim de
    desenvolver esses recursos e garantir que os processos e rotinas pertinentes a
    tais estruturas sejam cumpridos. Fica definido que, para equipes com até 4
    recursos (podendo ser consultores de vendas Internos e/ou Assistentes de
    Vendas), não será obrigatório ao Distribuidor ter um supervisor, mas um líder de
    equipe, que deverá executar suas tarefas como os demais e, além disso,
    direcionar e orientar o time. 
    `,
    avaliador: "Consultor de PD",
    numero: "5.1.2",
    bucket: "Estrutura de Vendas",
    grupo: "Estrutura de Suporte à Vendas",
    titulo: "Possuir supervisor de vendedores internos.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Possuir estrutura de suporte de vendas interna e/ou vendas internas
    conforme abaixo:
    Possuir Assistente de Vendas Internas que façam o atendimento espelhado
    de consultores de vendas es externos, realizando ligações ativas e receptivas.
    Fica definido que cada atendente interno poderá realizar o espelho de até 4
    consultores de vendas externos.
    As ligações receptivas visam apoiar o time de vendas externo em relação a
    solicitações que seus clientes possam fazer referentes a status de pedidos,
    colocação de pedidos, informações sobre produtos, enfim suportando e
    complementando o atendimento do time de vendas externo.
    As ligações ativas devem seguir o roteiro das ligações e tem como propósito
    reforçar o atendimento presencial. As atividades aqui incluem ligações ativas de
    vendas, Pesquisa de Satisfação de Clientes, Ações em carteira específica de
    clientes Churn, por exemplo. 
    `,
    avaliador: "Consultor de PD",
    numero: "5.1.3",
    bucket: "Estrutura de Vendas",
    grupo: "Estrutura de Suporte à Vendas",
    titulo:
      "Possuir estrutura de atendimento interno com recursos para suporte ao time de vendas externo.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Para que o Distribuidor possa ter melhor desempenho e cobrir seu
    mercado de atuação de forma mais efetiva, com foco nas ações de vendas,
    garantindo uma maior pulverização para que o mercado seja atendido
    adequadamente com a oferta de produtos, é fundamental que cada consultor de
    vendas atenda a uma área com potencial de 150 m³.
    Essas áreas devem ser definidas pelos supervisores e gerentes, sendo
    responsabilidade do supervisor garantir que essa cobertura equilibrada seja
    mantida. Esse potencial é definido de acordo com as informações do DENATRAN
    a partir da frota de cada município. A partir dessas informações, a equipe de
    Inteligência de Mercado da Raízen realiza um estudo de potencial de vendas
    estratificado por cidades, distribuidores e linhas de produtos. A quantidade de
    recursos necessária para a cobertura da área do distribuidor deve ser a soma dos
    recursos de varejo e revenda. 
    `,
    avaliador: "Consultor de PD",
    numero: "5.1.4",
    bucket: "Estrutura de Vendas",
    grupo: "Estrutura de Suporte à Vendas",
    titulo: "Possuir um vendedor a cada 150.000 l de potencial de mercado ",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 500,
  },
  {
    evidencia: "S",
    descricao: `Para que o Supervisor possa dar o foco devido no desenvolvimento de
    seus consultores de vendas, acompanhar seu desempenho, ajudá-los a identificar
    oportunidades e dar suporte comercial, é fundamental que possua um número
    máximo de 8 (oito) consultores de vendas sob sua subordinação `,
    avaliador: "Consultor de PD",
    numero: "5.1.5",
    bucket: "Estrutura de Vendas",
    grupo: "Estrutura de Suporte à Vendas",
    titulo:
      "Possuir um supervisor de vendas para, no máximo, cada 8 vendedores",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `O distribuidor deve possuir um STD (Suporte Técnico do
      Distribuidor).
      `,
    avaliador: "Consultor de PD",
    numero: "5.1.6",
    bucket: "Estrutura de Vendas",
    grupo: "Estrutura de Suporte à Vendas",
    titulo: "Possuir STD (Suporte Técnico do Distribuidor)",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `O distribuidor deve possuir um gerente de Vendas ou, caso o distribuidor
    não possua um gerente de vendas (acordado em BP), outro recurso do
    distribuidor poderá executar o direcionamento do time de Supervisores, desde
    que cumpra com todas as exigências desse Título de “Gerente de Vendas”. Esta
    condição deverá estar refletida no Business Plan do distribuidor. `,
    avaliador: "Consultor de PD",
    numero: "5.1.7",
    bucket: "Estrutura de Vendas",
    grupo: "Estrutura de Suporte à Vendas",
    titulo:
      "Possuir gerente de vendas ou recurso dedicado para direcionar o time de supervisores de forma estruturada caso não possua gerente de vendas. (situação deverá ser prevista em Business Plan)",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deve definir um Monitor Geral do Programa de
    Excelência Raízen Lubrificantes e Apoiadores para cada Título a fim de
    envolver toda a estrutura na busca pela Excelência. Estes serão responsáveis por
    multiplicar os conceitos do Programa no Distribuidor. Para tanto, deverão
    conhecer os Títulos, Quesitos e Conceitos do PEX para que sua credibilidade entre
    a equipe não seja prejudicada. O Monitor e Apoiadores serão responsáveis pela
    execução das reuniões de PEX, a elaboração, acompanhamento e exposição do
    Plano de Ação do PEX, bem como das pré-avaliações periódicas do Programa. 
    `,
    avaliador: "Consultor de PD",
    numero: "6.1.1",
    bucket: "Sistema de Gestão",
    grupo: "Projeto Monitor",
    titulo: "Foram definidos monitor e apoiadores do PEX no Distribuidor",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: ` Verificar se foi definido calendário anual de reuniões do Programa de
    Excelência Raízen Lubrificantes, se está exposto em quadro mural, se todos
    estão cientes, se as reuniões estão sendo realizadas e se seu propósito de
    monitorar as ações do PEX está sendo atingido. 
    `,
    avaliador: "Consultor de PD",
    numero: "6.1.2",
    bucket: "Sistema de Gestão",
    grupo: "Projeto Monitor",
    titulo:
      "Existe um calendário de reuniões e todos participantes estão cientes",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `Verificar se o Distribuidor, com o Consultor, realizou reunião para
    definição do Plano de Negócio (Business Plan), definindo os alvos, as ações e consolidando-os na planilha. Verificar também se o plano foi cascateado para
    toda a equipe de vendas até a primeira quinzena do mês de Janeiro. `,
    avaliador: "Consultor Franquia",
    numero: "6.2.1",
    bucket: "Sistema de Gestão",
    grupo: "Plano de Negócios",
    titulo:
      "Os objetivos para o ano foram definidos e documentados dentro do prazo estabelecido",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Para a entrega do Plano de Negócios e atingimento de alvos, o
    distribuidor, junto com seu consultor, deverá definir uma série de ações que
    deverão suportar esse crescimento.`,
    avaliador: "Consultor Franquia",
    numero: "6.2.2",
    bucket: "Sistema de Gestão",
    grupo: "Plano de Negócios",
    titulo:
      "As ações de suporte para entrega do plano foram definidas conforme seus segmentos",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Para que as ações sejam suficientes para suportar a entrega do Plano, o
    distribuidor precisa acompanhá-las e finalizá-las dentro dos prazos definidos. O
    cumprimento dessas ações deverá ser acompanhado mensalmente nas reuniões
    de revisão do Plano de Negócios. 
    `,
    avaliador: "Consultor Franquia",
    numero: "6.2.3",
    bucket: "Sistema de Gestão",
    grupo: "Plano de Negócios",
    titulo:
      "O distribuidor está cumprindo com as ações acordadas nos prazos acordados",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: ` O Distribuidor deve construir e acompanhar sua projeção financeira com
    base no Relatório Demonstrativo de Resultado (DRE) para entender a
    necessidade real de investimento em caixa e limite de crédito, além de poder
    trabalhar para reduzir custos e aumentar competitividade. `,
    avaliador: "Consultor Franquia",
    numero: "6.2.4",
    bucket: "Sistema de Gestão",
    grupo: "Plano de Negócios",
    titulo: "Foi construída Projeção Financeira com base no DRE",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Plano de Negócios deve ser apresentado e aprovado pelo Gerente
    Comercial Raízen. `,
    avaliador: "Consultor Franquia",
    numero: "6.2.5",
    bucket: "Sistema de Gestão",
    grupo: "Plano de Negócios",
    titulo:
      "O Business Plan do distribuidor foi aprovado pelo gerente comercial de vendas da Raízen.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deve fazer uma reunião mensal com o consultor da Raízen
    para apresentar as evoluções dos resultados em relação ao Plano de Negócios,
    bem como apresentar ações já concluídas, seus resultados e ações que ainda
    deverão ser trabalhadas. 
    `,
    avaliador: "Vendas",
    numero: "6.2.6",
    bucket: "Sistema de Gestão",
    grupo: "Plano de Negócios",
    titulo: "Foi realizada Revisão Mensal do BP",
    periodo: "Trimestral",
    criterio: "Variável (90 pontos por revisão limitado a uma por mês)",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deve ter assinado todos os documentos e
    contratos/aditamentos que fazem parte da relação Raízen e Distribuidor, não
    havendo nenhuma pendência em relação a esses documentos. `,
    avaliador: "Consultor Franquia",
    numero: "6.2.7",
    bucket: "Sistema de Gestão",
    grupo: "Plano de Negócios",
    titulo:
      "Todos os documentos e contratos/aditamentos entre a Raízen e o Distribuidor foram assinados",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `A venda de produtos agregados é importante, pois ajuda a diluir os custos
    do distribuidor, além de contribuir para uma oferta integrada de produtos para o
    cliente. Caso o distribuidor não tenha equipe exclusiva para comercialização dos
    produtos, a participação destes se comparada ao total de vendas do distribuidor,
    não deve ultrapassar 30%, para poder garantir o foco da equipe de vendas e do
    distribuidor em lubrificantes. 
    `,
    avaliador: "Consultor de PD",
    numero: "6.3.1",
    bucket: "Sistema de Gestão",
    grupo: "Gestão de Agregados",
    titulo:
      "A participação nas vendas totais de produtos agregados pela equipe de vendedores de lubrificantes é:",
    periodo: "Anual",
    criterio:
      "Variável \r\nInferior a 15% = Pontuação Máxima\r\nEntre 15,1% e 30% = Metade\r\nAcima de 30% = Zero ",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Caso o distribuidor atinja um faturamento superior ao limite de 30%,
    pode ser contratada uma equipe exclusiva para trabalhar com a linha de produtos
    agregados, garantido assim a manutenção do foco de atuação dos consultores
    de vendas em lubrificantes e podendo extrair o máximo dos itens agregados. 
    `,
    avaliador: "Consultor de PD",
    numero: "6.3.2",
    bucket: "Sistema de Gestão",
    grupo: "Gestão de Agregados",
    titulo:
      "Caso o faturamento ultrapasse a participação máxima de 30%, o distribuidor deverá possuir equipe dedicada para trabalhar com produtos agregados. Se o percentual for menor que 30% o  o ponto não será avaliado (N/A).",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `Não comparecimento do distribuidor ou transportadora contratada pelo
    distribuidor para carregamento de pedido agendado na Planta da Raízen
    Lubrificantes ou em qualquer base logística operada pela Raízen Lubrificantes. 
    `,
    avaliador: "Logistica",
    numero: "6.4.1",
    bucket: "Sistema de Gestão",
    grupo: "Gestão de Armazéns e Logística",
    titulo:
      "Comparecimento de todos os caminhões para carregamento de pedido agendado",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Prazo médio para agendamento e carregamento do pedido pelo
    distribuidor após liberação para carregamento na Planta da Raízen Lubrificantes
    ou em qualquer base logística operada pela Raízen Lubrificantes.`,
    avaliador: "Logistica",
    numero: "6.4.2",
    bucket: "Sistema de Gestão",
    grupo: "Gestão de Armazéns e Logística",
    titulo:
      "Linearidade de Agendamentos/Carregamentos - O distribuidor atingiu a linearidade de Agendamentos e Carregamentos conforme acordado com a Shell conforme abaixo:",
    periodo: "Trimestral",
    criterio:
      "Variável \r\nTotal de semanas completas (12) entre 20 a 30% = 150\r\nTotal de semanas completas (6) entre 20 a 30% = 75\r\nAbaixo de semanas entre a 20 a 30% = 0",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `O distribuidor deve possuir todas as autorizações e documentos legais
    necessários para a operação nos Armazéns, tais como Contrato Social e última
    alteração, CNPJ / CNAE, IPTU, Licença de Operação - órgão Ambiental, Alvará de
    Operações / Funcionamento, Livro de Inspeção do Trabalho, Livro de
    arrecadação do ICMS, Licença do Corpo de Bombeiros, Projeto C. Bombeiros do
    Armazém, PCMSO, PPRA, CAGED, CADRI - destino de resíduos, Registro /
    Gerador resíduos e TCFA. 
    `,
    avaliador: "SSMA",
    numero: "6.4.3",
    bucket: "Sistema de Gestão",
    grupo: "Gestão de Armazéns e Logística",
    titulo:
      "O distribuidor deve possuir todos as autorizações e documentos legais necessários para a operação nos Armazéns",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 500,
  },
  {
    evidencia: "S",
    descricao: `O distribuidor deverá aplicar o checklist de avaliação da transportadora
    em periodicidade definida e acordada pela Raízen e distribuidor. Esse checklist
    contém os processos, rotinas, equipamentos e tudo o que for necessário para
    que a transportadora atue com segurança e possa efetuar serviços de transporte
    para o Distribuidor. `,
    avaliador: "SSMA",
    numero: "6.4.4",
    bucket: "Sistema de Gestão",
    grupo: "Gestão de Armazéns e Logística",
    titulo: "Distribuidor deve realizar checklist na transportadora",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 500,
  },
  {
    evidencia: "S",
    descricao: `Um dos pontos críticos da operação de lubrificantes é a segurança
    de todos e do meio ambiente. Para isso é necessário que os caminhões
    utilizados pelos distribuidores para a retirada dos produtos na fábrica e/ ou nos
    armazéns da Raízen estejam sempre em dia com todos os itens de segurança e
    que todas as rotinas e processos de segurança sejam cumpridos, incluindo-se aí
    a verificação da documentação dos veículos e realização do teste de bafômetro
    pelos condutores. 
    `,
    avaliador: "Logistica",
    numero: "6.4.5",
    bucket: "Sistema de Gestão",
    grupo: "Gestão de Armazéns e Logística",
    titulo:
      "Em relação a quantidade de caminhões agendados para a retirada de produtos nas plantas e armazéns da Shell o distribuidor teve:",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "SSMA",
    numero: "6.4.6",
    bucket: "Sistema de Gestão",
    grupo: "Gestão de Armazéns e Logística",
    titulo: "Comunicar todo e qualquer acidente/incidente para CAE ",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `O distribuidor atingiu a linearidade de colocação de pedidos acordada
    com a Raízen. 
    `,
    avaliador: "Logistica",
    numero: "6.4.7",
    bucket: "Sistema de Gestão",
    grupo: "Gestão de Armazéns e Logística",
    titulo:
      "Linearidade de Pedidos - O distribuidor atingiu a linearidade de pedidos conforme acordado com a Raízen conforme abaixo:",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `Para medir a satisfação dos clientes, está disponível no sistema e com os
    resultados apresentados na PIV, a Pesquisa de Satisfação de Clientes. A pesquisa
    foi elaborada pela Raízen e comtempla os elementos críticos da proposta de valor
    Shell. Ela mede 5 elementos chave para gerar satisfação dos clientes:
    1-Satisfação geral com o Distribuidor;
    2- Satisfação geral com o atendimento do Distribuidor;
    3- Satisfação geral com a entrega/coleta do distribuidor
    4- Satisfação geral com as promoções e incentivos do distribuidor
    5- Satisfação geral com suporte técnico `,
    avaliador: "Consultor de PD",
    numero: "6.5.1",
    bucket: "Sistema de Gestão",
    grupo:
      "Gestão de Satisfação de Clientes (Customer Satisfaction Index) e OTIF (On Time In Full)",
    titulo:
      "Existe procedimento rotineiro para pesquisar a satisfação dos clientes (ativo). Para os distribuidores com acesso ao Portal Integrado de Vendas considerar a pesquisa disponível nessa ferramenta",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `O Distribuidor deve atingir um nível de serviço para atendimento aos
    clientes Raízen no nível que temos acordado em offerbook. Ou seja, 93% das
    ordens devem ser atendidas dentro do prazo acordado e quantidade solicitada e
    a medição é feita por grupo de clientes. 
    `,
    avaliador: "Consultor de PD",
    numero: "6.5.2",
    bucket: "Sistema de Gestão",
    grupo:
      "Gestão de Satisfação de Clientes (Customer Satisfaction Index) e OTIF (On Time In Full)",
    titulo: "Atingir nível dfe OTIF de 93% das ordens dos clientes Raízen",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Realizar semanalmente e de forma estruturada reunião com os
    Supervisores, discutindo iniciativas e ações dos respectivos canais de cada
    Supervisor.`,
    avaliador: "Consultor Franquia",
    numero: "7.1.1",
    bucket: "Metodologia de Vendas",
    grupo: "Gerente de Vendas",
    titulo:
      "Realizar reunião com os supervisores semanalmente e de forma estruturada discutindo iniciativas e ações dos respectivos canais de cada supervisor",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Para que tenhamos uma boa execução de mercado e uma percepção de
    prestação de serviço por parte do cliente, o consultor de vendas deve executar
    os procedimentos recomendados conforme o Drill. O consultor de vendas só
    realizará os procedimentos se tiver consciência de seus benefícios e as razões
    pelas quais deve adotar cada um dos passos. O Gerente deve saber explicar aos
    Supervisores cada um dos passos e as razões pelas quais cada um deles deve
    ser adotado para que, então, os supervisores tenham efetivamente um papel
    ativo nessa metodologia junto aos consultores de vendas. Além de saber explicar
    os 7Vs e seus benefícios, o gerente deve também conhecer e explicar os
    conceitos da Visão do Consumidor. O Gerente de vendas também deve conhecer
    todos os conceitos do canal varejo, tais como oferta integrada, trocador,
    galonagem do posto, ratio, compra zero, despejo e calculadora de valor.`,
    avaliador: "Consultor Franquia",
    numero: "7.1.2",
    bucket: "Metodologia de Vendas",
    grupo: "Gerente de Vendas",
    titulo:
      'Saber explicar aos subordinados os 7V\'s e a razão de cada um dos passos e Conhecer e transmitir os conceitos de Execução do PDV na "Visão do Consumidor" ',
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Em linha com os Pilares estratégicos do negócio, o Gerente deve conhecer
    profundamente as plataformas motivacionais da Raízen Lubrificantes, sabendo
    explicar os benefícios de cada um dos seus pilares, com o intuito de poder treinar
    e reciclar o conhecimento do time de vendas. Além disso, ele deve conhecer as
    metas dessas plataformas do seu time e os resultados atingidos em relação ao
    número de clientes engajados, clientes ativos e volume de produtos Premium. `,
    avaliador: "Consultor Franquia",
    numero: "7.1.3",
    bucket: "Metodologia de Vendas",
    grupo: "Gerente de Vendas",
    titulo:
      "Gerente deve saber explicar de forma precisa e segura todas as plataformas e ações do Varejo.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O gerente deverá conhecer, aplicar e gerenciar os indicadores do DSPANCOP (Força do Tubo, Alvo Incremental, Tempo de Ciclo, Taxa de Sucesso).
    Atentar-se aos BIG 5 e TOP 5. Orientar a equipe nas grandes negociações,
    participando quando necessário e dando apoio ao desenvolvimento da equipe. `,
    avaliador: "Consultor Franquia",
    numero: "7.1.4",
    bucket: "Metodologia de Vendas",
    grupo: "Gerente de Vendas",
    titulo:
      "Conhecer, aplicar e gerenciar os indicadores do SPANCOP (Força do Tubo, Alvo incremental, Tempo de Ciclo, Taxa de Sucesso). Atentar-se aos BIG 5 e TOP 5. Orientar a equipe nas grandes negociações, participando quando necessário e dando apoio ao desenvolvimento da equipe.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Gerente deve possuir um plano de ação claro para os 10 maiores
    clientes em CHURN do canal Revenda. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.1.5",
    bucket: "Metodologia de Vendas",
    grupo: "Gerente de Vendas",
    titulo:
      "Ter um plano de ação definido para recuperação dos 10 maiores clientes CHURN da gerência do B2C",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Gerente de Vendas realiza processo de coaching com os supervisores
    relativo à parte qualitativa do trabalho desses com seus consultores de vendas.
    Assim como o supervisor deve desenvolver as habilidades de seus consultores de
    vendas, o gerente deve também dar coaching aos seus supervisores (coaching
    do coaching).`,
    avaliador: "Consultor Franquia",
    numero: "7.1.6",
    bucket: "Metodologia de Vendas",
    grupo: "Gerente de Vendas",
    titulo:
      " Gerente de vendas realiza processo de coaching junto aos supervisores",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Supervisor do Canal B2C deve conhecer os alvos de clientes efetivos e
    o resultado obtido até o momento da avaliação em relação aos clientes efetivos
    do canal B2C. O Supervisor deve demostrar que possui controle absoluto sobre
    essa informação, apresentando planilhas e acompanhamentos que demonstrem
    os resultados obtidos em relação aos alvos do ano até a data da avaliação. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.2.1",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo: "Conhecer seus alvos de clientes efetivos e o resultado obtido.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Supervisor do Canal B2C deve conhecer os alvos de Volume Total e o
    resultado obtido até o momento da avaliação em relação ao volume total do canal
    B2C. O Supervisor deve demostrar que possui controle absoluto sobre essa
    informação, apresentando planilhas e acompanhamentos que demonstrem os
    resultados obtidos em relação aos alvos do ano até a data da avaliação. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.2.2",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo: "Conhecer seus alvos de volume total e resultado obtido.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Supervisor do Canal B2C deve conhecer os alvos de Volume Premium
    e o resultado obtido até o momento da avaliação em relação ao volume Premium
    do canal B2C. O Supervisor deve demostrar que possui controle absoluto sobre
    essa informação, apresentando planilhas e acompanhamentos que demonstrem
    os resultados obtidos em relação aos alvos do ano até a data da avaliação. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.2.3",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo: "Conhecer seus alvos de volume Premium e resultado obtido.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `As principais plataformas de relacionamento motivacionais da Raízen para
    se diferenciar da concorrência. O supervisor deve conhecer todos os pilares
    dessas plataformas, acompanhando a performance de sua equipe de vendas
    através do acompanhamento da performance dos clientes. Deve mostrar que
    possui os resultados do mês anterior, o alvo do mês corrente e o plano de ação
    SMART para evolução do KPI. Este plano de ação deve incluir todos os KPi’s
    medidos além de a quebra por consultor de vendas.`,
    avaliador: "Consultor Franquia",
    numero: "7.2.4",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      " Conhece as plataformas e ações motivacionais e explicar seus pilares e benefícios de cada um deles.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Supervisor deverá conhecer e acompanhar a performance dos 10
    maiores clientes B2C em relação a produtos premium. `,
    avaliador: "Consultor Franquia",
    numero: "7.2.5",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      "Conhecer os 10 maiores clientes de Volume Premium por canal (Revenda e Varejo).",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Para que tenhamos uma boa execução de mercado e uma percepção de
    venda de valor por parte do cliente, o consultor de vendas deve executar os
    procedimentos recomendados conforme o Drill. O consultor de vendas só
    realizará os procedimentos se tiver consciência de seus benefícios e as razões
    pelas quais deve adotar cada um dos passos. O Supervisor deve saber explicar
    cada uma das etapas e as razões pelas quais cada um deles deve ser adotado.`,
    avaliador: "Consultor Franquia",
    numero: "7.2.6",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      "Saber explicar aos subordinados os 7V's e a razão de cada um dos passos.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O consumidor final é a razão principal de todo nosso trabalho. Para tanto,
    devemos realizar uma execução nos Pontos de Vendas a fim de impulsionar o
    consumo dos nossos produtos. O Supervisor, a fim de orientar seus consultores
    de vendas, deve entender e saber transmitir os conceitos de execução em
    Merchandising, Exposição e Precificação, demonstrando a importância de cada
    aspecto da execução para nosso desempenho no mercado. `,
    avaliador: "Consultor Franquia",
    numero: "7.2.7",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      'Conhecer e transmitir os conceitos de Execução do PDV na "Visão do Consumidor".',
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Por meio do acompanhamento do consultor de vendas em rota e
    realização do Coaching, o Supervisor identifica os pontos a desenvolver de cada
    integrante da sua equipe. Com o auxílio da Matriz de Desempenho em Rota, ele
    consegue visualizar a consolidação de todas as rotas Coaching, bem como os
    pontos a desenvolver da equipe como um todo. Identificados esses pontos, o
    Supervisor deve ministrar treinamento dos 7Vs em PDV Simulado com o objetivo
    de desenvolver os pontos em que a equipe apresenta maior dificuldade,
    apresentando melhores práticas e dividindo experiências entre a equipe, focando
    sempre no benefício da diferenciação proporcionada pelos passos de vendas.`,
    avaliador: "Consultor Franquia",
    numero: "7.2.8",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo: "Treinar a equipe conforme Matriz de Desempenho em Rota.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Supervisor deve conhecer os índices de Cobertura, Efetividade das
    Visitas, Penetração e Cumprimento do Plano de Visitas de sua equipe. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.2.9",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      "Conhecer os índices de Efetividade, Penetração e Cumprimento do Plano de Visitas e Efetividade de sua equipe.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Supervisor deve possuir um plano de ação claro para os 10 maiores
    clientes em CHURN da sua supervisão.`,
    avaliador: "Consultor Franquia",
    numero: "7.2.10",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      "Ter um plano de ação definido para recuperação dos 10 maiores clientes CHURN da sua supervisão.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `No segmento do Varejo, além da redução de espaço para os nossos
    produtos na prateleira, o Despejo impacta diretamente no nosso potencial de
    vendas ao abrir espaço para produtos concorrentes. Visando o aumento de
    vendas e rentabilidade, é fundamental o combate contínuo e estratégico ao
    Despejo. Dessa forma, espera-se que o Supervisor tenha pleno conhecimento
    dos 10 maiores postos da sua base que sofram com Despejo, tendo plano de
    ação pronto para enfrentar essa realidade. O Plano de Ação deve conter as
    iniciativas corretivas, os produtos envolvidos e os prazos de implementação, além
    de indicar o suporte necessário como por exemplo, time da Raízen (Consultor
    Comercial, Gerente Comercial, Gerentes de Território, Gerentes Regionais...) 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.2.11",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      "Conhecer os 10 maiores clientes de despejo (no segmento Varejo) e ter plano de ação definido para reversão e contenção desse despejo",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: ` Supervisor envia mensalmente reporte e relatório com os resultados e
    principais ações realizadas no mês e ações planejadas para os próximos meses
    para o time Raízen a fim de gerar visibilidade do trabalho que está sendo
    executado por ele e por seu time, mantendo sempre uma comunicação proativa
    com os principais stakeholders da Raízen.`,
    avaliador: "Consultor Franquia",
    numero: "7.2.12",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      "Supervisor envia mensalmente reporte e relatório  (overview) com os resultados e principais ações realizadas no mês e ações planejadas para os próximos meses para time Raízen.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Conhecer e explicar os conceitos da calculadora de valor e saber utilizar`,
    avaliador: "Consultor Franquia",
    numero: "7.2.13",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      "Conhecer e explicar os conceitos da calculadora de valor e saber utilizar. ",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: ` Supervisor deve possuir total controle sobre a informação de Postos
    com Compra Zero e ações necessárias para reverter essas situações. `,
    avaliador: "Consultor Franquia",
    numero: "7.2.14",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2C (Revenda+Varejo)",
    titulo:
      "Supervisor deve possuir total controle sobre a informação de Postos com compra Zero e ações necessárias para reverter estas situações.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Consultor de Vendas do Canal B2C deve conhecer os alvos de clientes
    efetivos e o resultado obtido até o momento da avaliação em relação aos clientes
    efetivos do canal B2C. O Consultor de Vendas deve demostrar que possui controle
    absoluto sobre essa informação, apresentando planilhas e acompanhamentos 
    que demonstrem os resultados obtidos em relação aos alvos do ano até a data
da avaliação`,
    avaliador: "Consultor Franquia",
    numero: "7.3.1",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    titulo: "Conhecer seus alvos de clientes efetivos e o resultado obtido.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Consultor de Vendas do Canal B2C deve conhecer os alvos de Volume
    Total e o resultado obtido até o momento da avaliação em relação ao volume
    total do canal B2C. O Consultor de Vendas deve demostrar que possui controle
    absoluto sobre essa informação, apresentando planilhas e acompanhamentos
    que demonstrem os resultados obtidos em relação aos alvos do ano até a data
    da avaliação. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.3.2",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    titulo: "Conhecer seus alvos de volume total e resultado obtido.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Consultor de Vendas do Canal B2C deve conhecer os alvos de Volume
    Premium e o resultado obtido até o momento da avaliação em relação ao volume
    Premium do canal B2C. O Consultor de Vendas deve demostrar que possui
    controle absoluto sobre essa informação, apresentando planilhas e
    acompanhamentos que demonstrem os resultados obtidos em relação aos alvos
    do ano até a data da avaliação. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.3.3",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    titulo: "Conhecer seus alvos de volume Premium e resultado obtido.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Para que tenhamos uma boa execução de mercado e uma percepção de
    prestação de serviço por parte do cliente, o Consultor Drill Revenda deve executar
    os procedimentos recomendados conforme o Drill. O Consultor de Vendas Drill
    só realizará os procedimentos se tiver consciência de seus benefícios e as razões
    pelas quais deve adotar cada um dos passos. Dessa forma, o Consultor de Vendas
    Drill deverá explicar os 7Vs e qual a importância de cada passo dessa rotina. `,
    avaliador: "Consultor Franquia",
    numero: "7.3.4",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    titulo:
      "Saber quais são os 7V's e por quais razões deve-se adotar cada um dos passos.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O consumidor final é a razão principal de todo nosso trabalho. Para tanto,
    devemos realizar uma execução nos Pontos de Vendas a fim de impulsionar o
    consumo dos nossos produtos. O consultor de vendas deve entender e saber
    aplicar os conceitos de execução em Merchandising, Exposição e Precificação,
    demonstrando domínio sobre a importância de cada aspecto da execução para
    nosso desempenho no mercado.`,
    avaliador: "Consultor Franquia",
    numero: "7.3.5",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    titulo: 'Saber os conceitos de Execução do PDV na "Visão do Consumidor".',
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `As principais plataformas de relacionamento desenvolvidas pela Raízen
    para se diferenciar da concorrência. O Consultor de Vendas Drill deve conhecer
    todos os pilares dessas plataformas, acompanhando a performance de seus
    clientes através do acompanhamento da performance dos clientes. Deve mostrar
    que possui os resultados do mês anterior, o alvo do mês corrente e o plano de
    ação SMART para evolução do KPI. Este plano de ação deve incluir todos os KPi’s
    medidos além de a quebra por consultor de vendas. `,
    avaliador: "Consultor Franquia",
    numero: "7.3.6",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    titulo:
      " Conhece as plataformas e ações motivacionais e sabe explicar os pilares e benefícios de cada uma delas.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O consultor de vendas efetua coletas de preço (IN e OUT). Devem ser
    feitas pelo menos duas pesquisas por dia, podendo ser de preços IN (preço do
    distribuidor para a revenda) ou OUT (preço da revenda para o consumidor final).
    Essas duas pesquisas referem-se à quantidade de Pontos de Vendas pesquisados,
    ou seja, são dois Pontos de Vendas que devem ser pesquisados por dia, mesmo
    que em um dos Pontos de Venda sejam feitas coletas de dois ou mais produtos
    (IN ou OUT). 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.3.7",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill B2C (Revenda+Varejo)",
    titulo: "Vendedor efetua coletas de preço conforme rotina definida.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Consultor de vendas implementa os treinamentos dos funcionários dos
    postos (frentistas, trocadores e gerentes). Treinamentos presenciais – Cada
    consultor de vendas deve realizar no mínimo 02 treinamentos presenciais por
    mês. Os conteúdos podem ser variados, mas devem passar pelos principais
    pontos do varejo, Plataformas, motivacionais, rentabilidade e oportunidades no
    posto, abertura de capô e abordagem de pista, portfólio. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.3.8",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill Varejo",
    titulo:
      "Consultor de Vendas Varejo implementa os treinamentos aos funcionários dos postos (frentistas, trocadores e gerentes). ",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `No caso de consultor que atende o segmento do Varejo, deve possuir
    total controle sobre a informação de Postos com Compra Zero de sua área e
    ações necessárias para reverter essas situações. `,
    avaliador: "Consultor Franquia",
    numero: "7.3.9",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill Varejo",
    titulo:
      "Consultor de Vendas Varejo deve possuir total controle sobre a informação de Postos com compra Zero e ações necessárias para reverter estas situações.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Conhecer e explicar os conceitos da calculadora de valor e saber utilizar. `,
    avaliador: "Consultor Franquia",
    numero: "7.3.10",
    bucket: "Metodologia de Vendas",
    grupo: "Consultor de Vendas Drill Varejo",
    titulo:
      "Conhecer e explicar os conceitos da calculadora de valor e saber utilizar. ",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Ao longo do ano treinamentos obrigatórios serão disponibilizados para a
    equipe de vendas dos Distribuidores. Cabe ao Coordenador de Marketing garantir
    que toda a equipe de vendas tenha conhecimento dos módulos de treinamentos
    disponíveis e como acessá-los e acompanhar junto aos gerentes e supervisores
    a realização dos cursos por parte de toda a equipe de vendas. `,
    avaliador: "Trade Mkt",
    numero: "7.4.1",
    bucket: "Metodologia de Vendas",
    grupo: "Gestão De Recursos Humanos B2C (Revenda+Varejo)",
    titulo:
      "A participação da equipe do distribuidor em todos os módulos de treinamentos obrigatórios:",
    periodo: "Anual",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Treinamentos obrigatórios com provas e/ou quiz independentes
    acontecerão ao longo do ano. Será avaliada a nota média atingida pelo
    distribuidor nesses testes. O Distribuidor pontuará conforme a escala
    determinada.`,
    avaliador: "Trade Mkt",
    numero: "7.4.2",
    bucket: "Metodologia de Vendas",
    grupo: "Gestão De Recursos Humanos B2C (Revenda+Varejo)",
    titulo:
      " A nota média da equipe do distribuidor em relação as provas e/ou Quizz foi de:",
    periodo: "Anual",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 300,
  },
  {
    evidencia: "S",
    descricao: `Realizar semanalmente reunião com o consultor de vendas para garantir
    a cobertura correta de seus clientes por meio de um planejamento de visitas
    (CALL PLAN) eficiente, procurar direcionar o consultor de vendas para atingir
    seus alvos, focando nos pontos prioritários de desenvolvimento, orientar o
    consultor de vendas quanto à preparação de suas visitas (POPSA) e incentivar a
    prospecção discutindo o SPANCOP, analisando os indicadores e, por meio disso,
    fazendo a programação para a próxima semana. O coaching realizado pelo
    supervisor deverá englobar todas essas atividades. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.5.1",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2B",
    titulo:
      "Realizar semanalmente reunião com Vendendor para programação semanal",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Supervisor não deve ser solicitado a desenvolver atividades que não
    estejam diretamente ligadas aos seus consultores de vendas B2B. Casos em que
    a exclusividade não seja obedecida devem necessariamente estar negociadas no
    Plano de Negócios (BP).`,
    avaliador: "Consultor Franquia",
    numero: "7.5.2",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2B",
    titulo:
      "Dedicar-se exclusivamente a cuidar do seu mercado e chefia de sua equipe B2B",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Supervisor, a fim de planejar, acompanhar, e dar ritmo ao seu trabalho,
    deve saber qual é o alvo de volume da sua área e qual foi o resultado obtido em
    vendas até a data. Os dados devem ser expressos pelo Supervisor em litros e
    percentual em relação ao alvo. O Supervisor deve ter sob controle o SPANCOP,
    Call Plan e POPSA de toda sua equipe, avaliando os índices do SPANCOP e
    verificando se o resultado até a data está compatível com a meta estabelecida. `,
    avaliador: "Consultor Franquia",
    numero: "7.5.3",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2B",
    titulo: "Conhecer seus alvos mensais de volume e o resultado obtido B2B",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O volume de produtos premium garante a rentabilidade do negócio, e a
    busca pelo crescimento desse indicador deve ser o objetivo de todos, por meio
    das negociações cliente a cliente e da oferta de mix de maior valor agregado.
    Vendas cruzadas (venda de outros produtos e serviços) e Melhoria de Produtos
    (venda de produtos Premium ou de maior valor agregado) influenciam
    diretamente nesse Índice de Performance. A fim de acompanhar o desempenho
    da equipe e projetar os resultados da área, o Supervisor deve saber seu alvo de
    produtos premium e o resultado obtido no mês corrente acumulado até a data.`,
    avaliador: "Consultor Franquia",
    numero: "7.5.4",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2B",
    titulo:
      "Conhecer seus alvos de volume total de produtos premium e o resultado obtido B2B (MIX PREMIUM)",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Para obter resultados sólidos no SPANCOP, o supervisor deve conhecer
    o significado de cada fase dessa metodologia de vendas e seus marcos formais,
    bem como entender o preenchimento da ferramenta online D-SPANCOP. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.5.5",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2B",
    titulo:
      "Conhecer, aplicar e gerenciar os indicadores do SPANCOP (Força do Tubo, Alvo incremental, Tempo de Ciclo, Taxa de Sucesso). Atentar-se aos BIG 5 e TOP 5. Orientar a equipe nas grandes negociações, participando quando necessário e dando apoio ao desenvolvimento da equipe.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Entende-se por cliente em fase de abandono, ou Churn no canal B2B,
    aqueles clientes que estão sem comprar há 180 dias ou mais. Esse indicador é
    muito importante para gerenciarmos a retenção de clientes e aumentarmos a
    distribuição dos nossos produtos no mercado, garantindo a manutenção dos
    esforços de prospecção feitos anteriormente. Para que tenhamos os melhores
    resultados na retenção desses clientes, é fundamental que o Supervisor conheça
    os dez maiores clientes Churn da sua área de atuação. O assistente de vendas
    B2B deve realizar a pesquisa nos clientes Churn conforme sugestão do sistema,
    enviar o relatório diariamente com as causas de não compra para o supervisor
    analisar e elaborar seu plano de ação para reverter esses clientes.`,
    avaliador: "Consultor Franquia",
    numero: "7.5.6",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2B",
    titulo:
      "Gerenciamento da retenção dos clientes (Churn) com plano de blindagem para os grandes clientes.",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Supervisor de vendas deve conhecer o perfil da sua equipe de B2B
    (consultores de vendas e STD), características do cargo, e desenvolver os
    membros de sua equipe por meio de feedbacks de resultados das experiências
    em rota com o consultor de vendas. Isso será feito por meio de formulário de
    coach padrão. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.5.7",
    bucket: "Metodologia de Vendas",
    grupo: "Supervisor de Vendas B2B",
    titulo: "Realiza Coaching com seus vendedores dentro do padrão Raízen B2B",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Consultor de Vendas B2B, a fim de planejar, acompanhar, e dar ritmo
    ao seu trabalho, deve saber qual é o alvo de volume da sua área e qual foi o
    resultado obtido em vendas até a data. Os dados devem ser expressos pelo
    Consultor de Vendas B2B em litros e percentual em relação ao alvo. O Consultor
    de Vendas B2B deve ter sob controle o SPANCOP, Call Plan e POPSA de toda sua
    área, avaliando os índices do SPANCOP e verificando se o resultado até a data
    está compatível com a meta estabelecida. `,
    avaliador: "Consultor Franquia",
    numero: "7.6.1",
    bucket: "Metodologia de Vendas",
    grupo: "Vendedor Spancop B2B",
    titulo: "Conhecer seus alvos mensais de volume e o resultado obtido B2B",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O volume de produtos premium garante a rentabilidade do negócio, e a
    busca pelo crescimento desse indicador deve ser o objetivo de todos, por meio
    das negociações cliente a cliente e da oferta de mix de maior valor agregado.
    Vendas cruzadas (venda de outros produtos e serviços) e Melhoria de Produtos
    (venda de produtos Premium ou de maior valor agregado) influenciam
    diretamente nesse Índice de Performance. A fim de acompanhar o desempenho da equipe e projetar os resultados da área, o Consultor de Vendas B2B deve
    saber seu alvo de produtos premium e o resultado obtido no mês corrente
    acumulado até a data. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.6.2",
    bucket: "Metodologia de Vendas",
    grupo: "Vendedor Spancop B2B",
    titulo:
      "Conhecer seus alvos devolume total de produtos premium e o resultado obtido B2B (MIX PREMIUM)",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Para execução correta do SPANCOP, o consultor de vendas B2B deve
    conhecer o significado de cada fase da metodologia e seus marcos formais, bem
    como entender corretamente o uso da ferramenta online. O consultor de vendas
    B2B deve atualizar sua ferramenta D-SPANCOP, no mínimo, semanalmente. À
    medida que os consultores de vendas B2B movem suas oportunidades através
    das fases do SPANCOP, eles aumentam seu entendimento e compreensão dos
    Clientes, e, ao mesmo tempo, vão desenvolvendo seu relacionamento com
    diferentes áreas dentro das empresas. Esse processo é fundamental para que os
    consultores de vendas B2B criem valor para os Clientes, entregando soluções que
    atendam suas prioridades, metas e necessidades. Os consultores de vendas B2B
    devem entender corretamente o que é necessário em cada fase e também o que
    é preciso para passar para a fase seguinte. 
    `,
    avaliador: "Consultor Franquia",
    numero: "7.6.3",
    bucket: "Metodologia de Vendas",
    grupo: "Vendedor Spancop B2B",
    titulo:
      "Conhece e preenche rotineiramente o SPANCOP e entende os principais indicadores (Força do Tubo, Alvo incremental, Tempo de Ciclo, Taxa de Sucesso), indicando também os BIG 5 e TOP 5. ",
    periodo: "Anual",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O objetivo do Calendário Anual de Treinamento é capacitar todas as
    equipes de vendas de B2B para superar as argumentações de vendas dos
    competidores. As equipes de vendas (consultor de vendas B2B, Supervisores e
    STD) serão treinadas sobre o novo formato de conteúdo, metodologia e execução
    das estratégias e argumentações de vendas Raízen Lubrificantes, direcionando a
    equipe a uma comunicação homogênea ao mercado. O distribuidor deverá
    realizar todos os treinamentos referentes às trilhas de capacitação que forem
    solicitados pela Raízen. 
    `,
    avaliador: "Trade Mkt",
    numero: "7.7.1",
    bucket: "Metodologia de Vendas",
    grupo: "Gestão de Recursos Humanos B2B",
    titulo:
      "O Distribuidor aplicou as Avaliações de Nivelamento B2B e todos os vendedores participaram e responderam a todos os questionamentos e o resultado foi:",
    periodo: "Anual",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `A Avaliação de Nivelamento tem como objetivo mensurar o nível de
    conhecimento das equipes de vendas B2B e identificar os principais gaps. E, com
    os resultados das avaliações, diagnosticar os maiores gaps de conhecimento das
    equipes e elaborar um plano de ação para construir treinamentos em um
    calendário estruturado de capacitação. 
    `,
    avaliador: "Trade Mkt",
    numero: "7.7.2",
    bucket: "Metodologia de Vendas",
    grupo: "Gestão de Recursos Humanos B2B",
    titulo:
      "Plano elaborado pelo supervisor para melhoria da equipe com base nos resultados da avaliação e coaching",
    periodo: "Anual",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 300,
  },
  {
    evidencia: "S",
    descricao: `Coordenador de marketing deverá obrigatoriamente participar das
    teleconferências definidas pelo time da Raízen, das Webcasts dos Supervisores
    de Vendas, das sessões presenciais e Workshops de acordo com a periodicidade
    solicitada pelo time Raízen. Qualquer impossibilidade do Coordenador de
    Marketing de participar de qualquer uma das atividades agendadas pelo
    marketing deverá ser previamente comunicada ao time Raízen e ao Consultor
    Comercial Raízen. 
    `,
    avaliador: "Trade Mkt",
    numero: "8.1.1",
    bucket: "Marketing",
    grupo: "Coordenador de Marketing",
    titulo:
      "Participação dos Coordenadores de Marketing e Supervisores de Vendas nas reuniões mensais de acordo com a periodicidade solicitada pelo time Raízen.",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Fundo Local é um fundo de investimento com recursos do próprio
    distribuidor gerido pelo coordenador para ações locais em seus clientes e
    mercado de atuação. Espera-se que o distribuidor tenha um perfil empreendedor
    que entenda as necessidades do mercado relacionadas a desenvolvimento e
    aumento da participação de mercado. Para isso, é importante o reinvestimento
    de pelo menos 1% de todo o faturamento IN no mercado de atuação do
    distribuidor no Fundo Local. O distribuidor precisa ter um controle de todos os
    investimentos e compartilhar as informações com o consultor comercial ou time
    de marketing da Raízen nas reuniões de alinhamento do Plano de Negócios. 
    `,
    avaliador: "Trade Mkt",
    numero: "8.1.2",
    bucket: "Marketing",
    grupo: "Coordenador de Marketing",
    titulo:
      "O Distribuidor possui Fundo Local para investimento em clientes e controla os gastos dessas ações",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `O Coordenador de Marketing comunica e cascateia para a equipe de
    vendas as promoções e campanhas de incentivo vigentes em reuniões e matinais
    e garante que a equipe de vendas possua material explicativo das ações na pasta
    do vendedor. `,
    avaliador: "Trade Mkt",
    numero: "8.1.3",
    bucket: "Marketing",
    grupo: "Coordenador de Marketing",
    titulo:
      "O Coordenador de Marketing comunica e cascateia para a equipe de vendas as promoções e campanhas de incentivo vigentes em reuniões e matinais e garante que a equipe de vendas possua material explicativo das ações na pasta do vendedor.",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `Participação em rota no mínimo a cada 15 dias, utilizando o checklist
    disponibilizado por Marketing Raízen para capturar evidências. 
    `,
    avaliador: "Trade Mkt",
    numero: "8.1.4",
    bucket: "Marketing",
    grupo: "Coordenador de Marketing",
    titulo:
      "Participação em rota no mínimo a cada 15 dias, utilizando o checklist disponibilizado por Marketing Raízen para capturar evidências",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Coordenador de Marketing deve gerenciar as implementações de
    LubsDay do seu distribuidor`,
    avaliador: "Trade Mkt",
    numero: "8.1.5",
    bucket: "Marketing",
    grupo: "Coordenador de Marketing",
    titulo:
      "Coordenador de Marketing deve gerenciar as implementações de LubsDay do seu distribuidor",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Realização dos treinamentos obrigatórios pelo coordenador de
    Marketing. 
    `,
    avaliador: "Trade Mkt",
    numero: "8.1.6",
    bucket: "Marketing",
    grupo: "Gestão de Marketing",
    titulo: "Realização dos treinamentos obrigatórios.",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `Realiza acompanhamento e reporta o calendário promocional do
    distribuidor e acompanha os resultados de todas as atividades de marketing do
    seu distribuidor. 
    `,
    avaliador: "Trade Mkt",
    numero: "8.1.7",
    bucket: "Marketing",
    grupo: "Coordenador de Marketing",
    titulo:
      "Realiza acompanhamento e reporta os resultados de todas as atividades de marketing do seu distribuidor – envio de relatórios atualizados para Marketing Raízen, conforme periodicidade solicitada",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `O coordenador de marketing avalia a performance de execução dos
    materiais de ponto de venda (MPDVs) nos canais prioritários (canais 5 estrelas)
    para os três segmentos (carros, motos e caminhões).
    `,
    avaliador: "Trade Mkt",
    numero: "8.1.8",
    bucket: "Marketing",
    grupo: "Gestão de Marketing",
    titulo:
      "O coordenador de marketing avalia a performance de execução dos pontos de venda dos canais prioritários",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Trade Mkt",
    numero: "8.2.1",
    bucket: "Marketing",
    grupo: "Gestão de Marketing",
    titulo:
      "Percentual de efetivos cadastrados nas plataformas motivacionais. ",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Trade Mkt",
    numero: "8.2.2",
    bucket: "Marketing",
    grupo: "Gestão de Marketing",
    titulo:
      "O percentual de pontos de venda com trocador cadastrado nas plataformas motivacionais é de:",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Trade Mkt",
    numero: "8.2.3",
    bucket: "Marketing",
    grupo: "Gestão de Marketing",
    titulo: "O percentual de trocadores com tampinha cadastrada:",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Percentual de pontos creditados aos usuários das plataformas
    motivacionais versus pontos resgatados pelos usuários das plataformas
    motivacionais. `,
    avaliador: "Trade Mkt",
    numero: "8.2.4",
    bucket: "Marketing",
    grupo: "Gestão de Marketing",
    titulo: "Percentual de pontos resgatados versus pontos Creditados",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 300,
  },
  {
    evidencia: "S",
    descricao: "",
    avaliador: "Trade Mkt",
    numero: "8.2.5",
    bucket: "Marketing",
    grupo: "Coordenador de Marketing",
    titulo:
      "Percentual de cobertura da execução das campanhas promoções e pontos de venda. ",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `A garantia da execução da Rotina Básica nos Postos do Varejo por parte
    de todos os Consultores é a principal função do Supervisor de Vendas. Para isso,
    ele deve utilizar a Ferramenta do Coaching. O supervisor deve realizar em média
    de 8 a 12 Coachings por mês, ou seja, 2 a 3 por semana. Os coachings devem
    ser realizados no sistema, no Módulo do Supervisor. `,
    avaliador: "Time de BI",
    numero: "9.1.1",
    bucket: "Execução B2C",
    grupo: "Rotina Básica Supervisor Drill B2C",
    titulo: "Supervisor de B2C realiza coaching ",
    periodo: "Trimestral",
    criterio:
      "O supervisor deve realizar em média de 8 a 12 Coachings por mês, ou seja, 2 a 3 por semana. Os coachings devem ser realizados no sistema, no Módulo do Supervisor.",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Reunir-se com seus consultores de vendas (Matinal) antes da saída para
    o mercado, obedecendo execução de acordo com o previsto no Drill Varejo.
    A reunião matinal é o momento em que o Supervisor transmite as instruções,
    sana as dúvidas e alinha estratégias e compromissos com a equipe de vendas. A
    reunião deve respeitar a execução prevista na Rotina Drill Varejo e deve ser
    realizada diariamente de forma remota ou presencial. 
    `,
    avaliador: "Time de BI",
    numero: "9.1.2",
    bucket: "Execução B2C",
    grupo: "Rotina Básica Supervisor Drill B2C",
    titulo: "Realizar reunião matinal com time de vendas",
    periodo: "Trimestral",
    criterio:
      "Variável \r\nA partir de 15/mês = Pontuação Máxima\r\nA partir de 10/mês = Metade \r\nAté 9 nove/mês = Zero",
    pontuacao_maxima: 50,
  },
  {
    evidencia: "S",
    descricao: `Reunir-se com seus consultores (Vespertina) após o retorno da saída para
    o mercado, obedecendo execução de acordo com o previsto no Drill Varejo e
    seguindo o modelo abaixo descrito.
    - SMMA – verificando ocorrências, boas práticas e eventuais Potenciais
    Incidentes.
    - Volume total – Compromisso do Dia x Realizado
    - Volume total de produtos Premium – Compromisso do Dia x Realizado
    - Efetivos – Compromisso do Dia x Realizado
    - Caso os números estejam de acordo com o previsto deve-se destacar as
    melhores práticas e reconhecer bons resultados. Caso o número esteja
    diferente do acordado para mais ou para menos deve-se entender o que
    ocasionou a não acuracidade na previsão e entender qual o plano de ação
    de modo a tornar a previsão de vendas mais precisa ajudando assim ao
    distribuidor poder realizar um melhor controle de estoque e o time de
    vendas a entender o que move a decisão de compra do cliente. 
    `,
    avaliador: "Time de BI",
    numero: "9.1.3",
    bucket: "Execução B2C",
    grupo: "Rotina Básica Supervisor Drill B2C",
    titulo: "Realizar reunião vespertina com time de vendas",
    periodo: "Trimestral",
    criterio:
      "Variável \r\nA partir de 15/mês = Pontuação Máxima\r\nA partir de 10/mês = Metade \r\nAté 9 nove/mês = Zero",
    pontuacao_maxima: 50,
  },
  {
    evidencia: "S",
    descricao: `1º - Verificar Planejamento do PDV no Smartphone e/ou Tablet: Visa
    abordar de forma correta o cliente, antecipando as ações a serem tomadas no
    momento da visita e a correta abordagem baseada nas necessidades e
    oportunidades no cliente. 
    `,
    avaliador: "Vendas",
    numero: "9.2.1",
    bucket: "Execução B2C",
    grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    titulo: "Verificar Planejamento do PDV",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `2º - Verificar Merchandising: Sinalizar interna e externamente ao
    consumidor que o PDV trabalha com a marca e os produtos Shell. Com uma boa
    aplicação dos materiais no ponto de venda, conseguimos destacar nossa marca,
    reforçar e valorizar a qualidade de nossos produtos, despertar o interesse do
    consumidor e aumentar o conhecimento sobre nossos produtos, estimulando a
    decisão de compra do cliente final e ajudando na conquista por uma maior
    participação de mercado. 
    `,
    avaliador: "Vendas",
    numero: "9.2.2",
    bucket: "Execução B2C",
    grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    titulo: "Verificar Merchandising no PDV",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `3º - Verificar Exposição de Produtos: Atrair a atenção do consumidor
    mostrando os produtos Shell disponíveis no PDV de forma organizada e com
    padrão de apresentação seguindo o planograma indicado. Ganhar espaço em
    relação à concorrência e incentivar a venda por impulso, visto que o produto está
    ao alcance do consumidor. É nesta etapa também que se deve solicitar ao
    revendedor acesso aos estoques para que possa realizar o preenchimento dos
    espaços vazios expostos após a organização dos produtos. `,
    avaliador: "Vendas",
    numero: "9.2.3",
    bucket: "Execução B2C",
    grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    titulo: "Verificar Exposição de Produtos",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `4º - Verificar Checklist para clientes das plataformas
    motivacionais: Para garantir que todas as ações a serem realizadas nos clientes
    plataformas motivacionais sejam executadas na visita a esse cliente, o consultor
    de vendas deve utilizar o checklist padrão para verificar todos os pontos de ação
    dos pilares das plataformas motivacionais.`,
    avaliador: "Vendas",
    numero: "9.2.4",
    bucket: "Execução B2C",
    grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    titulo: "Verificar Checklist dos clientes B2C",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 70 % = Pontuação Máxima \r\nEntre 40%  e 69,9% = Metade\r\nAté 39,9% = Zero",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `5º - Verificar Estoque: Identificar as oportunidades e os padrões de
    consumo do cliente, bem como negociações da concorrência, produtos similares
    que o PDV comercializa além de um indicativo da saúde financeira da revenda. 
    `,
    avaliador: "Vendas",
    numero: "9.2.5",
    bucket: "Execução B2C",
    grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    titulo: "Verificar Estoque do PDV",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `6º - Verificar Preços: Argumentar com o cliente o retorno
    proporcionado pelos produtos Shell em relação à concorrência (utilizar
    calculadora de valor no smartphone) e realizar uma sugestão de posicionamento
    de Preços OUT no PDV. `,
    avaliador: "Vendas",
    numero: "9.2.6",
    bucket: "Execução B2C",
    grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    titulo: "Verificar Preços",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `7º - Verificar oferta de Produtos indicados para o PDV: Capturar
    todas as oportunidades de vendas dentro do cliente, aumentando o cross-sell de
    Produtos e, consequentemente, o Faturamento Unitário obtido no pedido. 
    `,
    avaliador: "Vendas",
    numero: "9.2.7",
    bucket: "Execução B2C",
    grupo: "Rotina Básica do Consultor de Vendas Drill B2C",
    titulo:
      "Vender produtos históricos e indicados para o PDV em quantidades compatíveis discutindo apenas ao final com o cliente",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Em rota, com o Formulário do Coaching em mãos, cada avaliador
    verificará 10 (dez) pontos de vendas e determinará o percentual dos PDVs que
    estavam executados em Merchandising, conforme orientações de aplicação do
    Marketing. Ex.: Zona Quente, Domínio do PV. 
    `,
    avaliador: "Vendas",
    numero: "9.3.1",
    bucket: "Execução B2C",
    grupo: "Execução de Mercado B2C",
    titulo: "Execução da Visão do consumidor no PDV: Merchandising",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 90 % = Pontuação Máxima \r\nEntre 75%  e 89,9% = Metade\r\nEntre 60%  e 74,9% = Um Quarto\r\nAté 59,9% = Zero",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: `Em rota, com o Formulário do Coaching em mãos, cada avaliador
    verificará 10 (dez) pontos de vendas e determinará o percentual dos PDVs que
    estavam executados em Exposição de Produtos na área de vendas, conforme
    planograma correto e zona quente.`,
    avaliador: "Vendas",
    numero: "9.3.2",
    bucket: "Execução B2C",
    grupo: "Execução de Mercado B2C",
    titulo: "Execução da Visão do consumidor no PDV: Exposição",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 90 % = Pontuação Máxima \r\nEntre 75%  e 89,9% = Metade\r\nEntre 60%  e 74,9% = Um Quarto\r\nAté 59,9% = Zero",
    pontuacao_maxima: 150,
  },
  {
    evidencia: "S",
    descricao: ` Em rota, com o Formulário do Coaching em mãos, cada avaliador
    verificará 10 (dez) pontos de vendas e determinará o percentual dos PDVs que
    estavam executados em Precificação dos produtos, com precificação nas
    prateleiras, nos produtos ou utilização do material. 
    `,
    avaliador: "Vendas",
    numero: "9.3.3",
    bucket: "Execução B2C",
    grupo: "Execução de Mercado B2C",
    titulo: "Execução da Visão do consumidor no PDV: Precificação",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 90 % = Pontuação Máxima \r\nEntre 75%  e 89,9% = Metade\r\nEntre 60%  e 74,9% = Um Quarto\r\nAté 59,9% = Zero",
    pontuacao_maxima: 300,
  },
  {
    evidencia: "S",
    descricao: `COBERTURA: total de clientes roteirizados sobre a base total de clientes
    do consultor de vendas. 
    `,
    avaliador: "Time de BI",
    numero: "9.4.1",
    bucket: "Execução B2C",
    grupo: "KPI's de Visitação B2C",
    titulo: "KPI's de Visitação: Cobertura",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 95 % = Pontuação Máxima \r\nEntre 85%  e 94,9% = Metade\r\nEntre 75%  e 84,9% = Um Quarto\r\nAté 74,9% = Zero",
    pontuacao_maxima: 300,
  },
  {
    evidencia: "S",
    descricao: `EFETIVIDADE DA VISITA: Determina o número de clientes efetivos
    (com pedidos) versus o número de visitas realizadas do consultor a clientes em
    determinado roteiro. `,
    avaliador: "Time de BI",
    numero: "9.4.2",
    bucket: "Execução B2C",
    grupo: "KPI's de Visitação B2C",
    titulo: "KPI's de Visitação: Efetividade",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 30 % = Pontuação Máxima \r\nEntre 20%  e 29,9% = Metade\r\nEntre 15%  e 19,9% = Um Quarto\r\nAté 14,9% = Zero",
    pontuacao_maxima: 300,
  },
  {
    evidencia: "S",
    descricao: `PENETRAÇÃO: Representa a porcentagem de clientes efetivos (com
      vendas) dividida pelo total de clientes roteirizados da base.`,
    avaliador: "Time de BI",
    numero: "9.4.3",
    bucket: "Execução B2C",
    grupo: "KPI's de Visitação B2C",
    titulo: "KPI's de Visitação: Penetração",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 65% = Pontuação Máxima \r\nEntre 55%  e 64,9% = Metade\r\nEntre 45%  e 54,9% = Um Quarto\r\nAté 44,9% = Zero",
    pontuacao_maxima: 330,
  },
  {
    evidencia: "S",
    descricao: `CUMPRIMENTO DO PLANO: É a relação entre o número de clientes
    visitados versus o número de clientes planejados no roteiro de visitas. `,
    avaliador: "Time de BI",
    numero: "9.4.4",
    bucket: "Execução B2C",
    grupo: "KPI's de Visitação B2C",
    titulo: "Cumprimento plano de visitas 90% ou acima",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 80% = Pontuação Máxima \r\nEntre 70%  e 79,9% = Metade\r\nEntre 60%  e 69,9% = Um Quarto\r\nAté 59,9% = Zero",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Força do Tubo: Distribuidor vai pontuar de acordo com a Força do Tubo
    do SPANCOP no momento da avaliação. 
    `,
    avaliador: "Vendas",
    numero: "10.1.1",
    bucket: "Execução B2B",
    grupo: "Execução de mercado e pipeline de oportunidades B2B",
    titulo:
      "Quanto a prospecção de novos clientes a Força do Tubo foi: (Igual ou Superior a 5)",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 5 = Pontuação Máxima \r\nEntr 3 e 4 = Metade\r\nInferior a 3 = Zero",
    pontuacao_maxima: 300,
  },
  {
    evidencia: "S",
    descricao: `Alvo Incremental: Distribuidor vai pontuar neste item de acordo com
    o Alvo incremental entregue no momento da avaliação. `,
    avaliador: "Vendas",
    numero: "10.1.2",
    bucket: "Execução B2B",
    grupo: "Execução de mercado e pipeline de oportunidades B2B",
    titulo:
      "Quanto ao plano de negocios o Alvo Incremental Entregue foi: (Igual ou superior a 100%)",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 100% = Pontuação Máxima \r\nEntre 90%  e 99,9% = Metade\r\nEntre 80%  e 89,9% = Um Quarto\r\nAté 79,9% = Zero",
    pontuacao_maxima: 250,
  },
  {
    evidencia: "S",
    descricao: `Sua Taxa de Sucesso: Distribuidor vai pontuar de acordo com a Taxa
    de Sucesso do SPANCOP.`,
    avaliador: "Vendas",
    numero: "10.1.3",
    bucket: "Execução B2B",
    grupo: "Execução de mercado e pipeline de oportunidades B2B",
    titulo:
      "Quanto ao resultado, a Taxa de Sucesso foi: (Igual ou superior a 25%)",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nIgual ou superior a 25% = Pontuação Máxima \r\nEntre 20%  e 24,9% = Metade\r\nEntre 15%  e 19,9% = Um Quarto\r\nAté 14,9% = Zero",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `Tempo de ciclo: Distribuidor vai pontuar de acordo com o tempo de
    ciclo do SPANCOP.`,
    avaliador: "Vendas",
    numero: "10.1.4",
    bucket: "Execução B2B",
    grupo: "Execução de mercado e pipeline de oportunidades B2B",
    titulo:
      "Quanto a efetividade das prospecções o Tempo de Ciclo foi: (Inferior a 100 dias)",
    periodo: "Trimestral",
    criterio:
      "Variável\r\nInferior a 100 dias = Pontuação Máxima \r\nEntre 100 e 120 dias = Metade\r\nEntre 121 e 130 dias = Um Quarto\r\nAcima de 130 dias = Zero",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: ` O Supervisor é responsável por fazer a gestão e orientar sua equipe na
    utilização das ferramentas de Sales 1st. O Call Plan é uma agenda que detalha
    o cronograma de visitas e os objetivos de cada contato com o cliente. Dessa
    forma, é imprescindível que o Supervisor estimule o uso na rotina do consultor
    para se beneficiar dessa organização e controle, cobrando disciplina e
    periodicidade no report. 
    `,
    avaliador: "Time de BI",
    numero: "10.2.1",
    bucket: "Execução B2B",
    grupo: "Rotina Básica Supervisor SPANCOP",
    titulo:
      "Faz gestão e orienta sua equipe na utilização das ferramentas de SaleS 1st (Call Plan, POPSA, SPANCOP, Call Report, Plano de Contas) - Atualização SPANCOP",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O Supervisor de vendas B2B deve conhecer o perfil da sua equipe de
    B2B (Consultores de Vendas B2B e STD), características do cargo, e desenvolver
    os membros de sua equipe por meio de feedbacks de resultados das experiências
    em rota com o consultor B2B. Isso será feito por meio de formulário de coach
    padrão. 
    `,
    avaliador: "Time de BI",
    numero: "10.2.2",
    bucket: "Execução B2B",
    grupo: "Rotina Básica Supervisor SPANCOP",
    titulo: "Supervisor realiza coaching ",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 100,
  },
  {
    evidencia: "S",
    descricao: `O POPSA é o método para preparação de visitas utilizado em vendas
    avançadas cuja finalidade é, por meio de propósitos e objetivos de visita
    claramente definidos, levar o consultor de vendas B2B a traçar sua estratégia e
    antecipar possíveis objeções no momento de contato com o cliente.`,
    avaliador: "Time de BI",
    numero: "10.3.1",
    bucket: "Execução B2B",
    grupo: "Rotina Básica Vendedor SPANCOP",
    titulo:
      "Realiza o P.O.P.S.A antes de entrar no cliente preparando suas visitas B2B",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `- O consultor de vendas B2B maximizará o sucesso de suas negociações
    se conseguir agregar maior valor aos seus clientes por meio do correto
    Número Item Período de
    Avaliação
    Critério de
    AtingimentoPontuação
    Máxima10.2.1
    Faz gestão e orienta sua equipe na
    utilização das ferramentas de SaleS
    1st (Call Plan, POPSA, SPANCOP,
    Call Report, Plano de Contas) -
    Atualização SPANCOP
    Trimestral Sim ( ) Não ( ) 10010.2.2 Supervisor realiza coaching Trimestral Sim ( ) Não ( ) 100
    atendimento de suas necessidades. Para isso, ele deverá possuir um
    planejamento que envolva no momento adequado as visitas aos seus clientes
    com propósitos claros de desenvolvimento. O Call Plan é a agenda de visitas
    que ajudará o consultor de Vendas B2B no seu planejamento mensal e propósito
    de visitas, assim como o reporte de sua rotina para seus superiores. O Call
    Report é a ferramenta voltada para o fechamento da visita, que permitirá clareza
    em relação às próximas ações e seus envolvidos, documentando todo o histórico
    da visita. 
    `,
    avaliador: "Time de BI",
    numero: "10.3.2",
    bucket: "Execução B2B",
    grupo: "Rotina Básica Vendedor SPANCOP",
    titulo:
      "Utiliza adequadamente o Call Plan no planejamento e prepara os reportes das visitas (Call Report) no B2B",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `O SPANCOP dos consultores de vendas B2B devem ser atualizados
    pelo menos 1 vez ao mês. 
    `,
    avaliador: "Time de BI",
    numero: "10.3.3",
    bucket: "Execução B2B",
    grupo: "Rotina Básica Vendedor SPANCOP",
    titulo: "Atualização SPANCOP",
    periodo: "Trimestral",
    criterio: "Sim ( ) Não ( )",
    pontuacao_maxima: 200,
  },
  {
    evidencia: "S",
    descricao: `Vendas de lubrificantes para CARROS: Para o cálculo da pontuação
    do item, devemos verificar o realizado de volume total no período relativo à
    avaliação versus o alvo previsto no Business Plan (Plano de Negócios) no mesmo
    período. O limite máximo de atingimento é de 120% e o mínimo é de 90%. Caso
    o distribuidor fique abaixo de 90%, a pontuação será zerada nesse item. `,
    avaliador: "Time de BI",
    numero: "11.1.1",
    bucket: "Vendas",
    grupo: "Resultados de Vendas",
    titulo:
      "Venda de Lubrificantes Carros: Em relação ao alvo de volume previsto no Plano de Negócios, o resultado atingido do Distribuidor foi:",
    periodo: "Trimestral",
    criterio:
      "Variável e proporcional ao atingimento entre o intervalo de 90% e 120%",
    pontuacao_maxima: 1000,
  },
  {
    evidencia: "S",
    descricao: `Vendas de lubrificantes para CAMINHÕES: Para o cálculo da
    pontuação do item, devemos verificar o realizado de volume total no período
    relativo à avaliação versus o alvo previsto no Business Plan (Plano de Negócios)
    no mesmo período. O limite máximo de atingimento é de 120% e o mínimo é de
    90%. Caso o distribuidor fique abaixo de 90%, a pontuação será zerada nesse
    item. 
    `,
    avaliador: "Time de BI",
    numero: "11.1.2",
    bucket: "Vendas",
    grupo: "Resultados de Vendas",
    titulo:
      "Venda de Lubrificantes Motos: Em relação ao alvo de volume previsto no Plano de Negócios, o resultado atingido do Distribuidor foi:",
    periodo: "Trimestral",
    criterio:
      "Variável e proporcional ao atingimento entre o intervalo de 90% e 120%",
    pontuacao_maxima: 1000,
  },
  {
    evidencia: "S",
    descricao: `Vendas de lubrificantes para MOTOS: Para o cálculo da pontuação
    do item, devemos verificar o realizado de volume total no período relativo à
    avaliação versus o alvo previsto no Business Plan (Plano de Negócios) no mesmo
    período. O limite máximo de atingimento é de 120% e o mínimo é de 90%. Caso
    o distribuidor fique abaixo de 90%, a pontuação será zerada nesse item. 
    `,
    avaliador: "Time de BI",
    numero: "11.1.3",
    bucket: "Vendas",
    grupo: "Resultados de Vendas",
    titulo:
      "Venda de Lubrificantes Caminhões: Em relação ao alvo de volume previsto no Plano de Negócios, o resultado atingido do Distribuidor foi:",
    periodo: "Trimestral",
    criterio:
      "Variável e proporcional ao atingimento entre o intervalo de 90% e 120%",
    pontuacao_maxima: 1000,
  },
  {
    evidencia: "S",
    descricao: `Vendas de lubrificantes para INDÚSTRIA: Para o cálculo da
    pontuação do item, devemos verificar o realizado de volume total no período
    relativo à avaliação versus o alvo previsto no Business Plan (Plano de Negócios)
    no mesmo período. O limite máximo de atingimento é de 120% e o mínimo é de
    80%. Caso o distribuidor fique abaixo de 80%, a pontuação será zerada nesse
    item. 
    `,
    avaliador: "Time de BI",
    numero: "11.1.4",
    bucket: "Vendas",
    grupo: "Resultados de Vendas",
    titulo:
      "Venda de Lubrificantes Indústria: Em relação ao alvo de volume previsto no Plano de Negócios, o resultado atingido do Distribuidor foi:",
    periodo: "Trimestral",
    criterio:
      "Variável e proporcional ao atingimento entre o intervalo de 80% e 120%",
    pontuacao_maxima: 1000,
  },
  {
    evidencia: "S",
    descricao: `Melhoria de Produtos (Mix Premium): Usando o argumento de
    entregar valor extra, é possível vender um produto de melhor tecnologia e com
    melhor desempenho em uma ampla variedade de aplicações, aumentando assim
    o seu volume de negócios e entrega de valor real para o cliente. Para o cálculo
    da pontuação do item, devemos verificar o realizado de volume de produtos
    Premium no período relativo à avaliação versus o volume total vendido no canal
    de B2B no mesmo período. O limite máximo de atingimento é de 120% e o
    mínimo é de 80%. Caso o distribuidor fique abaixo de 80%, a pontuação será
    zerada nesse item. 
    `,
    avaliador: "Time de BI",
    numero: "11.1.5",
    bucket: "Vendas",
    grupo: "Resultados de Vendas",
    titulo:
      "Venda de Lubrificantes Premium : Em relação a Venda de Produtos Premium o resultado atingido em relação ao alvo previsto no Plano de Negócios foi (máximo 120%):",
    periodo: "Trimestral",
    criterio:
      "Variável e proporcional ao atingimento entre o intervalo de 90% e 120%",
    pontuacao_maxima: 1000,
  },
  {
    descricao: `O total de clientes efetivos realizada no período da avaliação deve
    atingir ou superar a meta do Plano de Negócios no mesmo período, para
    garantirmos a continuidade e o aumento da distribuição dos nossos produtos no
    mercado. O limite máximo de atingimento é de 120% e o mínimo é de 80%.
    Caso o distribuidor fique abaixo de 90%, a pontuação será zerada nesse item. `,
    avaliador: "Time de BI",
    numero: "11.1.6",
    bucket: "Vendas",
    grupo: "Resultados de Vendas",
    titulo:
      "Efetivos B2C: Em relação à venda de produtos para os clientes, a fim de assegurar a disponibilidade dos produtos Shell para o consumidor, a média de clientes efetivos do ano contra o Business Plan foi (máximo 120%):",
    periodo: "Trimestral",
    criterio:
      "Variável e proporcional ao atingimento entre o intervalo de 80% e 120%",
    pontuacao_maxima: 1000,
  },
];

const dadosEditados = dataMock.map((item) => {
  const numerosEncontrados = item.numero.match(/\d+/);
  const primeiroNumero = numerosEncontrados
    ? parseInt(numerosEncontrados[0], 10)
    : undefined;

  if (numerosEncontrados) {
    item.range = numerosEncontrados.map((numero) => {
      const [valor, operador] = numero.split("%");
      const fator = parseFloat(valor);
      const pontos = calcularPontos(fator, operador);
      return { operador: ajustarOperador(operador), fator, pontos };
    });
  } else if (item.criterio.includes("intervalo de")) {
    const match = item.criterio.match(/(\d+)%\s+e\s+(\d+)%/);
    if (match) {
      const [min, max] = match.slice(1, 3).map(parseFloat);
      item.range = [
        {
          operador: ajustarOperador(""),
          fator: min,
          pontos: calcularPontos(min),
        },
        {
          operador: ajustarOperador(""),
          fator: max,
          pontos: calcularPontos(max),
        },
      ];
    }
  }

  // Ajuste do critério após criar o range
  if (item.criterio.trim().startsWith("S")) {
    item.range = [];
    item.criterio = "M";
  } else if (item.criterio.trim().startsWith("V")) {
    item.criterio = "V";
  }

  // Encontrar o capítulo correspondente no mock_capitulos
  const capituloEncontrado = mock_capitulos.items_capitulos.find(
    (capitulo) => capitulo.capitulo === primeiroNumero
  );

  // Adiciona a propriedade "capitulo" se encontrado no mock_capitulos
  item.capitulo = capituloEncontrado ? capituloEncontrado.AreaFoco : undefined;

  return item;
});

function calcularPontos(fator, operador) {
  // Substitua pela lógica desejada para calcular os pontos com base no fator e operador
  if (
    operador &&
    operador.trim().toUpperCase().startsWith("IGUAL OU SUPERIOR A")
  ) {
    return fator;
  } else if (operador && operador.trim().toUpperCase().startsWith("ENTRE")) {
    return fator / 2;
  } else {
    return 0;
  }
}

function ajustarOperador(operador) {
  // Verifica se o operador é definido antes de chamar trim()
  return operador && operador.trim().toUpperCase().startsWith("ACIMA")
    ? "C"
    : "B";
}
