import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Input,
  Flex,
  Button,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  Card,
  InputGroup,
  InputLeftAddon,
  Heading,
  Divider,
  Select, // Importando o componente de card do Chakra UI
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import Distribuidores from "../components/distribuidores";

const SelecionarPeriodoObservacoes = () => {
  // Estados para armazenar os valores dos inputs
  const [erroAno, setErroAno] = useState("");
  const [erroTrimestre, setErroTrimestre] = useState("");
  const [selectedDistribuidor, setSelectedDistribuidor] = useState("");
  const navigate = useNavigate();
  const {
    anoGlobal,
    setAnoGlobal,
    trimestreGlobal,
    setTrimestreGlobal,
    empresaGlobal,
    user,
    userProfile,
    setDistribuidorGlobal,
  } = useAppContext();

  // Função para lidar com a submissão do formulário
  const handleSubmit = (event) => {
    event.preventDefault();

    const route = `/observacoes/${encodeURIComponent(
      anoGlobal
    )}/${encodeURIComponent(trimestreGlobal)}`;
    navigate(route);
  };

  const handleChange = (event) => {
    const selectedDistribuidor =
      event.target.options[event.target.selectedIndex].text;

    // Atualize o estado global usando setDistribuidorGlobal
    setSelectedDistribuidor(event.target.value);

    setDistribuidorGlobal(selectedDistribuidor);
  };

  return (
    <Box p={4}>
      <>
        <Heading>Selecionar Período - Observação dos Avaliadores</Heading>
        <Divider borderWidth="2px" marginTop={"10px"} />
      </>

      <form onSubmit={handleSubmit}>
        <Flex direction="column">
          <InputGroup marginTop={"20px"}>
            <InputLeftAddon children="Ano Safra" width="175px" />
            <Select
              type="text"
              value={anoGlobal}
              onChange={(e) => {
                setAnoGlobal(e.target.value);
              }}
              mb={4}
              maxLength={4}
              isInvalid={erroAno} // Aplica estilo de erro se houver um erro
            >
              <option value="2023">2023/2024</option>
              <option value="2024">2024/2025</option>
              <option value="2025">2025/2026</option>
              <option value="2026">2026/2027</option>
              <option value="2027">2027/2028</option>
              <option value="2028">2028/2029</option>
              <option value="2029">2029/2030</option>
            </Select>
          </InputGroup>
          {erroAno && (
            <Alert status="error" mb={4} textAlign="center">
              <AlertIcon />
              <AlertTitle>{erroAno}</AlertTitle>
            </Alert>
          )}
          <InputGroup marginTop={"20px"}>
            <InputLeftAddon children="Trimestre" width="175px" />

            <Select
              type="number"
              value={trimestreGlobal}
              onChange={(e) => {
                setTrimestreGlobal(e.target.value);
              }}
              mb={4}
              min="1"
              max="4"
              isInvalid={erroTrimestre} // Aplica estilo de erro se houver um erro
            >
              <option value="1">1 (abr - mai - jun)</option>
              <option value="2">2 (jul - ago - set)</option>
              <option value="3">3 (out - nov - dez)</option>
              <option value="4">4 (jan - fev - mar)</option>
            </Select>
          </InputGroup>
          {erroTrimestre && (
            <Alert status="error" mb={4} textAlign="center">
              <AlertIcon />
              <AlertTitle>{erroTrimestre}</AlertTitle>
            </Alert>
          )}
          {userProfile === "Avaliador" && (
            <InputGroup marginTop="20px">
              <InputLeftAddon children="Distribuidor" width="175px" />
              <Select
                onChange={handleChange}
                placeholder="Selecione um distribuidor"
                value={selectedDistribuidor}
              >
                <option value="AGRICOPEL">AGRICOPEL</option>
                <option value="BOA NOVA">BOA NOVA</option>
                <option value="BRAZMAX">BRAZMAX</option>
                <option value="DECLUB">DECLUB</option>
                <option value="FORMULA">FORMULA</option>
                <option value="LUBPAR">LUBPAR</option>
                <option value="LUBTROL">LUBTROL</option>
                <option value="NACIONAL">NACIONAL</option>
                <option value="PNBLUB">PNBLUB</option>
                <option value="PORTOLUB">PORTOLUB</option>
                <option value="QUITE">QUITE</option>
                <option value="SPM">SPM</option>
              </Select>
            </InputGroup>
          )}

          <Button
            type="submit"
            mt="
          32px"
            colorScheme="whatsapp"
            w="150px"
          >
            Selecionar
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default SelecionarPeriodoObservacoes;
