import {
  Box,
  Text,
  Button,
  Flex,
  Divider,
  Icon,
  Heading,
} from "@chakra-ui/react";
import {
  FaBullseye,
  FaClipboard,
  FaCommentDots,
  FaUserTie,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

export default function PaginaContestacao() {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedItem, contestacoesState } = location.state || {}; // Obtendo dados do estado de navegação
  const { distribuidor } = useAppContext();

  // Verifica se selectedItem está definido e encontra a contestação correspondente em contestacoesState
  const contestacaoCorrespondente = contestacoesState.find(
    (contestacao) => contestacao.numero === selectedItem.numero
  );

  if (!selectedItem || !contestacaoCorrespondente) {
    return (
      <Box
        bg="gray.50"
        border="1px"
        borderColor="gray.200"
        borderRadius="md"
        p={6}
        textAlign="center"
        mt={6}
        boxShadow="sm"
      >
        <Text fontSize="lg" color="gray.700" fontWeight="medium">
          O item{" "}
          <Text as="span" color="red.500">
            {selectedItem.numero}
          </Text>{" "}
          não possui nenhuma contestação.
        </Text>
        <Button
          mt={4}
          colorScheme="teal"
          variant="solid"
          onClick={() => navigate(-1)}
          size="md"
          boxShadow="base"
        >
          Voltar
        </Button>
      </Box>
    );
  }

  // Constrói um array de contestações e respostas a partir do contestacaoCorrespondente
  const contestacoesRespostas = [
    {
      tipo: "contestacao",
      data: contestacaoCorrespondente.data_contestacao1,
      hora: contestacaoCorrespondente.hora_contestacao1,
      texto: contestacaoCorrespondente.texto_contestacao1,
    },
    {
      tipo: "resposta",
      data: contestacaoCorrespondente.data_resposta1,
      hora: contestacaoCorrespondente.hora_resposta1,
      texto: contestacaoCorrespondente.texto_resposta1,
    },
    {
      tipo: "contestacao",
      data: contestacaoCorrespondente.data_contestacao2,
      hora: contestacaoCorrespondente.hora_contestacao2,
      texto: contestacaoCorrespondente.texto_contestacao2,
    },
    {
      tipo: "resposta",
      data: contestacaoCorrespondente.data_resposta2,
      hora: contestacaoCorrespondente.hora_resposta2,
      texto: contestacaoCorrespondente.texto_resposta2,
    },
    {
      tipo: "contestacao",
      data: contestacaoCorrespondente.data_contestacao3,
      hora: contestacaoCorrespondente.hora_contestacao3,
      texto: contestacaoCorrespondente.texto_contestacao3,
    },
    {
      tipo: "resposta",
      data: contestacaoCorrespondente.data_resposta3,
      hora: contestacaoCorrespondente.hora_resposta3,
      texto: contestacaoCorrespondente.texto_resposta3,
    },
  ];

  console.log("contestacoesRespostas", contestacoesRespostas);

  return (
    <Box>
      <Heading>
        Contestação do Item {selectedItem.numero} - {distribuidor}
      </Heading>
      <Divider borderWidth="2px" marginTop={"10px"} />

      {/* Botão de Voltar */}
      <Button
        mt={4}
        mb={3}
        colorScheme="red"
        variant="outline"
        onClick={() => navigate(-1)}
      >
        Voltar
      </Button>

      {/* Renderiza detalhes do selectedItem */}
      <Box key={selectedItem.chave} p={4} borderWidth={1} borderRadius="lg">
        <Flex justify="space-between" align="center">
          <Text fontWeight="bold" fontSize="large">
            <Icon as={FaClipboard} color="blue.500" mr={2} />
            {selectedItem.numero}
          </Text>
        </Flex>
        <Divider />
        <Text mt={2}>
          <Icon as={FaUserTie} color="green.500" mr={2} />
          Responsável: {selectedItem.capitulo}
        </Text>
        <Text>
          <Icon as={FaBullseye} color="yellow.500" mr={2} />
          Pontuação Máxima: {selectedItem.pontuacao_maxima}
        </Text>
        <Text>
          <Icon as={FaBullseye} color="yellow.500" mr={2} />
          Pontuação Obtida: {selectedItem.pontuacao_real}
        </Text>
        <Text>
          <Icon as={FaBullseye} color="yellow.500" mr={2} />
          Atingimento: {selectedItem.atingimento}%
        </Text>
        <Box bg="gray.100" p={3} borderRadius="10px" mt={2} mb={2} min="33vw">
          <Icon as={FaCommentDots} color="gray.500" mr={2} />
          <Text>
            <strong>Observação:</strong> {selectedItem.observacao}
          </Text>
        </Box>
      </Box>

      <Divider mt={6} mb={6} />

      <Box mt={4}>
        <Heading as="h2" size="md" mb={6} textAlign="center" color="gray.600">
          Histórico
        </Heading>
        <Box ml="300px">
          {contestacoesRespostas.map(
            (msg, index) =>
              msg.data && msg.hora && msg.texto ? (
                <Flex
                  key={index}
                  bg={msg.tipo === "contestacao" ? "gray.100" : "teal.50"}
                  p={3}
                  borderRadius="lg"
                  alignSelf={
                    msg.tipo === "contestacao" ? "flex-start" : "flex-end"
                  }
                  maxWidth="70%"
                  mb={4}
                  direction="column"
                  boxShadow="base"
                  borderTopLeftRadius={msg.tipo === "contestacao" ? "0" : "lg"}
                  borderTopRightRadius={msg.tipo === "contestacao" ? "lg" : "0"}
                >
                  <Text fontSize="xs" color="gray.500" align="right">
                    {msg.data} {msg.hora}
                  </Text>
                  <Text
                    fontWeight="medium"
                    color={
                      msg.tipo === "contestacao" ? "blue.600" : "green.600"
                    }
                    fontSize="sm"
                    mb={1}
                  >
                    {msg.tipo === "contestacao" ? "Contestação:" : "Resposta:"}
                  </Text>
                  <Text
                    fontSize="md"
                    color="gray.700"
                    wordBreak="break-word"
                    lineHeight="1.5"
                  >
                    {msg.texto}
                  </Text>
                </Flex>
              ) : null // Evita renderização de contestações ou respostas vazias
          )}
        </Box>
      </Box>
    </Box>
  );
}
