import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Spinner,
  Stack,
  Center,
  VStack,
  Text,
  Heading,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { utils, writeFile } from "xlsx";
import { FaFileExcel } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { getToken } from "../api/get-token-api";
import { getAllItemsPex } from "../api/get-items-pex-api";
import { getAllItemsPexWithPoints } from "../api/get-items-pontuacao-api";
import { FaExclamationTriangle } from "react-icons/fa";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useAppContext } from "../context/AppContext";
import { getAllFileNames } from "../api/get-files-name-jc-api";
import { Switch } from "@chakra-ui/react";

export default function PontosPorItens() {
  const [token, setToken] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [apiDataPontosTrimestral, setApiDataPontosTrimestral] = useState([]);
  const [apiDataPontosAnual, setApiDataPontosAnual] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemObs, setSelectedItemObs] = useState("");
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [selectedItemNumber, setSelectedItemNumber] = useState("");
  const [selectedItemTitle, setSelectedItemTitle] = useState("");
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();
  const { distribuidor } = useAppContext();
  const [fileNamesApi, setFileNamesApi] = useState([]);
  const [estadoSwitch, setEstadoSwitch] = useState("T");
  const { ano, trimestre } = useParams();
  const { anoGlobal, setAnoGlobal } = useAppContext();
  const { trimestreGlobal, setTrimestreGlobal } = useAppContext();

  const groupedData = {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingTable(true);

        let paramForGetItemsPex = 2; // Valor padrão, pode ser ajustado conforme a necessidade

        // Verifica a condição para ajustar o parâmetro
        if (
          anoGlobal === "2023" &&
          (trimestreGlobal === "3" || trimestreGlobal === "4")
        ) {
          paramForGetItemsPex = 1; // Altera o valor para 2 se a condição for verdadeira
        }

        const tokenData = await getToken(emailGlobal, passwordGlobal);
        setToken(tokenData.access_token);
        const itemsDataPex = await getAllItemsPex(tokenData.access_token, 1);

        // Chama a API com base no estado do estadoSwitch
        let itemsDataPeriodo;
        let fileNames;
        if (estadoSwitch === "A" || estadoSwitch === "ANUAL") {
          itemsDataPeriodo = await getAllItemsPexWithPoints(
            tokenData.access_token,
            "Anual",
            distribuidor,
            anoGlobal,
            1, // Usando 1 para período anual
            paramForGetItemsPex
          );
          fileNames = await getAllFileNames(
            estadoSwitch,
            distribuidor,
            anoGlobal,
            1, // Usando 1 para período anual
            paramForGetItemsPex
          );
        } else {
          itemsDataPeriodo = await getAllItemsPexWithPoints(
            tokenData.access_token,
            "Trimestral",
            distribuidor,
            anoGlobal,
            trimestreGlobal,
            paramForGetItemsPex
          );
          fileNames = await getAllFileNames(
            estadoSwitch,
            distribuidor,
            anoGlobal,
            trimestreGlobal,
            paramForGetItemsPex
          );
        }

        setFileNamesApi(fileNames);
        setApiData(itemsDataPex);
        setApiDataPontosTrimestral(itemsDataPeriodo); // Atribuindo dados de acordo com o período
        setApiDataPontosAnual(itemsDataPeriodo); // Atribuindo os mesmos dados para o período anual também
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingTable(false);
      }
    };

    fetchData();
  }, [estadoSwitch]);
  const duasApisJuntas = (
    apiData,
    apiDataPontosTrimestral,
    apiDataPontosAnual
  ) => {
    return apiData.map((item) => {
      const itemTrimestral = apiDataPontosTrimestral.find(
        (item2) => item2.numero === item.numero
      );
      const itemAnual = apiDataPontosAnual.find(
        (item2) => item2.numero === item.numero
      );
      return { ...item, ...itemTrimestral, ...itemAnual };
    });
  };

  const arrayUnido = duasApisJuntas(
    apiData,
    apiDataPontosTrimestral,
    apiDataPontosAnual
  );
  // Ordena o arrayUnido pelos números
  arrayUnido.sort((a, b) => {
    const parseNumber = (str) => str.split(".").map(Number);
    const aNumber = parseNumber(a.numero);
    const bNumber = parseNumber(b.numero);

    for (let i = 0; i < Math.max(aNumber.length, bNumber.length); i++) {
      if (aNumber[i] !== bNumber[i]) {
        return aNumber[i] - bNumber[i];
      }
    }

    return 0;
  });

  // Limpa o groupedData antes de recriá-lo
  Object.keys(groupedData).forEach((key) => delete groupedData[key]);

  // Recria o groupedData com base no arrayUnido ordenado
  arrayUnido.forEach((item) => {
    if (!groupedData[item.bucket]) {
      groupedData[item.bucket] = [];
    }
    groupedData[item.bucket].push(item);
  });

  const handleExportData = () => {
    const rows = [];

    rows.push(["Número", "Título", "Observação"]);

    // Adiciona os itens do fileNamesApi com observações ao array de linhas
    fileNamesApi.forEach((item) => {
      if (item.observacao) {
        // Verifica se o item possui uma observação
        rows.push([item.numero, item.titulo, item.observacao]);
      }
    });

    const ws = utils.aoa_to_sheet(rows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Dados");

    const dataAtual = new Date();
    const dataFormatada = format(dataAtual, "ddMMyyyy");

    const nomeArquivo = `observacao_pex_${dataFormatada}.xlsx`;

    writeFile(wb, nomeArquivo);
  };

  const handleAlertClick = (itemNumber, itemTitle) => {
    setSelectedItemNumber(itemNumber);
    setSelectedItemTitle(itemTitle);
    setIsModalOpen(true);
  };

  const handleToggleSwitch = () => {
    const novoEstado = estadoSwitch === "T" ? "A" : "T";
    setEstadoSwitch(novoEstado);
  };

  return (
    <>
      <Heading as="h1" size="xl">
        Distribuidor: {distribuidor} - {anoGlobal}/{parseInt(anoGlobal) + 1} -{" "}
        {trimestreGlobal}
      </Heading>

      {!isLoadingTable && (
        <>
          <Stack direction="row">
            <Button
              mt="4"
              mb="4"
              leftIcon={<FaFileExcel />}
              onClick={handleExportData}
              colorScheme="whatsapp"
            >
              Exportar para Excel
            </Button>
          </Stack>
        </>
      )}
      {isLoadingTable ? (
        <>
          <Center h="100vh">
            <VStack>
              <Spinner size="xl" color="black" thickness="4px" />
              <Text mt="4">Aguarde... </Text>
            </VStack>
          </Center>
        </>
      ) : (
        <>
          <Stack direction="row" mt="15px" mb="15px">
            <Text>Trimestral</Text>
            <Switch
              size="md"
              isChecked={estadoSwitch === "A"}
              onChange={handleToggleSwitch}
              colorScheme="green"
            />
            <Text>Anual</Text>
          </Stack>

          {fileNamesApi.some((item) => item.observacao) ? (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Número</Th>
                  <Th>Título</Th>
                  <Th>Observação</Th>
                </Tr>
              </Thead>
              <Tbody>
                {fileNamesApi
                  .filter((item) => item.observacao) // Filtra apenas os itens com observação
                  .sort((a, b) => {
                    // Ordena os itens com base no número
                    const numA = parseFloat(a.numero.replace(/[^\d.]/g, ""));
                    const numB = parseFloat(b.numero.replace(/[^\d.]/g, ""));
                    return numA - numB;
                  })
                  .map((item) => (
                    <Tr key={item.chave}>
                      <Td>{item.numero}</Td>
                      <Td>{item.titulo}</Td>
                      <Td>{item.observacao}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          ) : (
            <Center mt="15%">
              <Text>Sem observações registradas para o período</Text>
            </Center>
          )}
        </>
      )}
    </>
  );
}
