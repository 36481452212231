import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bubble } from "react-chartjs-2";
import { Button } from "@chakra-ui/button";
import { Link } from "react-router-dom";
import { Box, Stack } from "@chakra-ui/layout";
import { getToken } from "../api/get-token-api";
import { useAppContext } from "../context/AppContext";
import { Chart2 } from "./chart2";
import { IoArrowForwardSharp } from "react-icons/io5";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
    x: {
      beginAtZero: true,
      max: 100,
    },
  },
};

const generateRandomData = (label) => {
  return {
    label: label,
    data: [
      {
        x: getRandomNumber(0, 100),
        y: getRandomNumber(0, 100),
        r: getRandomNumber(5, 60),
      },
    ],
    backgroundColor: generateRandomColor(),
  };
};

const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
};

// Função para gerar cores únicas
const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color + "80"; // Adicionando transparência
};

const labels = [
  "SPM",
  "DECLub",
  "PNBLub",
  "Fórmula",
  "Agricopel",
  "Nacional",
  "Boa Nova",
  "Brazmax",
  "Lubpar",
  "Lubtrol",
  "Quite",
  "Portolub",
];

const data = {
  datasets: labels.map((label) => generateRandomData(label)),
};

export default function Charts() {
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [apiDataPontos, setApiDataPontos] = useState([]);
  const { globalToken, setGlobalToken } = useAppContext();

  useEffect(() => {
    // Simulando uma operação assíncrona, por exemplo, uma chamada à API
    const fetchData = async () => {
      try {
        setIsLoadingTable(true);

        // Obter o token
        const tokenData = await getToken();
        setGlobalToken(tokenData.access_token);
        // Obter os itens da API usando o token
        // const itemsData = await getAllItemsPex(tokenData.access_token);
        // const itemsDataPontos = await getAllItemsPexWithPoints(
        //   tokenData.access_token
        // );

        // setApiData(itemsData);
        // setApiDataPontos(itemsDataPontos);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingTable(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Link to="/pontuacao-item-dist">
        <Button bg="#FFC050" leftIcon={<IoArrowForwardSharp />}>
          Pontuação por Item
        </Button>
      </Link>
      <Box>
        <Bubble options={options} data={data} />
      </Box>
      <Box>
        <Chart2 />
      </Box>
    </>
  );
}
