import React, { useEffect, useState } from "react";
import {
  Table,
  Tbody,
  Th,
  Td,
  Tr,
  Heading,
  Divider,
  Stack,
  Button,
  Center,
  Spinner,
  Thead,
  Text,
  Switch,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { FaFileExcel } from "react-icons/fa";
import { getAllPontuacao } from "../api/get-all-pontuacao-api";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { getToken } from "../api/get-token-api";

const TabelaAvaliadoresTdsPontos = () => {
  const [dados, setDados] = useState(null);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [distribuidores, setDistribuidores] = useState([]);
  const [estadoTab, setEstadoTab] = useState("Todos");
  const { ano, trimestre } = useParams();
  const { anoGlobal, setAnoGlobal } = useAppContext();
  const { trimestreGlobal, setTrimestreGlobal } = useAppContext();
  const { acessoUsuario, setAcessoUsuario } = useAppContext();
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal, userProfile, distribuidor } =
    useAppContext();

  const storedNameStorage = acessoUsuario || [];
  // JSON.parse(localStorage.getItem("userAccess")) || [];
  // const storedAccessStorage = localStorage.getItem("userNivelAcess");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let paramForGetItemsPex = 2; // Valor padrão, pode ser ajustado conforme a necessidade

        // Verifica a condição para ajustar o parâmetro
        if (
          anoGlobal === "2023" &&
          (trimestreGlobal === "3" || trimestreGlobal === "4")
        ) {
          paramForGetItemsPex = 1; // Altera o valor para 2 se a condição for verdadeira
        }

        setIsLoadingTable(true);

        const tokenData = await getToken(emailGlobal, passwordGlobal);

        const data = await getAllPontuacao(
          anoGlobal,
          trimestreGlobal,
          paramForGetItemsPex,
          tokenData.access_token
        );
        // Ordenar os números dos itens
        const sortedData = {
          Anual: data.Anual.sort((a, b) => {
            const partesA = a.numero.split(".").map((part) => parseInt(part));
            const partesB = b.numero.split(".").map((part) => parseInt(part));

            for (let i = 0; i < partesA.length; i++) {
              if (partesA[i] !== partesB[i]) {
                return partesA[i] - partesB[i];
              }
            }

            return 0;
          }),
          Trimestral: data.Trimestral.sort((a, b) => {
            const partesA = a.numero.split(".").map((part) => parseInt(part));
            const partesB = b.numero.split(".").map((part) => parseInt(part));

            for (let i = 0; i < partesA.length; i++) {
              if (partesA[i] !== partesB[i]) {
                return partesA[i] - partesB[i];
              }
            }

            return 0;
          }),
        };

        setDados(sortedData);

        const allDistribuidores = data.Trimestral.reduce((acc, curr) => {
          curr.distribuidores.forEach((distribuidor) => {
            if (!acc.includes(distribuidor.distribuidor)) {
              acc.push(distribuidor.distribuidor);
            }
          });
          return acc;
        }, []);

        // Ordena o array em ordem alfabética
        allDistribuidores.sort((a, b) => a.localeCompare(b));

        setDistribuidores(allDistribuidores);
        console.log(allDistribuidores);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingTable(false);
      }
    };

    fetchData();
  }, []);

  const handleExportData = () => {
    // Obter a tabela como uma planilha
    const ws = XLSX.utils.table_to_sheet(
      document.getElementById("table-content")
    );

    // Remover o caractere "+" da primeira coluna
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      const cellRef = XLSX.utils.encode_cell({ r: R, c: 0 }); // Referência para a célula na primeira coluna
      if (!ws[cellRef]) continue; // Se a célula estiver vazia, continue para a próxima linha
      ws[cellRef].v = String(ws[cellRef].v).replace(/\+/g, ""); // Remover o caractere "+"
    }

    // Criar o livro e adicionar a planilha
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Escrever o arquivo
    const dataAtual = new Date();
    const dataFormatada = format(dataAtual, "ddMMyyyy");
    XLSX.writeFile(wb, `pontuacao_pex_${estadoTab}_${dataFormatada}.xlsx`);
  };

  const handleTabChange = (index) => {
    setEstadoTab(index);
  };

  return (
    <>
      <Heading>
        Resumo das Avaliações - {distribuidor} - {anoGlobal}/
        {parseInt(anoGlobal) + 1} -{trimestreGlobal}
      </Heading>
      {userProfile !== "Distribuidor" && (
        <Heading as="h3" size="md" color="blueviolet">
          Avaliador(a):{" "}
          {storedNameStorage && storedNameStorage.length > 0
            ? storedNameStorage[0].name
            : "Nome não disponível"}
        </Heading>
      )}
      <Divider borderWidth="2px" marginTop={"10px"} />

      {!isLoadingTable && (
        <Stack direction="row">
          <Button
            mt="4"
            mb="4"
            leftIcon={<FaFileExcel />}
            onClick={handleExportData}
            colorScheme="whatsapp"
          >
            Exportar para Excel
          </Button>
        </Stack>
      )}

      {isLoadingTable ? (
        <Center>
          <Spinner size="xl" color="black" thickness="4px" mt="23%" />
        </Center>
      ) : (
        <>
          <Stack direction="row" mt="15px" mb="15px">
            <Tabs
              variant="soft-rounded"
              colorScheme="green"
              onChange={(index) => handleTabChange(index)}
            >
              <TabList>
                <Tab onClick={() => handleTabChange("Todos")}>Todos</Tab>
                <Tab onClick={() => handleTabChange("Trimestral")}>
                  Trimestral
                </Tab>
                <Tab onClick={() => handleTabChange("Anual")}>Anual</Tab>
              </TabList>
            </Tabs>
          </Stack>
          <Box overflowX="auto" maxWidth="100%" height="95vh">
            <Table variant="striped" colorScheme="gray" id="table-content">
              <Thead
                position="sticky"
                top="0"
                bg="gray.600"
                color="white"
                zIndex="1"
              >
                <Tr>
                  <Th color="white">Número</Th>
                  <Th color="white">Descrição</Th>
                  <Th color="white">Pontuação Máxima</Th>
                  <Th color="white">Período</Th>
                  {userProfile === "Distribuidor" ? (
                    <Th bg="gray.600" color="white">
                      {distribuidor}
                    </Th>
                  ) : (
                    distribuidores.map((distribuidorItem, index) => (
                      <Th bg="gray.600" key={index} color="white">
                        {distribuidorItem}
                      </Th>
                    ))
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {dados &&
                  (estadoTab === "Todos"
                    ? ["Anual", "Trimestral"].flatMap((periodo) =>
                        dados[periodo]?.map((item) =>
                          item.distribuidores[0].pontuacao_maxima !== 0 ? (
                            <Tr key={item.numero}>
                              <Td>
                                {`${item.numero}`}
                                <span style={{ color: "white" }}>+</span>
                              </Td>
                              <Td>{item.titulo}</Td>
                              <Td>{item.distribuidores[0].pontuacao_maxima}</Td>
                              <Td>{periodo}</Td>
                              {userProfile === "Distribuidor" ? (
                                <Td>
                                  {item.distribuidores.find(
                                    (d) => d.distribuidor === distribuidor
                                  )?.pontuacao_real || "N/A"}
                                </Td>
                              ) : (
                                distribuidores.map(
                                  (distribuidorItem, index) => {
                                    const distribuidorEncontrado =
                                      item.distribuidores.find(
                                        (d) =>
                                          d.distribuidor === distribuidorItem
                                      );
                                    return (
                                      <Td key={index}>
                                        {distribuidorEncontrado?.pontuacao_real ||
                                          "N/A"}
                                      </Td>
                                    );
                                  }
                                )
                              )}
                            </Tr>
                          ) : null
                        )
                      )
                    : dados[estadoTab]?.map((item) =>
                        item.distribuidores[0].pontuacao_maxima !== 0 ? (
                          <Tr key={item.numero}>
                            <Td>
                              {`${item.numero}`}
                              <span style={{ color: "white" }}>+</span>
                            </Td>
                            <Td>{item.titulo}</Td>
                            <Td>{item.distribuidores[0].pontuacao_maxima}</Td>
                            <Td>{estadoTab}</Td>
                            {userProfile === "Distribuidor" ? (
                              <Td>
                                {item.distribuidores.find(
                                  (d) => d.distribuidor === distribuidor
                                )?.pontuacao_real || "N/A"}
                              </Td>
                            ) : (
                              distribuidores.map((distribuidorItem, index) => {
                                const distribuidorEncontrado =
                                  item.distribuidores.find(
                                    (d) => d.distribuidor === distribuidorItem
                                  );
                                return (
                                  <Td key={index}>
                                    {distribuidorEncontrado?.pontuacao_real ||
                                      "N/A"}
                                  </Td>
                                );
                              })
                            )}
                          </Tr>
                        ) : null
                      ))}
              </Tbody>
            </Table>
          </Box>
        </>
      )}
    </>
  );
};

export default TabelaAvaliadoresTdsPontos;
