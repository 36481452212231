import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        backgroundColor: "gray.900",
        color: "black",
      },
      svg: {
        cursor: "pointer",
      },
      ".table": {
        border: "1px solid gray.900",
        width: "100%",
      },
      ".tr": {
        display: "flex",
        width: "fit-content",
      },
      ".th, .td": { boxShadow: "inset 0 0 0 1px red" },
      ".th": {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        padding: "0.5rem",
        fontWeight: "bold",
        fontSize: "xs",
        textTransform: "uppercase",
        textAlign: "center",
      },
      ".td > input": {
        m: "1",
        padding: "0.2rem",
        bg: "transparent",
        maxW: "100%",
      },
      ".date-wrapper": {
        display: "flex",
        alignItems: "center",
        w: "100%",
        h: "100%",
      },
      ".resizer": {
        position: "absolute",
        opacity: 0,
        top: 0,
        right: 0,
        h: "100%",
        w: "5px",
        bg: "#27bbff",
        cursor: "col-resize",
        userSelect: "none",
        touchAction: "none",
        borderRadius: "6px",
      },
      ".resizer.isResizing": {
        bg: "#2eff31",
        opacity: 1,
      },
      "*:hover > .resizer": {
        opacity: 1,
      },
    },
  },
});

export default theme;
