export async function saveAvaliation(token, body) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: "follow",
  };

  const apiUrl =
    "https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/INT_SHELL_PEX_GRAVAAVALIACAO";

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error post aval:", error);
  }
}
