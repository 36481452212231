import { useEffect, useState } from "react";
import {
  Heading,
  Divider,
  Input,
  Stack,
  InputLeftAddon,
  InputGroup,
  Radio,
  RadioGroup,
  Select,
  HStack,
  Button,
  Text,
  Textarea,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { createItemPex } from "../api/create-item-pex-api";
import { getToken } from "../api/get-token-api";
import { useToast } from "@chakra-ui/react";
import { getAllItemsPex } from "../api/get-items-pex-api";
import { useAppContext } from "../context/AppContext";

export default function AdicionarItemComponent({
  showTitleAndDivider,
  initialData,
  onAdd,
  onCloseModal,
  closeAddItemModal,
  refetchTableData,
  isCancelButtonVisible,
}) {
  const [avaliador, setAvaliador] = useState("");
  const [pontuacao, setPontuacao] = useState(0);
  const [numero, setNumero] = useState("");
  const [bucket, setBucket] = useState("");
  const [grupo, setGrupo] = useState("");
  const [item, setItem] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedCriterion, setSelectedCriterion] = useState("option1");
  const [descricao, setDescricao] = useState("");
  const [conditions, setConditions] = useState([
    { type: "ooption1", value: "", secondValue: "" },
  ]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false); // Adicione este estado para controlar o estado de carregamento
  const toast = useToast();
  const [selectedCriterionType, setSelectedCriterionType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true); // Adicione este estado
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const { globalToken } = useAppContext();
  const [todosItemsPex, setTodosItemsPex] = useState([]);
  const [isNumeroValido, setIsNumeroValido] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [isAutomatico, setIsAutomatico] = useState("S");

  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();

  useEffect(() => {
    const getItemsOnInicio = async () => {
      if (globalToken === "") {
        try {
          const pegaToken = await getToken(emailGlobal, passwordGlobal);
          const pegaItems = await getAllItemsPex(pegaToken.access_token, 1);

          setTodosItemsPex(pegaItems);
        } catch (error) {
          console.error("Erro ao obter itens:", error);
        } finally {
          // Marque o carregamento como concluído
          setLoadingData(false);
        }
      } else {
        try {
          const pegaItems = await getAllItemsPex(globalToken.access_token, 1);

          setTodosItemsPex(pegaItems);
        } catch (error) {
          console.error("Erro ao obter itens:", error);
        } finally {
          // Marque o carregamento como concluído
          setLoadingData(false);
        }
      }
    };

    getItemsOnInicio();
  }, []);

  const [isCancelButtonVisibleInternal, setIsCancelButtonVisibleInternal] =
    useState(isCancelButtonVisible || false);
  useEffect(() => {
    if (initialData) {
      // Preencha os estados com os dados iniciais
      setSelectedPeriod(initialData.periodo || "");
      setSelectedCriterionType(initialData.criterio === "M" ? "M" : "V");
      setAvaliador(initialData.avaliador || "");
      setPontuacao(String(initialData.pontuacao_maxima) || ""); // Corrigido aqui
      setNumero(initialData.numero || "");
      setBucket(initialData.bucket || "");
      setGrupo(initialData.grupo || "");
      setItem(initialData.titulo || "");
      setSelectedCriterion(
        initialData.criterio === "M" ? "option1" : "option2"
      );
      setDescricao(initialData.descricao || "");
      setIsAutomatico(initialData.evidencia === "S" ? "S" : "N");
      // Trate o 'range' aqui
      setConditions(
        initialData.range.map((rangeItem) => ({
          operador: rangeItem.operador === "B" ? "B" : "C",
          fator: String(rangeItem.fator), // Certifique-se de que os valores estejam corretos
          pontos: Number(rangeItem.pontos), // Certifique-se de que os valores estejam corretos
        }))
      );
    }
  }, [initialData]);

  const addCondition = () => {
    setConditions((prevConditions) => {
      const newConditions = [
        ...prevConditions,
        { operador: "B", fator: "", pontos: "" },
      ];

      // Log para verificar o conteúdo de 'range' dinamicamente ao adicionar condição

      return newConditions;
    });
  };

  const removeCondition = (index) => {
    setConditions((prevConditions) => {
      const updatedConditions = [...prevConditions];
      updatedConditions.splice(index, 1);

      // Log para verificar o conteúdo de 'range' dinamicamente ao remover condição

      return updatedConditions;
    });
  };

  // Função para salvar os dados
  const saveData = async () => {
    try {
      toast({
        title: "Criando item...",
        status: "info",
        duration: 5000,
        isClosable: true,
      });

      const token = await getToken(emailGlobal, passwordGlobal);
      const data = [
        {
          capitulo: bucket,
          numero,
          bucket,
          grupo,
          periodo: selectedPeriod,
          criterio: selectedCriterion === "option1" ? "M" : "V",
          pontuacao_maxima: Number(pontuacao) || 0,
          titulo: item,
          descricao,
          evidencia: isAutomatico,
          avaliador,
          range:
            selectedCriterion === "option1"
              ? [] // Se for "Sim ou Não" (M), range é um array vazio
              : selectedCriterionType === "V"
              ? conditions.map((condition) => ({
                  operador: condition.operador,
                  fator: Number(condition.fator) || 0,
                  pontos: Number(condition.pontos) || 0,
                }))
              : [], // Se for "Variável" (V), range é criado com base nas condições fornecidas
        },
      ];

      await createItemPex(token.access_token, data);

      toast.closeAll();
      toast.closeAll();
      toast({
        title: "Item salvo com sucesso!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if (onAdd) {
        onAdd();
      }

      if (onCloseModal) {
        onCloseModal();
      }

      clearFields();
    } catch (error) {
      console.error("Erro ao salvar dados:", error);

      toast({
        title: "Erro ao salvar item",
        description: `Algo deu errado. Detalhes: ${error.message || error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const clearFields = () => {
    // Adicione o código para limpar todos os campos
    setAvaliador("");
    setPontuacao("");
    setNumero("");
    setBucket("");
    setGrupo("");
    setItem("");
    setSelectedPeriod(""); // Certifique-se de limpar o estado do período
    setSelectedCriterion([]);
    setDescricao("");
    setConditions([{ type: "ooption1", value: "", secondValue: "" }]);
    setIsAutomatico("");
  };

  const verificarNumeroExistente = (numeroDigitado) => {
    const numeroExiste = todosItemsPex.some(
      (item) => item.numero === numeroDigitado
    );
    setIsNumeroValido(!numeroExiste);
  };

  return (
    <div>
      {showTitleAndDivider && (
        <>
          <Heading>Adicionar Item</Heading>
          <Divider borderWidth="2px" marginTop={"10px"} />
        </>
      )}
      {loadingData ? (
        <Center height="100vh">
          <Spinner size="xl" color="black" thickness="4px" />
        </Center>
      ) : (
        <Stack spacing={6}>
          <InputGroup marginTop={"20px"}>
            <InputLeftAddon children="Número" width="175px" />
            <Input
              type="text"
              placeholder="1.1.1"
              value={numero}
              onChange={(e) => {
                setNumero(e.target.value);
                verificarNumeroExistente(e.target.value);
              }}
              isRequired
            />
          </InputGroup>
          {isNumeroValido || (
            <Text color="red.500">
              Um item com este número já existe. Por favor, use outro.
            </Text>
          )}
          <InputGroup>
            <InputLeftAddon children="Capítulo" width="175px" />
            <Input
              type="text"
              placeholder="SSMA, RH, Marketing"
              value={bucket}
              onChange={(e) => setBucket(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <InputLeftAddon children="Avaliador" width="175px" />
            <Input
              type="text"
              placeholder="Avaliador"
              value={avaliador}
              onChange={(e) => setAvaliador(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <InputLeftAddon children="Grupo" width="175px" />
            <Input
              type="text"
              placeholder="Gestão de SSMA, Gestão de RH, Gestão de Marketing"
              value={grupo}
              onChange={(e) => setGrupo(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <InputLeftAddon children="Item" width="175px" />
            <Input
              type="text"
              placeholder="Título do Item"
              value={item}
              onChange={(e) => setItem(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <InputLeftAddon children="Descrição Item" width="175px" />
            <Textarea
              placeholder="Descrição do Item"
              height="120px"
              onChange={(e) => setDescricao(e.target.value)}
              value={descricao} // Certifique-se de adicionar isso
            />
          </InputGroup>
          <InputGroup>
            <InputLeftAddon children="Período de Avaliação" width="175px" />
            <Stack
              direction="row"
              align="center"
              border="1px"
              borderTopRightRadius="5px"
              borderBottomRightRadius="5px"
              borderColor="#E5EAF0"
              width="100vw"
            >
              <RadioGroup
                onChange={(e) => {
                  setSelectedPeriod(e);
                }}
              >
                <RadioGroup
                  onChange={(e) => setSelectedPeriod(e)}
                  value={selectedPeriod}
                >
                  <Radio value="T" ml="10px">
                    Trimestral
                  </Radio>
                  <Radio value="A" ml="10px">
                    Anual
                  </Radio>
                </RadioGroup>
              </RadioGroup>
            </Stack>
          </InputGroup>
          <InputGroup>
            <InputLeftAddon children="Requer Evidência" width="175px" />
            <Stack
              direction="row"
              align="center"
              border="1px"
              borderTopRightRadius="5px"
              borderBottomRightRadius="5px"
              borderColor="#E5EAF0"
              width="100vw"
            >
              <RadioGroup
                onChange={(e) => {
                  setIsAutomatico(e);
                }}
              >
                <RadioGroup
                  onChange={(e) => setIsAutomatico(e)}
                  value={isAutomatico}
                >
                  <Radio value="S" ml="10px">
                    Sim
                  </Radio>
                  <Radio value="N" ml="10px">
                    Automático
                  </Radio>
                  <Radio value="N" ml="10px">
                    Não Requer Evidência
                  </Radio>
                </RadioGroup>
              </RadioGroup>
            </Stack>
          </InputGroup>

          <InputGroup>
            <InputLeftAddon children="Pontuação Máxima" width="175px" />
            <Input
              type="text"
              placeholder="Pontuação Máxima"
              value={pontuacao}
              onChange={(e) => setPontuacao(e.target.value)}
            />
          </InputGroup>
          <Stack direction="row">
            <InputGroup>
              <InputLeftAddon children="Critério de Avaliação" width="175px" />
              <Select
                marginLeft="0px"
                style={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                value={selectedCriterion}
                onChange={(e) => {
                  setSelectedCriterion(e.target.value);
                  setSelectedCriterionType(
                    e.target.value === "option1" ? "M" : "V"
                  );
                }}
              >
                <option value="option1">Sim ou Não</option>
                <option value="option2">Variável</option>
              </Select>
            </InputGroup>
          </Stack>
          {selectedCriterion === "option2" && (
            <Stack direction="column">
              {conditions.map((condition, index) => (
                <HStack key={index} spacing={2} alignItems="center">
                  <Select
                    width="135px"
                    value={condition.operador}
                    onChange={(e) => {
                      const updatedConditions = [...conditions];
                      updatedConditions[index].operador = e.target.value;
                      setConditions(updatedConditions);
                    }}
                  >
                    <option value="B">Inferior a</option>
                    <option value="C">Acima de</option>
                  </Select>

                  <Input
                    type="text"
                    placeholder=""
                    width="80px"
                    value={condition.fator}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/[^0-9,]/g, "");
                      const updatedConditions = [...conditions];
                      updatedConditions[index].fator = numericValue;
                      setConditions(updatedConditions);
                    }}
                  />

                  <Text> = </Text>
                  <Input
                    type="text"
                    placeholder=""
                    width="80px"
                    value={condition.pontos}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/[^0-9,]/g, "");
                      const updatedConditions = [...conditions];
                      updatedConditions[index].pontos = numericValue;
                      setConditions(updatedConditions);
                    }}
                  />
                  <Button
                    colorScheme="red"
                    onClick={() => removeCondition(index)}
                  >
                    Remover
                  </Button>
                </HStack>
              ))}
              <Button width="330px" onClick={addCondition}>
                Adicionar Critério
              </Button>
            </Stack>
          )}
          <Stack direction="row">
            {isCancelButtonVisible && (
              <Button
                colorScheme="red"
                onClick={() => {
                  setIsCancelButtonVisibleInternal(false);
                  closeAddItemModal();
                }}
                width="50%"
              >
                Cancelar
              </Button>
            )}
            <Button
              colorScheme="green"
              color="white"
              onClick={() => {
                saveData();
                clearFields();
                refetchTableData();
                closeAddItemModal();
              }}
              isDisabled={
                !avaliador ||
                !pontuacao ||
                !numero ||
                !bucket ||
                !grupo ||
                !item ||
                !selectedPeriod ||
                (selectedCriterion === "option2" &&
                  conditions.some(
                    (condition) => !condition.fator || !condition.pontos
                  ))
              }
              width={isCancelButtonVisible ? "50%" : "100%"} // Ajuste a largura conforme necessário
            >
              {loading ? <Spinner size="sm" color="green" /> : "Salvar"}
            </Button>
          </Stack>
        </Stack>
      )}
    </div>
  );
}
