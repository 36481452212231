import { useState } from "react";
import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { useAppContext } from "../context/AppContext";

export default function ButtonFator({
  onSave,
  avaliado,
  pontuacao_real,
  range,
  fator,
  pontos,
  onAlertBeforeSave,
}) {
  // Estado para controlar o botão clicado
  const [botaoClicado, setBotaoClicado] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { anoGlobal, setAnoGlobal } = useAppContext();
  const { trimestreGlobal, setTrimestreGlobal } = useAppContext();
  const { anoGlobalAtivo, setAnoGlobalAtivo } = useAppContext();
  const { trimestreGlobalAtivo, setTrimestreGlobalAtivo } = useAppContext();
  const { userProfile, setUserProfile } = useAppContext();

  const handleClick = (index, fator, pontos) => {
    // Atualizamos o estado para indicar que não é mais a carga inicial
    setIsInitialLoad(false);

    if (botaoClicado === index) {
      // Se o botão clicado novamente, desmarcar
      setBotaoClicado(null);
    } else {
      // Se um novo botão foi clicado, marcar
      setBotaoClicado(index);
    }

    onSave(fator, avaliado, pontuacao_real, pontos);
  };

  let isDisabled =
    anoGlobal !== anoGlobalAtivo || trimestreGlobal !== trimestreGlobalAtivo;

  if (userProfile === "Administrador") {
    isDisabled = false; // Desativar a desativação se o usuário for um administrador
  }
  return (
    <Box>
      <Stack direction="column">
        {range.map((item, index) => (
          <Button
            key={index}
            isDisabled={isDisabled}
            mt="10px"
            colorScheme={
              (isInitialLoad && avaliado === "S" && fator === item.fator) ||
              botaoClicado === index
                ? "green"
                : "gray"
            }
            onClick={() => handleClick(index, item.fator, item.pontos)}
            ml="10px"
          >
            {item.operador === "C" ? `Acima de ` : `Abaixo de `}
            {item.fator}
            <Text ml="2" fontSize="md" fontWeight="bold">
              = {item.pontos} pontos
            </Text>
          </Button>
        ))}
        <Button
          isDisabled={isDisabled}
          mt="10px"
          colorScheme={
            (isInitialLoad && avaliado === "S" && fator === 0) ||
            botaoClicado === range.length
              ? "red"
              : "gray"
          }
          onClick={() => handleClick(range.length, 0, 0)}
          ml="10px"
        >
          Não Atende = 0
        </Button>
      </Stack>
    </Box>
  );
}
