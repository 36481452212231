import { useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import {
  MRT_EditActionButtons,
  MantineReactTable,
  // createRow,
  useMantineReactTable,
} from "mantine-react-table";
import {
  ActionIcon,
  Button,
  Flex,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { ModalsProvider, modals } from "@mantine/modals";
import { IconDownload, IconEdit, IconTrash } from "@tabler/icons-react";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { DATA, criterios } from "../helpers/mock/data";
import { Box, Divider, Heading } from "@chakra-ui/react";
import { utils, writeFile } from "xlsx";
import { FaFileExcel } from "react-icons/fa";
import { getToken } from "../api/get-token-api";
import { getAllItemsPex } from "../api/get-items-pex-api";
import AdicionarItemComponent from "./adicionar-item";
import { format } from "date-fns";
import { useAppContext } from "../context/AppContext";
import { FaChevronDown } from "react-icons/fa";

const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [token, setToken] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [revisao, setRevisao] = useState(2);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabelaData, setTabelaData] = useState([]); // Estado representando os dados da tabela
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();

  const handleRevisao = (value) => {
    setRevisao(value);
  };
  const openAddItemModal = (item) => {
    setSelectedItem(item);
    setIsAddingItem(true);
  };

  const closeAddItemModal = async () => {
    setIsAddingItem(false);
    await refetchTableData(); // Chame a função que busca os dados da API novamente
  };

  const refetchTableData = async () => {
    try {
      setIsLoadingTable(true);
      const tokenData = await getToken(emailGlobal, passwordGlobal);
      setToken(tokenData.access_token);
      const itemsData = await getAllItemsPex(tokenData.access_token, revisao);
      setApiData(itemsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingTable(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingTable(true);

        // Obter o token
        const tokenData = await getToken(emailGlobal, passwordGlobal);
        setToken(tokenData.access_token);
        // Obter os itens da API usando o token
        const itemsData = await getAllItemsPex(tokenData.access_token, revisao);
        setApiData(itemsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingTable(false);
      }
    };

    fetchData();
  }, [revisao]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);

    // Criar uma planilha Excel
    const ws = utils.json_to_sheet(rowData, {
      header: columns.map((col) => col.header),
    });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet 1");

    // Salvar o arquivo Excel
    writeFile(wb, "exported_data.xlsx");
  };

  const handleExportData = () => {
    const formattedData = apiData.map((item) => ({
      avaliador: item.avaliador,
      numero: item.numero,
      // bucket: item.bucket,
      capitulo: item.capitulo,
      grupo: item.grupo,
      titulo: item.titulo,
      descricao: item.descricao,
      periodo: item.periodo === "A" ? "ANUAL" : "TRIMESTRAL",
      criterio: item.criterio === "V" ? "VARIAVEL" : "SIM ou NAO",
      pontuacao_maxima: item.pontuacao_maxima,
      range: item.range
        .map((rangeItem) => {
          const operadorText =
            rangeItem.operador === "C" ? "acima de" : "abaixo de";
          return `${operadorText} ${rangeItem.fator}=${rangeItem.pontos}`;
        })
        .join("; "),
    }));
    const dataAtual = new Date();
    const dataFormatada = format(dataAtual, "ddMMyyyy");

    const nomeArquivo = `itens_pex_${dataFormatada}.xlsx`;
    // Criar uma planilha Excel
    const ws = utils.json_to_sheet(formattedData, {
      header: columns.map((col) => col.accessorKey),
    });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet 1");

    // Salvar o arquivo Excel
    writeFile(wb, nomeArquivo);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "avaliador",
        header: "Avaliador",
        size: 25,
      },
      {
        accessorKey: "numero",
        header: "Número",
        size: 25,

        mantineEditTextInputProps: {
          type: "email",
          required: true,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              firstName: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: "capitulo",
        header: "Capítulo",
        size: 25,
        mantineEditTextInputProps: {
          type: "email",
          required: true,
          error: validationErrors?.lastName,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              lastName: undefined,
            }),
        },
      },
      // {
      //   accessorKey: "bucket",
      //   header: "Bucket",
      //   size: 25,
      //   mantineEditTextInputProps: {
      //     type: "email",
      //     required: true,
      //     error: validationErrors?.lastName,
      //     //remove any previous validation errors when user focuses on the input
      //     onFocus: () =>
      //       setValidationErrors({
      //         ...validationErrors,
      //         lastName: undefined,
      //       }),
      //   },
      // },
      {
        accessorKey: revisao === 2 ? "bucket" : "grupo",
        header: revisao === 2 ? "Quesito" : "Grupo",
        mantineEditTextInputProps: {
          type: "email",
          required: true,
          error: validationErrors?.email,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
        },
      },
      {
        accessorKey: "titulo",
        header: "Título Item",
        editVariant: "select",
        mantineEditTextInputProps: {
          type: "email",
          required: true,
          error: validationErrors?.email,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
        },
      },
      {
        accessorKey: "descricao",
        header: "Descrição Item",
        editVariant: "select",
        size: 250,
        mantineEditTextInputProps: {
          type: "email",
          required: true,
          error: validationErrors?.email,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
        },
      },
      {
        accessorKey: "periodo",
        header: "Período de Avaliação",
        size: 25,

        Cell: ({ row }) => {
          const rowData = row.original;

          if (rowData.periodo === "T") {
            return <div>Trimestral</div>;
          } else if (rowData.periodo === "A") {
            return <div>Anual</div>;
          }
        },
      },
      {
        accessorKey: "criterio",
        header: "Critério de Atingimento",

        size: 50,
        Cell: ({ row }) => {
          const rowData = row.original;

          if (rowData.criterio === "V") {
            return <div>Variável</div>;
          } else if (rowData.criterio === "M") {
            return <div>Sim ou Não</div>;
          }
        },
      },
      {
        accessorKey: "pontuacao_maxima",
        header: "Pontuação Máxima",
        size: 35,

        mantineEditTextInputProps: {
          type: "email",
          required: true,
          error: validationErrors?.email,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
        },
      },
      {
        accessorKey: "range",
        header: "Faixa de Pontuação",
        size: 150,
        Cell: ({ row }) => (
          <div>
            {row.original.range.length > 0 ? (
              <table>
                <tbody>
                  {row.original.range.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.operador === "C" ? "Acima de " : "Abaixo de "}
                      </td>
                      <td> {item.fator}</td>
                      <td>= {item.pontos}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>-</p>
            )}
          </div>
        ),
      },
    ],
    [validationErrors, revisao]
  );

  //call CREATE hook
  const { mutateAsync: createUser, isLoading: isCreatingUser } =
    useCreateUser();
  //call READ hook
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();
  //call UPDATE hook
  const { mutateAsync: updateUser, isLoading: isUpdatingUser } =
    useUpdateUser();
  //call DELETE hook
  const { mutateAsync: deleteUser, isLoading: isDeletingUser } =
    useDeleteUser();

  //CREATE action
  const handleCreateUser = async ({ values, exitCreatingMode }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createUser(values);
    exitCreatingMode();
  };

  //UPDATE action
  const handleSaveUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) =>
    modals.openConfirmModal({
      title: "Tem certeza que deseja deletar este item?",
      children: (
        <Text>
          Deseja mesmo deletar o item de número {row.original.Número}? Essa ação
          é irreversível.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => deleteUser(row.original.id),
    });

  const table = useMantineReactTable({
    columns,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          color="lightblue"
          onClick={handleExportData}
          leftIcon={<FaFileExcel />}
          variant="filled"
        >
          Exportar para Excel
        </Button>
        <Flex align="center" justify="center">
          <Heading as="h3" size="lg">
            PEX:
          </Heading>
          <Tabs defaultIndex={1} variant="soft-rounded" colorScheme="yellow">
            <TabList ml={1}>
              <Tab onClick={() => handleRevisao(1)}>Versão Anterior</Tab>
              <Tab onClick={() => handleRevisao(2)}>Versão Atual</Tab>
            </TabList>
            {/* <TabPanels>
            <TabPanel>
            <p>one!</p>
            </TabPanel>
            <TabPanel>
            <p>two!</p>
            </TabPanel>
          </TabPanels> */}
          </Tabs>
        </Flex>
      </Box>
    ),
    localization: {
      actions: "Ações",
      and: "e",
      cancel: "Cancelar",
      changeFilterMode: "Alterar o modo de filtro",
      changeSearchMode: "Alterar o modo de pesquisa",
      clearFilter: "Limpar filtros",
      clearSearch: "Limpar pesquisa",
      clearSort: "Limpar classificações",
      clickToCopy: "Clique para copiar",
      collapse: "Recolher",
      collapseAll: "Recolher tudo",
      columnActions: "Ações das colunas",
      copiedToClipboard: "Copiado para área de transferência",
      dropToGroupBy: "Solte para agrupar por {column}",
      edit: "Editar",
      expand: "Expandir",
      expandAll: "Expandir tudo",
      filterArrIncludes: "Inclui",
      filterArrIncludesAll: "Incluir tudo",
      filterArrIncludesSome: "Inclui alguns",
      filterBetween: "Entre",
      filterBetweenInclusive: "Entre valores incluídos",
      filterByColumn: "Filtrar por {column}",
      filterContains: "Contém",
      filterEmpty: "vazio",
      filterEndsWith: "Termina com",
      filterEquals: "Igual",
      filterEqualsString: "Igual",
      filterFuzzy: "Impreciso",
      filterGreaterThan: "Maior que",
      filterGreaterThanOrEqualTo: "Maior ou igual que",
      filterInNumberRange: "Entre",
      filterIncludesString: "Contém",
      filterIncludesStringSensitive: "Contém",
      filterLessThan: "Menor que",
      filterLessThanOrEqualTo: "Menor ou igual que",
      filterMode: "Modo de filtro: {filterType}",
      filterNotEmpty: "Não é vazio",
      filterNotEquals: "Não é igual",
      filterStartsWith: "Começa com",
      filterWeakEquals: "Igual",
      filteringByColumn: "Filtrando por {column} - {filterType} {filterValue}",
      goToFirstPage: "Ir para a primeira página",
      goToLastPage: "Ir para a última página",
      goToNextPage: "Ir para a próxima página",
      goToPreviousPage: "Ir para a página anterior",
      grab: "Agarrar",
      groupByColumn: "Agrupar por {column}",
      groupedBy: "Agrupado por ",
      hideAll: "Ocultar tudo",
      hideColumn: "Ocultar coluna {column}",
      max: "Max",
      min: "Min",
      move: "Mover",
      noRecordsToDisplay: "Não há registros a serem exibidos",
      noResultsFound: "Nenhum resultado encontrado",
      of: "de",
      or: "ou",
      pinToLeft: "Fixar à esquerda",
      pinToRight: "Fixar à direita",
      resetColumnSize: "Restaurar tamanho da coluna",
      resetOrder: "Restaurar ordem",
      rowActions: "Ações da linha",
      rowNumber: "#",
      rowNumbers: "Número da linha",
      rowsPerPage: "Linhas por página",
      save: "Salvar",
      search: "Pesquisar",
      selectedCountOfRowCountRowsSelected:
        "{selectedCount} de {rowCount} linha(s) selecionada(s)",
      select: "Selecionar",
      showAll: "Mostrar tudo",
      showAllColumns: "Mostrar todas as colunas",
      showHideColumns: "Mostrar/Ocultar colunas",
      showHideFilters: "Mostrar/Ocultar filtros",
      showHideSearch: "Mostrar/Ocultar barra de pesquisa",
      sortByColumnAsc: "Ordenar por {column} em ascendente",
      sortByColumnDesc: "Ordenar por {column} em descendente",
      sortedByColumnAsc: "Ordenado por {column} em ascendente",
      sortedByColumnDesc: "Ordenado por {column} em descendente",
      thenBy: ", depois por ",
      toggleDensity: "Alternar densidade",
      toggleFullScreen: "Alternar tela cheia",
      toggleSelectAll: "Alternar selecionar tudo",
      toggleSelectRow: "Alternar seleção da linha",
      toggleVisibility: "Alternar visibilidade",
      ungroupByColumn: "Desagrupar por {column}",
      unpin: "Desfixar",
      unpinAll: "Desfixar tudo",
    },
    enableStickyHeader: true,

    state: { isLoading: isLoadingTable },
    data: apiData,
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: revisao === 2 ? true : false,
    enableDelete: false,
    getRowId: (row) => row.id,
    mantineToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        maxHeight: "720px",
        minHeight: "520px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    renderCreateRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Create New User</Title>
        {internalEditComponents}
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderEditRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Edit User</Title>
        {internalEditComponents}
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {/* <Tooltip label="Editar">
          <ActionIcon onClick={() => table.setEditingRow(row)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip> */}
        <Tooltip label="Editar item">
          <ActionIcon onClick={() => openAddItemModal(row.original)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>

        {isAddingItem && revisao === 2 && (
          <Modal
            blockScrollOnMount={false}
            isOpen={isAddingItem}
            size="full"
            onClose={closeAddItemModal}
            style={{ width: "80%", height: "100vh" }}
            scrollBehavior="inside"
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(1px) hue-rotate(90deg)"
            />
            <ModalContent style={{ height: "1200px", width: "1450px" }}>
              <ModalHeader>
                {selectedItem ? "Editar" : "Adicionar"} Item
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <AdicionarItemComponent
                  showTitleAndDivider={false}
                  initialData={selectedItem}
                  closeAddItemModal={closeAddItemModal}
                  refetchTableData={refetchTableData}
                  isCancelButtonVisible={true}
                />
                {/* <Button colorScheme="blue" mr={3} onClick={closeAddItemModal}>
                  Cancelar
                </Button> */}
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        {/* <Tooltip label="Delete">
        <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
          <IconTrash />
        </ActionIcon>
      </Tooltip> */}
      </Flex>
    ),
  });

  return <MantineReactTable table={table} />;
};

//CREATE hook (post new user to api)
function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(["users"], (prevUsers) => [
        ...prevUsers,
        {
          ...newUserInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUsers() {
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      //send api request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve(DATA);
    },
    // remova refetchOnWindowFocus
  });
}

//UPDATE hook (put user in api)
function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(["users"], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser
        )
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (userId) => {
      queryClient.setQueryData(["users"], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId)
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const ExampleWithProviders = () => (
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
    <ModalsProvider>
      <Heading>Editar Remover Item</Heading>
      <Divider borderWidth="2px" marginTop={"10px"} />
      <Box marginTop={"20px"} mb={5}></Box>
      <Example />
    </ModalsProvider>
  </QueryClientProvider>
);

export default ExampleWithProviders;

const validateRequired = (value) => value != null && !!value.length;

function validateUser(Bucket) {
  return {
    Avaliador: !validateRequired(Bucket?.Avaliador)
      ? "Avaliador is Required"
      : "",
    Número: !validateRequired(Bucket?.Número) ? "Número is Required" : "",
    Bucket: !validateRequired(Bucket?.Bucket) ? "Bucket is Required" : "",
    Grupo: !validateRequired(Bucket?.Grupo) ? "Grupo is Required" : "",
    Item: !validateRequired(Bucket?.Item) ? "Item is Required" : "",
    "Período de Avaliação": !validateRequired(Bucket?.["Período de Avaliação"])
      ? "Período de Avaliação is Required"
      : "",
    "Critério de Atingimento": !validateRequired(
      Bucket?.["Critério de Atingimento"]
    )
      ? "Critério de Atingimento is Required"
      : "",
    "Pontuação Máxima": !validateRequired(Bucket?.["Pontuação Máxima"])
      ? "Pontuação Máxima is Required"
      : "",
  };
}
