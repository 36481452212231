import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import SidebarWithHeader from "./pages/AppShell";
import theme from "./style";

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <SidebarWithHeader />
      </ChakraProvider>
    </div>
  );
}

export default App;
