export async function getAllItemsPex(token, rev) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const apiUrl = `https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/INT_SHELL_PEX_BUSCAITENS?revisao=${rev}`;

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error get pex items:", error);
  }
}
