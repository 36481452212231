import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Text,
  Flex,
  Button,
  Center,
  Spinner,
  Heading,
  Divider,
  VStack,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaBullseye,
  FaClipboard,
  FaCommentDots,
  FaUserTie,
  FaExclamation,
} from "react-icons/fa";
import { useAppContext } from "../context/AppContext";
import { getToken } from "../api/get-token-api";
import { getAllItemsPexWithPoints } from "../api/get-items-pontuacao-api";
import { getAllItemsPex } from "../api/get-items-pex-api";
import { validCredentials } from "../helpers/mock/validCredentials-mock"; // Import the validCredentials file
import ContestacaoModal from "../components/modal-contestacao";
import { getContestacoes } from "../api/get-contestacoes-api";
import { useNavigate } from "react-router-dom";
import PaginaContestacao from "./contestar";

const SelecionarPeriodoPontuacao = () => {
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [data, setData] = useState([]);
  const [periodoAtivo, setPeriodoAtivo] = useState("Trimestral");
  const [contestacoesState, setContestacoesState] = useState([]);

  const {
    anoGlobal,
    trimestreGlobal,
    setGlobalToken,
    globalToken,
    emailGlobal,
    passwordGlobal,
    distribuidor,
  } = useAppContext();
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  // Gera uma chave única baseada nos parâmetros
  const storageKey = `pontuacao_${anoGlobal}_${trimestreGlobal}_${periodoAtivo}_${distribuidor}`;

  const fetchData = useCallback(async () => {
    setIsLoadingTable(true);

    // Verifica se existem dados armazenados no sessionStorage
    const storedData = sessionStorage.getItem(storageKey);
    if (storedData) {
      const { combinedData, contestacoes } = JSON.parse(storedData);
      setData(combinedData);
      setContestacoesState(contestacoes);
      setIsLoadingTable(false);
      return;
    }

    // Caso não haja dados armazenados, faça as chamadas de API
    let token = globalToken;
    if (!globalToken) {
      const tokenData = await getToken(emailGlobal, passwordGlobal);
      token = tokenData.access_token;
      setGlobalToken(token);
    }

    let paramForGetItemsPex = 2;
    if (anoGlobal === "2023" && ["3", "4"].includes(trimestreGlobal)) {
      paramForGetItemsPex = 1;
    }

    // Chamadas de API em paralelo
    const [data, dataItens, contestacoes] = await Promise.all([
      getAllItemsPexWithPoints(
        token,
        periodoAtivo,
        distribuidor || "FORMULA",
        anoGlobal,
        trimestreGlobal,
        paramForGetItemsPex
      ),
      getAllItemsPex(token, paramForGetItemsPex),
      getContestacoes(
        distribuidor || "FORMULA",
        periodoAtivo,
        anoGlobal,
        trimestreGlobal,
        token
      ),
    ]);

    setContestacoesState(contestacoes);

    // Filtra e combina os dados
    const filteredData = data.filter(
      (item) =>
        item.observacao &&
        item.observacao.trim() &&
        item.pontuacao_maxima !== item.pontuacao_real
    );

    const itemsMap = dataItens.reduce((acc, item) => {
      acc[item.numero] = item;
      return acc;
    }, {});

    const combinedData = filteredData.map((item) => ({
      ...item,
      ...(itemsMap[item.numero] || {}),
    }));

    // Ordena os dados combinados
    combinedData.sort((a, b) => {
      const aParts = a.numero.split(".").map(Number);
      const bParts = b.numero.split(".").map(Number);
      for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aValue = aParts[i] || 0;
        const bValue = bParts[i] || 0;
        if (aValue !== bValue) return aValue - bValue;
      }
      return 0;
    });

    // Armazena os dados no sessionStorage
    sessionStorage.setItem(
      storageKey,
      JSON.stringify({ combinedData, contestacoes })
    );

    setData(combinedData);
    setIsLoadingTable(false);
  }, [
    anoGlobal,
    trimestreGlobal,
    distribuidor,
    periodoAtivo,
    emailGlobal,
    passwordGlobal,
    globalToken,
    setGlobalToken,
  ]);

  console.log("selectedItem", selectedItem);

  // Reexecuta o fetchData sempre que algum dos parâmetros mudar
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleContestar = (item) => {
    navigate(
      `/acompanhar-contestar/${anoGlobal}/${trimestreGlobal}/${distribuidor}`,
      { state: { selectedItem: item } }
    );
  };

  return (
    <Box>
      <Heading>Acompanhamento e Contestações - {distribuidor}</Heading>
      <Divider borderWidth="2px" marginTop={"10px"} />

      <Flex gap={3} my="4">
        <Button
          onClick={() => setPeriodoAtivo("Trimestral")}
          colorScheme={periodoAtivo === "Trimestral" ? "yellow" : "gray"}
        >
          Trimestral
        </Button>
        <Button
          onClick={() => setPeriodoAtivo("Anual")}
          colorScheme={periodoAtivo === "Anual" ? "yellow" : "gray"}
        >
          Anual
        </Button>
      </Flex>

      {isLoadingTable ? (
        <Center h="100vh">
          <VStack>
            <Spinner size="xl" color="black" thickness="4px" />
            <Text mt="4">Aguarde... </Text>
          </VStack>
        </Center>
      ) : (
        <Box>
          <VStack spacing={4} align="stretch">
            {data.length === 0 ? (
              <Text>Nenhum dado disponível.</Text>
            ) : (
              data.map((item) => (
                <Box key={item.chave} p={4} borderWidth={1} borderRadius="lg">
                  <Flex justify="space-between" align="center">
                    <Text fontWeight="bold" fontSize="large">
                      <Icon as={FaClipboard} color="blue.500" mr={2} />
                      {item.numero}
                    </Text>
                    <Button
                      leftIcon={<Icon as={FaExclamation} />}
                      colorScheme="red"
                      onClick={() => handleContestar(item)}
                    >
                      CONTESTAR
                    </Button>
                  </Flex>
                  <Divider />
                  <Text mt={2}>
                    <Icon as={FaUserTie} color="green.500" mr={2} />
                    Responsável: {item.capitulo}
                  </Text>
                  <Text>
                    <Icon as={FaBullseye} color="yellow.500" mr={2} />
                    Pontuação Máxima: {item.pontuacao_maxima}
                  </Text>
                  <Text>
                    <Icon as={FaBullseye} color="yellow.500" mr={2} />
                    Pontuação Obtida: {item.pontuacao_real}
                  </Text>
                  <Text>
                    <Icon as={FaBullseye} color="yellow.500" mr={2} />
                    Atingimento: {item.atingimento}%
                  </Text>
                  <Box
                    bg="gray.100"
                    p={3}
                    borderRadius="10px"
                    mt={2}
                    mb={2}
                    min="33vw"
                  >
                    <Icon as={FaCommentDots} color="gray.500" mr={2} />
                    <Text>
                      <strong>Observação:</strong> {item.observacao}
                    </Text>
                  </Box>
                </Box>
              ))
            )}
          </VStack>
          <PaginaContestacao selectedItem={selectedItem} />
        </Box>
      )}
    </Box>
  );
};

export default SelecionarPeriodoPontuacao;
