import { Box, Divider, Heading } from "@chakra-ui/react";
import Distribuidores from "../components/distribuidores";

export function EscolhaDistribuidor() {
  return (
    <Box>
      <Heading>Selecione o Distribuidor</Heading>
      <Divider borderWidth="2px" marginTop={"10px"} />
      <Distribuidores />
    </Box>
  );
}
